/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { buildFilters } from 'embercom/lib/reporting/flexible/filter-helpers';
import { groupBy, isEmpty } from 'underscore';
import ENV from 'embercom/config/environment';

export default class TableComponent extends Component {
  @service appService;
  @service intercomEventService;
  @service notificationsService;
  @service store;
  @service intl;

  @tracked tableData = [];
  @tracked isLoading = true;
  @tracked unansweredQuestionForTraining = null;
  @tracked page = 1;
  @tracked totalPages = 1;

  get canLoadMore() {
    return this.totalPages > this.page;
  }

  get globalFilters() {
    return this.args.filters || {};
  }

  get tableIsEmpty() {
    return !this.tableData.length;
  }

  get timeRange() {
    return this.args.range || {};
  }

  get shouldShowEmptyState() {
    return this.isLoading === false && this.tableData.length === 0;
  }

  @action
  showTrainingModal(data) {
    let { phrase_id, phrase_text } = data;

    this.unansweredQuestionForTraining = { phrase_id, phrase_text };

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'unanswered_question_training_modal',
      phrase_id,
      phrase_text,
    });
  }

  @action
  loadFirstPage() {
    this.tableData = [];
    this.page = 1;
    this.totalPages = 1;
    this._maxConversationTurnCreatedAtEndTime = moment(/* now */);
    this._loadPage.perform({ page: 1 });
  }

  @action
  loadNextPage() {
    this._loadPage.perform({ page: this.page + 1 });
  }

  @task({ restartable: true })
  *_loadPage({ page }) {
    this.isLoading = true;

    let dataRequest = this._buildDataRequest();
    let paginationParams = { page };

    let response = yield this.fetchData.perform({
      ...dataRequest,
      ...paginationParams,
    });

    if (response?.unanswered_questions !== undefined) {
      let { unanswered_questions, meta } = response;

      this.page = meta.page;
      this.totalPages = meta.total_pages;

      let users = unanswered_questions.mapBy('user').compact();

      if (users.length) {
        this.store.pushPayload({ users });
      }

      this.tableData = this._addTableData({ unanswered_questions });
    }

    this.isLoading = false;
  }

  @task({ restartable: true })
  *fetchData(data) {
    try {
      return yield ajax({
        url: '/ember/reporting/resolution_bot/unanswered_questions',
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          ...data,
        }),
      });
    } catch (_e) {
      debounce(
        this.notificationsService,
        this.notificationsService.notifyError,
        this.intl.t('components.answers.reporting.unanswered-questions.table.went-wrong'),
        ENV.APP._1000MS,
        ENV.APP._1000MS,
        true,
      );
    }
  }

  _buildDataRequest() {
    let filter = this._transformFilterList([
      ...this._getGlobalFilters(),
      ...this._getTimeFilters(),
      ...buildFilters('resolution_bot_conversation_coverage', ['not_covered_customer_left']),
    ]);

    return {
      time: {
        property: 'first_user_conversation_part_created_at',
        start: moment(this.timeRange.start).valueOf(),
        end: moment(this.timeRange.end).valueOf(),
      },
      filter,
      sort: [
        { first_user_conversation_part_created_at: { order: 'desc' } },
        { conversation_turn_created_at: { order: 'asc' } },
      ],
    };
  }

  _addTableData(payload) {
    let { unanswered_questions } = payload;

    // index all phrases due to case where pagination fetches more phrases for existing conversation
    let orderedPhrasesData = this.tableData.concat(unanswered_questions);
    let orderedConversationIds = orderedPhrasesData.mapBy('conversation_id').uniq();
    let phrasesGroupedByConversationId = groupBy(orderedPhrasesData, 'conversation_id');

    return orderedConversationIds.reduce((tableData, conversation_id) => {
      let phrasesForConversation = phrasesGroupedByConversationId[conversation_id];
      let tableDataForConversation = this._buildTableDataForConversation(phrasesForConversation);
      return tableData.concat(tableDataForConversation);
    }, []);
  }

  _buildTableDataForConversation(phrasesForConversation) {
    let lastPhraseIndex = phrasesForConversation.length - 1;
    let hasManyInConversation = lastPhraseIndex > 0;

    return phrasesForConversation.map((phrase, i) => {
      let trainingIsDisabled = phrase.can_be_trained === false;
      let user = phrase.user && this.store.peekRecord('user', phrase.user.id);

      return {
        ...phrase,
        user,
        trainingIsDisabled,
        isFirstPhraseInConversation: i === 0,
        isNotLastPhraseOfManyInConversation: hasManyInConversation && i !== lastPhraseIndex,
        tooltipText:
          trainingIsDisabled &&
          this.intl.t('components.answers.reporting.unanswered-questions.table.tooltip-text'),
      };
    });
  }

  // -------------------------

  // -------- global filters merging --------
  _transformFilterList(list) {
    if (list.length === 0) {
      return;
    } else if (list.length === 1) {
      return list[0];
    } else {
      return { type: 'and', filters: list };
    }
  }

  _getGlobalFilters() {
    let filtersList = Object.entries(this.globalFilters).reduce((filtersList, [key, value]) => {
      if (value !== undefined) {
        let filters = buildFilters(key, value, { mergeCombined: true });
        return filtersList.concat(...filters);
      }
    }, []);

    return filtersList.filter((f) => !isEmpty(f));
  }

  _getTimeFilters() {
    let conversationTurnCreatedAtEndTime = moment
      .min(moment(this._maxConversationTurnCreatedAtEndTime), moment(this.timeRange.end))
      .valueOf();

    return [
      {
        type: 'time_sequence',
        property: 'conversation_turn_created_at',
        end_time: conversationTurnCreatedAtEndTime,
      },
    ];
  }
}
