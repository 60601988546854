/* import __COLOCATED_TEMPLATE__ from './facebook-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { statuses } from 'embercom/lib/channels/constants';

export default class FacebookCard extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked appPackage;
  @tracked integrations;

  constructor() {
    super(...arguments);
    this.loadAppPackage.perform();
  }

  @task({ drop: true })
  *loadAppPackage() {
    this.appPackage = yield this.store.findRecord('appstore/app-package', 'facebook');
  }

  get status() {
    return statuses.active;
  }
}
