/* import __COLOCATED_TEMPLATE__ from './new-selector.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import { equal, and } from '@ember/object/computed';
import Component from '@ember/component';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import defaultTo from '@intercom/pulse/lib/default-to';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNameBindings: ['isInlineMode:u__display-inline-block'],
  showTeammates: defaultTo(true),
  showTeams: defaultTo(false),
  showRemoveAdmin: defaultTo(false),
  removeAdminValue: defaultTo(null),
  removeAdminTooltip: defaultTo(null),
  initiallyOpened: defaultTo(false),
  showAwayAndReassigningStatus: defaultTo(true),
  itemComponent: defaultTo('admin/new-selector-item'),
  defaultLabel: defaultTo('Unassigned'),
  buttonIsSubtle: defaultTo(false),
  buttonIsTruncated: defaultTo(false),
  mode: defaultTo('inline'),
  isInlineMode: equal('mode', 'inline'),
  onSelectItem: defaultTo(() => {}),
  onClose: defaultTo(() => {}),
  footerActions: defaultTo(undefined),
  intl: service(),

  showBothTeammatesAndTeams: and('showTeammates', 'showTeams'),

  filterPlaceholderToUse: computed(
    'intl.locale',
    'filterPlaceholder',
    'showBothTeammatesAndTeams',
    'showTeams',
    function () {
      if (this.filterPlaceholder) {
        return this.filterPlaceholder;
      }

      if (this.showBothTeammatesAndTeams) {
        return this.intl.t('components.admin.new-selector.default-filter-placeholder-for-both');
      }

      if (this.showTeams) {
        return this.intl.t('components.admin.new-selector.default-filter-placeholder-for-teams');
      }

      return this.intl.t('components.admin.new-selector.default-filter-placeholder-for-teammates');
    },
  ),

  _createGroupItems(group, itemComponent) {
    return group.map((unformattedItem) => {
      return {
        text: unformattedItem.get('display_as_assignee'),
        value: unformattedItem.get('id'),
        component: itemComponent,
        isFilterable: true,
        assignee: unformattedItem,
        showAwayAndReassigningStatus: this.showAwayAndReassigningStatus,
      };
    });
  },
  teams: computed('assignees.[]', 'selectedValue', function () {
    return this.assignees.filter((assignee) => {
      return assignee.get('isTeam') && assignee.get('id') !== this.selectedValue;
    });
  }),
  teammates: computed('assignees.[]', 'selectedValue', function () {
    return this.assignees.filter((assignee) => {
      return !assignee.get('isTeam') && assignee.get('id') !== this.selectedValue;
    });
  }),
  selectedAssignee: findByProperty('assignees', 'id', 'selectedValue'),
  teammatesGroup: computed(
    'intl.locale',
    'teammates.[]',
    'teammatesHeading',
    'itemComponent',
    function () {
      return {
        heading:
          this.teammatesHeading || this.intl.t('components.admin.new-selector.teammates-heading'),
        items: this._createGroupItems(this.teammates, this.itemComponent),
      };
    },
  ),
  teamsGroup: computed('intl.locale', 'teams.[]', 'teamsHeading', 'itemComponent', function () {
    return {
      heading: this.teamsHeading || this.intl.t('components.admin.new-selector.teams-heading'),
      items: this._createGroupItems(this.teams, this.itemComponent),
    };
  }),
  selectedAssigneeGroup: computed('selectedAssignee', function () {
    return {
      items: this._createGroupItems([this.selectedAssignee], this.itemComponent),
    };
  }),
  groupedAssignees: computed(
    'teammatesGroup.[]',
    'teamsGroup.[]',
    'selectedAssigneeGroup.[]',
    'showTeammates',
    'showTeams',
    'selectedAssignee',
    function () {
      let groupedAssignees = [];
      if (this.showRemoveAdmin) {
        groupedAssignees = groupedAssignees.concat({
          text: this.defaultLabel,
          value: this.removeAdminValue,
          tooltipText: this.removeAdminTooltip,
        });
      }
      if (this.selectedAssignee) {
        groupedAssignees = groupedAssignees.concat(this.selectedAssigneeGroup);
      }
      if (this.showTeammates) {
        groupedAssignees = groupedAssignees.concat(this.teammatesGroup);
      }
      if (this.showTeams) {
        groupedAssignees = groupedAssignees.concat(this.teamsGroup);
      }
      return A(groupedAssignees);
    },
  ),
});
