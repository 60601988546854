/* import __COLOCATED_TEMPLATE__ from './messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  HAS_ANSWER_STATE,
  type NO_ANSWER_STATE,
  BOT_ONLY_MODE,
  LOOPING_MODE,
  QUICK_REPLY_ID,
} from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type Profile from 'embercom/models/ai-agent/profile';

interface Signature {
  Args: {
    locale: string;
    profile: Profile;
  };
}

type AnswerState = typeof HAS_ANSWER_STATE | typeof NO_ANSWER_STATE;

export default class MessengerPreview extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare aiAgentQuickRepliesService: $TSFixMe;

  @tracked answerToggleValue = HAS_ANSWER_STATE;

  get hasAnswers() {
    return this.answerToggleValue === HAS_ANSWER_STATE;
  }

  get locale() {
    return this.args.locale || 'en';
  }

  get operatorComments() {
    if (this.hasAnswers) {
      return [
        this.intl.t('answer_bot_did_that_help', {
          locale: this.locale,
        }),
      ];
    } else {
      let followUpPart = this.isBotOnlyEnabled
        ? this.intl.t('answer_bot_looping_bot_only_first_no_answer_followup', {
            locale: this.locale,
          })
        : this.intl.t('answer_bot_looping_first_no_answer_followup', {
            locale: this.locale,
          });
      return [
        this.intl.t('answer_bot_looping_first_no_answer', {
          locale: this.locale,
        }),
        followUpPart,
      ];
    }
  }

  get isBotOnlyEnabled() {
    return this.profile.botMode === BOT_ONLY_MODE;
  }

  get isLoopingEnabled() {
    return this.profile.botMode === LOOPING_MODE;
  }

  get botOnlyParts() {
    return this.profile.botOnlyMessageLocalizations.find((msg) => msg.locale === this.locale)!
      .parts;
  }

  get profile() {
    return this.args.profile;
  }

  get suggestions() {
    let suggestions = [];

    if (this.hasAnswers) {
      suggestions.push(
        this.aiAgentQuickRepliesService.getLabel(
          QUICK_REPLY_ID.ALL_DONE,
          this.customQuickReplies.allDoneLabel,
          this.locale,
        ),
      );
      suggestions.push(
        this.aiAgentQuickRepliesService.getLabel(QUICK_REPLY_ID.MORE_ANSWERS, null, this.locale),
      );
    }

    if (this.isBotOnlyEnabled) {
      suggestions.push(
        this.aiAgentQuickRepliesService.getLabel(QUICK_REPLY_ID.GET_MORE_HELP, null, this.locale),
      );
    } else {
      suggestions.push(
        this.aiAgentQuickRepliesService.getLabel(
          QUICK_REPLY_ID.WAIT_FOR_THE_TEAM,
          this.customQuickReplies.waitForTheTeamLabel,
          this.locale,
        ),
      );
    }

    return suggestions;
  }

  get getMoreHelpQuickReply() {
    return this.aiAgentQuickRepliesService.getLabel(
      QUICK_REPLY_ID.GET_MORE_HELP,
      null,
      this.locale,
    );
  }

  get customQuickReplies() {
    return this.store.peekRecord(
      'operator-settings/ai-agent-quick-replies',
      this.appService.app.id,
    );
  }

  @action
  toggleHasAnswers(value: AnswerState) {
    this.answerToggleValue = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Profiles::Editor::MessengerPreview': typeof MessengerPreview;
    'ai-agent/profiles/editor/messenger-preview': typeof MessengerPreview;
  }
}
