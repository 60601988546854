/* import __COLOCATED_TEMPLATE__ from './toggle-with-predicates.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ToggleWithPredicates extends Component {
  @service intercomEventService;

  get predicatesGroup() {
    return this.args.settings[this.args.predicatesScope];
  }

  get isEnabled() {
    return this.args.settings[this.args.enabledScope];
  }

  @action
  toggleEnabled() {
    this.args.settings.toggleProperty(this.args.enabledScope);
    this.intercomEventService.trackEvent('resolution-bot-setting-changed', {
      action: 'changed',
      object: this.args.enabledScope,
      owner: 'resolution-bot',
      newValue: this.isEnabled,
    });
  }

  @action
  resetPredicates() {
    this.predicatesGroup.set('predicates', []);
  }
}
