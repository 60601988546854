/* import __COLOCATED_TEMPLATE__ from './restore-version-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class RestoreVersionButton extends Component {
  @service router;
  @tracked showConfirmModal = false;

  @action
  async restoreVersion() {
    await this.args.article.restoreVersion(this.args.selectedVersion);

    this.transitionToEdit();
  }

  transitionToEdit() {
    this.args.closeSideDrawer();
    if (this.router.currentRouteName === 'apps.app.articles.articles.article-content.edit') {
      this.args.onVersionRestore();
    } else {
      this.router.transitionTo(
        'apps.app.articles.articles.article-content.edit',
        this.args.article,
      );
    }
  }
}
