/* import __COLOCATED_TEMPLATE__ from './single-article-collection-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type ArticleGroup from 'embercom/models/articles/article-group';
import { indentCollections, type CollectionWithLevel } from './multi-article-group-selector-helper';
import type IntlService from 'ember-intl/services/intl';
import { type CollectionOption } from './multi-article-group-selector';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

export interface SingleArticleCollectionSelectorArgs {
  article: ArticleMultilingual;
  displayColumn?: boolean;
  displayModal?: boolean;
  applyCollections: (collectionIds: string[]) => void;
}

export default class SingleArticleCollectionSelector extends Component<SingleArticleCollectionSelectorArgs> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get article() {
    return this.args.article;
  }

  get defaultHelpCenter() {
    return this.helpCenterService.allSites.firstObject;
  }

  get collections() {
    let articleGroups = this.store.peekAll('articles/article-group');

    let collectionsInHelpCenter: ArticleGroup[] = articleGroups.filter(
      (articleGroup: ArticleGroup) => {
        return articleGroup.helpCenterId === this.defaultHelpCenter.id && !articleGroup.readOnly;
      },
    );

    let indentedCollections = indentCollections(collectionsInHelpCenter);
    let collectionOptions = indentedCollections.map((collection: CollectionWithLevel) =>
      this.makeCollectionOption(collection),
    );

    return collectionOptions;
  }

  get selectedCollectionId() {
    return this.article.collectionForDefaultHelpCenter?.id;
  }

  set selectedCollectionId(collectionId: string | undefined) {
    this.article.inCollections.clear();
    if (collectionId) {
      this.article.inCollections.pushObject(
        this.store.peekRecord('articles/article-group', collectionId),
      );
    }
  }

  get collectionDefaultLabel() {
    return this.collections.length > 0
      ? this.collectionPlaceHolder
      : this.intl.t('articles.editor.side-drawer.settings.no-collections');
  }

  get selectedCollections() {
    return this.article.inCollections.map((collection: ArticleGroup) => collection.id);
  }

  get selectedLabel() {
    if (!this.selectedCollectionId) {
      return this.collectionDefaultLabel;
    }
    let option = this.collections.find((option) => option.value === this.selectedCollectionId);
    return option?.text;
  }

  get inKnowledgeHub() {
    return this.args.displayModal || this.args.displayColumn;
  }

  get collectionPlaceHolder() {
    return this.inKnowledgeHub
      ? this.intl.t(
          'knowledge-hub.content-editor.article-content.side-panel.help-center-section.collection-placeholder',
        )
      : this.intl.t('articles.editor.side-drawer.settings.select');
  }

  private makeCollectionOption(augmentedCollection: CollectionWithLevel): CollectionOption {
    return {
      text: `${augmentedCollection.collection.get('name')}`,
      value: augmentedCollection.collection.id,
      isSelected: this.selectedCollections.includes(augmentedCollection.collection.id),
      component: 'articles/editor/settings-side-drawer/indented-collection-option',
      componentAttrs: { indentLevel: augmentedCollection.level },
    };
  }

  @action onCollectionSelect(collectionId: string) {
    this.selectedCollectionId = collectionId;
    this.args.applyCollections([collectionId]);
  }

  @action removeFromCollection() {
    this.selectedCollectionId = undefined;
    this.args.applyCollections([]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Editor::SettingsSideDrawer::SingleArticleCollectionSelector': typeof SingleArticleCollectionSelector;
    'articles/editor/settings-side-drawer/single-article-collection-selector': typeof SingleArticleCollectionSelector;
  }
}
