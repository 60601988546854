/* import __COLOCATED_TEMPLATE__ from './seat-assignment-modal-body.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
// @ts-nocheck

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import { compact } from 'underscore';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  migrationSeatConfiguration: MigrationSeatConfiguration;
  selectedPlanId: string;
}

interface Signature {
  Args: Args;
}

export default class SeatAssignmentModalBody extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get columns() {
    return compact([
      {
        label: this.intl.t('billing.migrations.edit_plan.admin_seat_mapping.body.headers.name'),
        valuePath: 'name',
        width: '60%',
        isSortable: false,
      },
      {
        valuePath: 'isActive',
        isSortable: false,
      },
      this.shouldShowCurrentSeatType && {
        label: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.headers.current_seat_type',
        ),
        valuePath: 'currentSeatType',
        isSortable: false,
        tooltip: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.header_tooltips.current_seat_type',
        ),
      },
      {
        label: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.headers.new_seat_type',
        ),
        valuePath: 'newSeatType',
        isSortable: false,
        tooltip: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.header_tooltips.new_seat_type',
        ),
      },
    ]);
  }

  get shouldShowCurrentSeatType() {
    return (
      this.appService.app.hasValueBasedPricing2 || this.appService.app.canUsePerProductPricingFlow
    );
  }

  get admins() {
    return this.appService.app.humanAdmins;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignmentModalBody': typeof SeatAssignmentModalBody;
  }
}
