/* import __COLOCATED_TEMPLATE__ from './cluster-side-drawer-conversations-table.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

const PAGE_SIZE = 20;

export default class ClusterSideDrawerConversationsTable extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service router;

  @tracked tableData = [];
  @tracked isLoading = true;
  @tracked page = 1;
  @tracked totalExampleCount = 0;

  pageSize = PAGE_SIZE;

  get canLoadMore() {
    return this.totalPages > this.page;
  }

  get tableIsEmpty() {
    return !this.tableData.length;
  }

  get totalPages() {
    return Math.ceil(this.totalExampleCount / this.pageSize);
  }

  @action
  createNewAnswer(example) {
    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        exampleId: example.conversationId,
        exampleSource: 'answers_clusters_side_drawer_example',
        exampleText: example.exampleText,
        expandSelectedExamples: true,
        language: this.args.locale,
        transitionBackTo: 'apps.app.automation.resolution-bot.clusters',
      },
    });
  }

  @action
  loadFirstPage() {
    this.tableData = [];
    this.page = 1;
    this.loadPage.perform({ page: 1 });
  }

  @action
  loadNextPage() {
    let page = this.page + 1;
    this.loadPage.perform({ page });

    this.intercomEventService.trackAnalyticsEvent({
      object: 'suggested_answers_drawer_load_more',
      action: 'clicked',
      place: 'answer_bot',
      section: 'operator',
      page_number: page,
    });
  }

  @task({ restartable: true })
  *loadPage({ page }) {
    this.isLoading = true;

    let response = yield this.fetchData.perform({ page });
    if (response && response.examples) {
      let tableDataForExamples = response.examples.map(this._buildTableDataForExample.bind(this));
      this.tableData = this.tableData.concat(tableDataForExamples);
      this.totalExampleCount = response.total_count;
      this.page = page;
    }
    this.isLoading = false;
  }

  @task({ restartable: true })
  *fetchData({ page }) {
    try {
      return yield ajax({
        url: `/ember/custom_answers/suggested_answer_clusters/${this.args.clusterId}/examples`,
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          cluster_run_id: this.args.clusterRunId,
          interval: this.args.interval,
          locale: this.args.locale,
          page,
          page_size: this.pageSize,
        },
      });
    } catch (e) {
      debounce(
        this.notificationsService,
        this.notificationsService.notifyError,
        'Something went wrong, try reloading the page.',
        1000,
        1000,
        true,
      );
    }
  }

  _buildTableDataForExample(example) {
    return {
      exampleText: example.example_text,
      conversationId: example.conversation_id,
      conversationCreatedAt: example.conversation_created_at,
      conversationSource: example.conversation_source,
      isConversationAccessible: example.is_conversation_accessible,
      canTrainFromExample:
        example.conversation_source === 'intercom' ? example.is_conversation_accessible : true,
      tooltipText:
        example.conversation_source === 'intercom' && !example.is_conversation_accessible
          ? 'This conversation is not available'
          : undefined,
    };
  }
}
