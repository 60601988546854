/* import __COLOCATED_TEMPLATE__ from './graduation-confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  isOpen: boolean;
  isEarlyStagePlan: boolean;
  earlyStageGraduation: any;
  onModalClose: () => void;
  confirmAction: () => void;
  newInvoiceAmount?: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
export default class GraduationConfirmationModal extends Component<Signature> {
  @service declare purchaseAnalyticsService: any;

  @action trackClickEvent(object: string) {
    let place = this.args.isEarlyStagePlan ? 'update_subscription' : 'plan_builder';

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'graduation_confirmation_modal',
      place,
      object,
    });
  }

  @action onConfirmAction() {
    this.trackClickEvent('confirm');
    this.args.confirmAction();
  }

  @action ocCloseModal() {
    this.trackClickEvent('close');
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::GraduationConfirmationModal': typeof GraduationConfirmationModal;
    'billing/update-early-stage-subscription/graduation-confirmation-modal': typeof GraduationConfirmationModal;
  }
}
