/* import __COLOCATED_TEMPLATE__ from './credit-card-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { addressValidation } from 'embercom/lib/address-validation';
import AddressParams from 'embercom/lib/billing/address-params';

export default class CreditCardModal extends Component {
  @service appService;
  @service customerService;
  @service stripev3;
  @service paymentIntentService;
  @service notificationsService;
  @service store;
  @service intl;
  @tracked address = new AddressParams();
  @tracked showAllErrors = false;

  constructor() {
    super(...arguments);
    this.address.countryCode = 'US';

    if (this.customerService.customer.billingAddress) {
      this.address.countryCode = this.customerService.customer.billingAddress.country;
      this.address.stateCode = this.customerService.customer.billingAddress.state;
      this.address.postcode = this.customerService.customer.billingAddress.postcode;
      this.address.streetAddress = this.customerService.customer.billingAddress.streetAddress;
      this.address.city = this.customerService.customer.billingAddress.city;
    }
  }

  get isFormValid() {
    return addressValidation(this.address);
  }

  @action
  stripeElementChanged(_, ...args) {
    let { complete, value } = args[0];
    let oldPostcode = this.address?.postcode?.toLowerCase() || '';
    let newPostcode = value.postalCode?.toLowerCase() || '';
    if (complete && newPostcode.includes(oldPostcode)) {
      this.address.postcode = value.postalCode;
    }
  }

  @task
  *updateCardTask(stripeElement) {
    if (!addressValidation(this.address)) {
      this.showAllErrors = true;
      return;
    }

    try {
      let stripePaymentMethodId = yield this.paymentIntentService.addPaymentMethod.perform(
        stripeElement,
        this.address.countryCode,
      );

      let requestData = {
        country_code: this.address.countryCode,
        state_code: this.address.stateCode,
        stripe_payment_method_id: stripePaymentMethodId,
        app_id: this.appService.app.id,
        billing_address: {
          street_address: this.address.streetAddress,
          city: this.address.city,
          postcode: this.address.postcode,
          country: this.address.countryCode,
          state_code: this.address.stateCode,
        },
      };

      let response = yield ajax({
        url: `/ember/customers/${this.customerService.customer.id}/update_credit_card`,
        type: 'POST',
        data: JSON.stringify(requestData),
      });
      this.store.pushPayload('billing/customer', {
        'billing/customer': response,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.credit-card-modal.successful-update'),
      );
      this.args.closeModal();
    } catch (error) {
      if (error?.message) {
        return this.notificationsService.notifyError(error.message);
      }
      let responseObject = error.jqXHR.responseJSON;
      let errorMessage;
      if (responseObject) {
        errorMessage =
          responseObject.errors.country ||
          responseObject.errors.token ||
          responseObject.errors.billing_address;
      } else {
        errorMessage = this.intl.t('billing.credit-card-modal.update-error');
      }
      this.notificationsService.notifyError(errorMessage);
    }
  }
}
