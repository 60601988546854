/* import __COLOCATED_TEMPLATE__ from './current-usage-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';

interface Args {
  name: string;
  price: string | undefined;
  testIdentifier: string;
  showLine: boolean | undefined;
  usage: string;
  includeLinkToTeammatesPage: boolean;
  seatFilterValue: string | undefined;
  seatLabel: string | undefined;
  filterType: string | undefined;
  overageTooltipData: any;
  shouldHidePrices: boolean;
  textStyle: 'muted' | undefined;
  charge: Charge;
  contract?: Contract;
}

export default class CurrentUsageRow extends Component<Args> {
  @service intercomEventService: any;
  @service router: any;

  @action transitionToTeammatesPage(filterType: string, seatFilterValue: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'seat_usage_count',
      place: 'billing_summary',
      context: seatFilterValue,
    });
    this.router.transitionTo('apps.app.settings.teammates', {
      queryParams: { filterType, filterValue: seatFilterValue },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::CurrentUsageRow': typeof CurrentUsageRow;
  }
}
