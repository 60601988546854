/* import __COLOCATED_TEMPLATE__ from './supporting-documents-section.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type UploadedSupportingDocument } from './supporting-document-modal';
import { restartableTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { type RegulatoryBundleArgs } from './phone-number-type-section';

export interface SupportingDocumentsSectionArgs {
  bundleSid: string;
  supportingDocumentsRequirement: any;
  supportingDocuments: UploadedSupportingDocument[];
  readOnly?: boolean;
  supportingDocumentsOption: number;
  satisfiedRequirements: any;
  regulatoryBundle: RegulatoryBundleArgs;
  fileUploadNeeded: boolean;
  onSupportingDocumentCreated: (document: UploadedSupportingDocument) => void;
  onSupportingDocumentDeleted: (document: UploadedSupportingDocument) => void;
}

export default class SupportingDocumentsSection extends Component<SupportingDocumentsSectionArgs> {
  @service declare appService: any;
  @service store: any;
  @service intl: any;
  @service notificationsService: any;

  @tracked showUploadSupportingDocumentModal = false;
  @tracked selectedDocument?: UploadedSupportingDocument;

  constructor(owner: any, args: SupportingDocumentsSectionArgs) {
    super(owner, args);
  }

  get supportingDocumentTypes() {
    return this.args.supportingDocumentsRequirement[this.args.supportingDocumentsOption]
      .map((document: any) =>
        document.accepted_documents.map((doc: any) => ({ value: doc.type, text: doc.name })),
      )
      .flat()
      .uniqBy('value');
  }

  get documentsTableHeader() {
    let header = [
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.information-required'),
        valuePath: 'name',
        width: '30%',
      },
    ];

    if (this.args.fileUploadNeeded) {
      header.push({
        label: this.intl.t(
          'calling.settings.phone-regulatory-bundle.acceptable-supporting-documents',
        ),
        valuePath: 'accepted_documents',
        width: '70%',
      });
    } else {
      header.push({
        label: this.intl.t(
          'calling.settings.phone-regulatory-bundle.supporting-document-description',
        ),
        valuePath: 'description',
        width: '70%',
      });
    }

    return header;
  }

  get supportingDocumentsTableHeader() {
    return [
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.document-name'),
        valuePath: 'name',
      },
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.supporting-document-type'),
        valuePath: 'type',
      },
      { label: '', valuePath: 'actions' },
    ];
  }

  get supportingDocumentsTableData() {
    return this.args.supportingDocuments.map((document: UploadedSupportingDocument) => {
      return {
        name: document.name,
        type: document.type,
        typeName: this.nameForSupportingDocumentType(document.type),
        sid: document.sid,
        attributes: document.attributes,
      };
    });
  }

  optionIndexToLabel(optionIndex: number) {
    return `${String.fromCharCode('A'.charCodeAt(0) + optionIndex)}`;
  }

  @restartableTask
  *deleteSupportingDocument(supportingDocument: UploadedSupportingDocument) {
    try {
      yield post('/ember/calling_settings/delete_supporting_document_for_bundle', {
        app_id: this.appService.app.id,
        bundle_sid: this.args.bundleSid,
        supporting_document_sid: supportingDocument.sid,
      });
      this.args.onSupportingDocumentDeleted(supportingDocument);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.error-deleting-supporting-document'),
      );
    }
  }

  @action
  showSupportingDocument(supportingDocument: UploadedSupportingDocument) {
    this.selectedDocument = { ...supportingDocument };
    this.showUploadSupportingDocumentModal = true;
  }

  nameForSupportingDocumentType(type: string) {
    return this.supportingDocumentTypes.findBy('value', type).text;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundle::SupportingDocumentsSection': typeof SupportingDocumentsSection;
    'calling/settings/phone-regulatory-bundle/supporting-documents-section': typeof SupportingDocumentsSection;
  }
}
