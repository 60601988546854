/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-ai */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { type AiAssistSettingsResponse } from 'embercom/lib/ai-assist';
import { request } from 'embercom/lib/inbox/requests';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';

enum AiAssistSettingsToToggle {
  ToggleSummarizeAndTransform = 'toggleSummarizeAndTransform',
  ToggleAutoFillTickets = 'toggleAutoFillTickets',
  ToggleAiAssistInArticle = 'toggleAiAssistInArticle',
  NoSettingToggled = '',
}

export default class AiAssistSettings extends Component<{}> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked isUserAgreementModalOpen = false;
  @tracked isDisableCopilotModalOpen = false;
  @tracked settingToToggle: AiAssistSettingsToToggle = AiAssistSettingsToToggle.NoSettingToggled;

  get canUseAiAssistInArticles() {
    return this.aiAssistSettingsLoader.value?.articles ?? false;
  }

  get canUseAiAssistToSummarize() {
    return this.aiAssistSettingsLoader.value?.conversation_summarization ?? false;
  }

  get canUseAiAssistToTransform() {
    return this.aiAssistSettingsLoader.value?.text_transformations ?? false;
  }

  get canUseAiAssistToAutoFillTickets() {
    return this.aiAssistSettingsLoader.value?.auto_fill_tickets ?? false;
  }

  get isCopilotEnabled() {
    return this.aiAssistSettingsLoader.value?.copilot ?? false;
  }

  get canUseAiAssistToSummarizeAndTransform() {
    return this.canUseAiAssistToSummarize || this.canUseAiAssistToTransform;
  }

  get requiresUserAgreement() {
    return (
      !this.canUseAiAssistInArticles &&
      !this.canUseAiAssistToSummarizeAndTransform &&
      !this.canUseAiAssistToAutoFillTickets
    );
  }

  get isAutoFillTicketsDisabled() {
    return (
      !this.appService.app.canUseAiAssistAutoFillTickets ||
      taskFor(this.updateAiAssistSettings).isRunning
    );
  }

  get countOfUnlimtedTeammates() {
    return this.appService.app.adminsWithUnlimitedCopilotAccess.length;
  }

  get countOfFreeTeammates() {
    return this.appService.app.adminsWithLimitedCopilotAccess.length;
  }

  get isCopilotSwitchDisabled() {
    return (
      taskFor(this.updateAiAssistSettings).isRunning ||
      (this.isCopilotEnabled && this.countOfUnlimtedTeammates > 0)
    );
  }

  @action openUserAgreementModal(settingToToggle: AiAssistSettingsToToggle) {
    this.settingToToggle = settingToToggle;
    this.isUserAgreementModalOpen = true;
  }

  @action closeUserAgreementModal() {
    this.isUserAgreementModalOpen = false;
    this.settingToToggle = AiAssistSettingsToToggle.NoSettingToggled;
  }

  @action openDisableCopilotModal() {
    this.isDisableCopilotModalOpen = true;
  }

  @action closeDisableCopilotModal() {
    this.isDisableCopilotModalOpen = false;
  }

  @action routeToTeammatesPage() {
    this.router.transitionTo('apps.app.settings.teammates');
  }

  @action async toggleSetting() {
    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleSummarizeAndTransform) {
      await this.toggleSummarizeAndTransform();
    }

    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleAutoFillTickets) {
      await this.toggleAutoFillTickets();
    }

    if (this.settingToToggle === AiAssistSettingsToToggle.ToggleAiAssistInArticle) {
      await this.toggleAiAssistInArticles();
    }

    this.closeUserAgreementModal();
  }

  @action async toggleSummarizeAndTransform() {
    try {
      let settings = await taskFor(this.updateAiAssistSettings).perform(
        this.canUseAiAssistToSummarizeAndTransform ? 'opt_out_inbox' : 'opt_in_inbox',
      );

      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleAiAssistInArticles() {
    try {
      let settings = await taskFor(this.updateAiAssistSettings).perform(
        this.canUseAiAssistInArticles ? 'opt_out_articles' : 'opt_in_articles',
      );

      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleAutoFillTickets() {
    try {
      if (this.appService.app.canUseAiAssistAutoFillTickets) {
        let settings = await taskFor(this.updateAiAssistSettings).perform(
          this.canUseAiAssistToAutoFillTickets
            ? 'opt_out_auto_fill_tickets'
            : 'opt_in_auto_fill_tickets',
        );

        this.aiAssistSettingsLoader.update(settings);
        this.notificationsService.notifyConfirmation(
          this.intl.t('ai-assist.settings.enabled.settings-updated'),
        );
      }
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @action async toggleCopilot() {
    try {
      let settings = await taskFor(this.updateAiAssistSettings).perform(
        this.isCopilotEnabled ? 'turn_off_copilot' : 'turn_on_copilot',
      );

      this.aiAssistSettingsLoader.update(settings);
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-assist.settings.enabled.settings-updated'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('ai-assist.settings.enabled.settings-update-failed'),
      );
      throw err;
    }
  }

  @use aiAssistSettingsLoader = AsyncData<AiAssistSettingsResponse>(async ({ signal }) => {
    let response = await request(`/ember/ai_assist_settings?app_id=${this.appService.app.id}`, {
      signal,
    });
    return response.json();
  });

  @dropTask *updateAiAssistSettings(
    updateType:
      | 'opt_in_articles'
      | 'opt_out_articles'
      | 'opt_in_inbox'
      | 'opt_out_inbox'
      | 'opt_in_auto_fill_tickets'
      | 'opt_out_auto_fill_tickets'
      | 'turn_on_copilot'
      | 'turn_off_copilot',
  ): TaskGenerator<AiAssistSettingsResponse> {
    return yield post(`/ember/ai_assist_settings?app_id=${this.appService.app.id}`, {
      update_type: updateType,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAssist::Settings': typeof AiAssistSettings;
    'ai-assist/settings': typeof AiAssistSettings;
  }
}
