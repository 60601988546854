/* import __COLOCATED_TEMPLATE__ from './article-hc-link-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  articleContent: any;
  article: any;
  displayColumn?: boolean;
}

export default class ArticleHcLinkSelector extends Component<Args> {
  @service declare helpCenterService: any;
  @tracked selectedHelpCenter: HelpCenterSite;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.selectedHelpCenter = this.initHelpCenter();
  }

  private initHelpCenter() {
    if (this.inMultipleHelpCenters || this.inOneHelpCenter) {
      return this.inHelpCenters.firstObject;
    } else {
      return this.allHelpCenters.firstObject;
    }
  }

  get articleContent() {
    return this.args.articleContent;
  }

  get allHelpCenters() {
    return this.helpCenterService.sites;
  }

  get inHelpCenters() {
    let helpCenterIds = this.args.article.inCollections.map(
      (collection: any) => collection.helpCenterId,
    );
    return this.allHelpCenters.filter((helpCenter: HelpCenterSite) =>
      helpCenterIds.includes(helpCenter.id),
    );
  }

  get inMultipleHelpCenters() {
    return this.inHelpCenters.length > 1;
  }

  get inOneHelpCenter() {
    return this.inHelpCenters.length === 1;
  }

  get shouldShowLink() {
    return (
      this.computedSelectedHelpCenter.turnedOn && this.computedSelectedHelpCenter.websiteTurnedOn
    );
  }

  // When removing an article from a collection, the selectedHelpCenter is not updated
  // This falls back to the first help center in the list / first help center in the app
  get computedSelectedHelpCenter() {
    if (this.inHelpCenters.includes(this.selectedHelpCenter)) {
      return this.selectedHelpCenter;
    } else if (this.inHelpCenters.length > 0) {
      return this.inHelpCenters.firstObject;
    } else {
      return this.allHelpCenters.firstObject;
    }
  }

  get url() {
    if (this.articleContent.externalUrl) {
      return this.articleContent.externalUrl;
    }
    if (!this.articleContent.publicUrl) {
      return null;
    }
    let id = `/articles/${this.articleContent.publicUrl.split('/articles/')[1]}`;
    let locale = this.articleContent.locale;
    let domain = this.computedSelectedHelpCenter.url;

    return `${domain}/${locale}${id}`;
  }

  get helpCenterOptions() {
    return this.inHelpCenters.map((site: HelpCenterSite) => {
      return {
        text: site.name,
        value: site.id,
      };
    });
  }

  @action switchHelpCenter(value: any) {
    this.selectedHelpCenter = this.allHelpCenters.find(
      (helpCenter: HelpCenterSite) => helpCenter.id === value,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Show::ArticleHcLinkSelector': typeof ArticleHcLinkSelector;
    'articles/show/article-hc-link-selector': typeof ArticleHcLinkSelector;
  }
}
