/* import __COLOCATED_TEMPLATE__ from './ai-categories.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import type RoutingSettings from 'embercom/models/ai-agent/routing-settings';
import type Store from '@ember-data/store';
import { isBlank } from '@ember/utils';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';

export interface Args {
  aiAgentRoutingSettings: RoutingSettings;
}

export const AI_CATEGORIES_LIMIT = 35;

export default class AiCategories extends Component<Args> {
  @service appService: any;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @tracked showEditCategoryModal = false;
  @tracked selectedCategory: ListOption | null = null;
  categoriesLimit = AI_CATEGORIES_LIMIT;

  @dropTask *toggleAiRouting(): TaskGenerator<void> {
    try {
      this.args.aiAgentRoutingSettings.enabled = !this.args.aiAgentRoutingSettings.enabled;
      yield this.args.aiAgentRoutingSettings.save();
      this.notificationsService.notifyConfirmation(
        this.args.aiAgentRoutingSettings.enabled
          ? this.intl.t('operator.fin.setup.ai-categories.toggled-on')
          : this.intl.t('operator.fin.setup.ai-categories.toggled-off'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: this.args.aiAgentRoutingSettings.enabled ? 'enabled' : 'disabled',
        object: 'ai_categorization',
        place: 'ai_categories',
        section: 'ai_categories',
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.ai-categories.toggle-error'),
      );
      this.args.aiAgentRoutingSettings.rollbackAttributes();
    }
  }

  @dropTask *createClassificationDescriptor(
    _id: string | null,
    label: string,
    description: string,
  ): TaskGenerator<boolean> {
    try {
      yield post(`/ember/ai_agent/routing_settings/create_classification_descriptor`, {
        app_id: this.appService.app.id,
        list_options: [
          {
            label: label.trim(),
            description: description.trim(),
          },
        ],
      });
      yield this.args.aiAgentRoutingSettings.reload();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.create-successful'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'ai_category',
        place: 'ai_categories',
        section: 'ai_categories',
      });
      return true;
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.save-error'),
      );
      return false;
    }
  }

  @dropTask *saveCategory(
    id: string | null,
    label: string,
    description: string,
  ): TaskGenerator<boolean> {
    let createMode = isBlank(id);
    let listOption: ListOption = createMode
      ? this.store.createRecord('conversation-attributes/list-option')
      : this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.findBy('id', id);

    try {
      listOption.setProperties({
        label: label.trim(),
        description: description.trim(),
      });
      if (createMode) {
        this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.pushObject(
          listOption,
        );
      }
      this.args.aiAgentRoutingSettings.classificationDescriptor.set(
        'listOptions',
        this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.rejectBy('archived'),
      );
      yield this.args.aiAgentRoutingSettings.classificationDescriptor.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.save-successful'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: createMode ? 'created' : 'edited',
        object: 'ai_category',
        place: 'ai_categories',
        section: 'ai_categories',
      });
      return true;
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.save-error'),
      );
      listOption.rollbackAttributes();
      return false;
    }
  }

  @dropTask *archiveCategory(category: ListOption): TaskGenerator<void> {
    let index =
      this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.indexOf(category);

    try {
      this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.removeAt(index);
      yield this.args.aiAgentRoutingSettings.classificationDescriptor.save();
      category.unloadRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.fin.setup.ai-categories.archive-successful'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'archived',
        object: 'ai_category',
        place: 'ai_categories',
        section: 'ai_categories',
      });
    } catch (e) {
      this.args.aiAgentRoutingSettings.classificationDescriptor.listOptions.insertAt(
        index,
        category,
      );
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.ai-categories.archive-error'),
      );
    }
  }

  @action addCategory() {
    this.selectedCategory = null;
    this.showEditCategoryModal = true;
  }

  @action editCategory(category: any) {
    this.selectedCategory = category;
    this.showEditCategoryModal = true;
  }

  @action closeEditCategoryModal() {
    this.selectedCategory = null;
    this.showEditCategoryModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::AiCategories': typeof AiCategories;
  }
}
