/* import __COLOCATED_TEMPLATE__ from './program-overview-hero.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import type IntlService from 'ember-intl/services/intl';
import { PLAN_DATA, PRICING_5_X_CORE_PLANS } from 'embercom/lib/billing';

type Status = 'starting' | 'ending' | 'complete' | 'cancelling' | 'new-subscription' | null;

const TRIAL = 0;
const YEAR_1 = 1;
const YEAR_2 = 2;
const YEAR_3 = 3;

export interface ProgramStep {
  planName: string;
  status: Status;
  startOrEndDateFormatted: string | null;
}

interface Args {
  earlyStageGraduation: any;
  subscriptionWillBeCancelledAt: Date;
  cancellationRequestedOutsideMigration: boolean;
  earlyStageProgrameName: string;
  esChoiceConfirmation?: boolean;
}

export default class ProgramOverviewHero extends Component<Args> {
  @service intl!: IntlService;

  @tracked progressValue = 0;
  @tracked dotLeftPosition = '';

  windowResize: () => void;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.windowResize = this.updateDotPosition.bind(this);
    if (args.esChoiceConfirmation) {
      window.addEventListener('resize', this.windowResize);
      later(() => this.launchProgressBarAnimation(), 0);
    } else {
      this.setProgressValueOfTheProgressBar();
    }
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('resize', this.windowResize);
  }

  get esChoiceConfirmation() {
    return this.args.esChoiceConfirmation ?? false;
  }

  @action
  launchProgressBarAnimation() {
    this.setProgressValueOfTheProgressBar();
    this.updateDotDisplayAndPosition();
  }

  @action
  setProgressValueOfTheProgressBar() {
    this.progressValue = this.calculateProgress;
  }

  @action
  updateDotDisplayAndPosition() {
    this.updateDotPosition();
  }

  @action
  updateDotPosition() {
    let dotProgressPercentage = this.calculateDotPosition;
    let progressBarMaxPercentage = 100;
    let progressBarWidth = this.progressBarOffsetWidth;
    let dotPosition = (dotProgressPercentage / progressBarMaxPercentage) * progressBarWidth;
    this.dotLeftPosition = `left: ${dotPosition}px;`;
  }

  get progressBarOffsetWidth() {
    let progressContainer = document.querySelector(
      '.early-stage__program-overview__progress-bar',
    ) as HTMLElement;
    return progressContainer?.offsetWidth || 0;
  }

  get calculateDotPosition() {
    return this.currentEarlyStageYear === 1 ? 50 : 75;
  }

  get trialBarPercentage() {
    return 25;
  }

  get programBarPercentage() {
    return 75;
  }

  get calculateTrialProgress() {
    let daysPassed = this.currentDate.diff(this.earlyStageTrialStartDate, 'days');
    let totalDays = this.earlyStageTrialEndDate.diff(this.earlyStageTrialStartDate, 'days');
    return (daysPassed / totalDays) * this.trialBarPercentage;
  }

  get calculateProgramProgress() {
    let daysPassed = this.currentDate.diff(this.earlyStageProgramStartDate, 'days');
    let totalDays = this.earlyStageProgramEndDate.diff(this.earlyStageProgramStartDate, 'days');
    return this.trialBarPercentage + (daysPassed / totalDays) * this.programBarPercentage;
  }

  get calculateProgress() {
    if (!this.isEarlyStageTrialComplete) {
      return this.calculateTrialProgress;
    } else {
      return this.calculateProgramProgress;
    }
  }

  get currentDate() {
    return moment();
  }

  get humanizedDateFormat() {
    return 'MMMM DD, YYYY';
  }

  //---------OVERALL PROGRAM DATA---------

  get programOverviewData() {
    return this.args.earlyStageGraduation.programOverviewData;
  }

  get currentEarlyStageYear() {
    return this.args.earlyStageGraduation.currentStepYearNumber;
  }

  get graduationInProgress() {
    return this.args.earlyStageGraduation.inProgress;
  }

  get earlyStageProgramStartDate() {
    return moment(this.programDataForYear(YEAR_1).startsAt);
  }

  get earlyStageProgramEndDate() {
    return moment(this.programDataForYear(YEAR_3).endsAt);
  }

  get earlyStageProgramName() {
    return this.args.earlyStageProgrameName;
  }

  //---------TRIAL DATA---------

  get isOnEarlyStageTrial() {
    return !this.isEarlyStageTrialComplete;
  }

  get earlyStageTrialStartDate() {
    return moment(this.programDataForYear(TRIAL).startsAt);
  }

  get earlyStageTrialEndDate() {
    return moment(this.programDataForYear(TRIAL).endsAt);
  }

  get isEarlyStageTrialComplete() {
    return this.currentDate.isAfter(this.earlyStageTrialEndDate);
  }

  get earlyStageTrialPlanName() {
    return this.intl
      .t('billing.summary.early-stage.program-overview.early-stage-trial', {
        programName: this.earlyStageProgramName,
      })
      .toUpperCase();
  }

  //---------YEAR 1 DATA--------

  get isOnEarlyStageYear1() {
    return this.currentEarlyStageYear === 1 && this.isEarlyStageTrialComplete;
  }

  get earlyStageYear1EndDate() {
    return moment(this.programDataForYear(YEAR_1).endsAt);
  }

  get isEarlyStageYear1Complete() {
    return this.currentEarlyStageYear > 1;
  }

  get earlyStageYear1PlanName() {
    return this.intl
      .t('billing.summary.early-stage.program-overview.early-stage-year-1', {
        programName: this.earlyStageProgramName,
      })
      .toUpperCase();
  }

  //---------YEAR 2 DATA--------

  get isOnEarlyStageYear2() {
    return this.currentEarlyStageYear === 2;
  }

  // we only display this value if the customer is on year 1
  get earlyStageYear2StartDate() {
    return moment(this.programDataForYear(YEAR_2).startsAt);
  }

  get earlyStageYear2EndDate() {
    return moment(this.programDataForYear(YEAR_2).endsAt);
  }

  get earlyStageYear2PlanName() {
    return this.intl.t('billing.summary.early-stage.program-overview.early-stage-year-2');
  }

  //---------YEAR 3 DATA--------

  get isOnEarlyStageYear3() {
    return this.currentEarlyStageYear === 3;
  }

  // we only display this value if the customer is on year 1 or year 2
  get earlyStageYear3StartDate() {
    return moment(this.programDataForYear(YEAR_3).startsAt);
  }

  get earlyStageYear3PlanName() {
    return this.intl.t('billing.summary.early-stage.program-overview.early-stage-year-3');
  }

  //---------GRADUATION/CANCELLATION DATA--------

  get isLeavingEarlyStage() {
    return this.isSubscriptionCancelled || this.graduatingToListPrice;
  }

  get graduatingToListPrice() {
    return (
      this.args.earlyStageGraduation.inProgress &&
      !this.args.earlyStageGraduation.isGraduatingToEarlyStage
    );
  }

  get earlyStageGraduatingCorePlanName() {
    let plans = this.args.earlyStageGraduation.planIds;
    let graduatingCorePlanId = plans.find((id: any) =>
      PRICING_5_X_CORE_PLANS.includes(id.toString()),
    );
    return graduatingCorePlanId
      ? this.intl.t(PLAN_DATA[graduatingCorePlanId].marketingNameTranslationKey)
      : '';
  }

  get isSubscriptionCancelled() {
    return this.args.cancellationRequestedOutsideMigration;
  }

  get cancellationDate() {
    return moment(this.args.subscriptionWillBeCancelledAt);
  }

  //---------PROGRAM STEPS DATA--------

  programDataForYear(year: number) {
    return this.programOverviewData.find((data: any) => data.year === year);
  }

  @action
  buildEarlyStageProgramStep(
    planName: string,
    hasStarted: boolean,
    isComplete = false,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
  ): ProgramStep {
    let programStep = {} as ProgramStep;
    programStep.planName = planName;

    if (hasStarted) {
      programStep.status = 'ending';
    } else if (isComplete) {
      programStep.status = 'complete';
    } else {
      programStep.status = 'starting';
    }

    if (hasStarted) {
      programStep.startOrEndDateFormatted = endDate?.format(this.humanizedDateFormat) || null;
    } else if (isComplete) {
      programStep.startOrEndDateFormatted = null;
    } else {
      programStep.startOrEndDateFormatted = startDate?.format(this.humanizedDateFormat) || null;
    }

    return programStep;
  }

  @action
  buildLeavingStep(): ProgramStep {
    if (this.isSubscriptionCancelled) {
      return {
        planName: this.intl.t('billing.summary.early-stage.program-overview.cancelled'),
        status: 'cancelling',
        startOrEndDateFormatted: this.cancellationDate.format(this.humanizedDateFormat),
      };
    } else {
      return {
        planName: this.earlyStageGraduatingCorePlanName.toUpperCase(),
        status: 'new-subscription',
        startOrEndDateFormatted: moment(this.args.earlyStageGraduation.graduationDate).format(
          this.humanizedDateFormat,
        ),
      };
    }
  }

  get earlyStageProgramSteps() {
    let earlyStageProgramSteps: (ProgramStep | null)[] = [];

    let earlyStageTrialStep = this.buildEarlyStageProgramStep(
      this.earlyStageTrialPlanName,
      this.isOnEarlyStageTrial,
      this.isEarlyStageTrialComplete,
      this.earlyStageTrialStartDate,
      this.earlyStageTrialEndDate,
    );
    let earlyStageStepYear1 = this.buildEarlyStageProgramStep(
      this.earlyStageYear1PlanName,
      this.isOnEarlyStageYear1,
      this.isEarlyStageYear1Complete,
      this.earlyStageProgramStartDate,
      this.earlyStageYear1EndDate,
    );
    let earlyStageStepYear2 = this.buildEarlyStageProgramStep(
      this.earlyStageYear2PlanName,
      this.isOnEarlyStageYear2,
      this.isOnEarlyStageYear3,
      this.earlyStageYear2StartDate,
      this.earlyStageYear2EndDate,
    );
    let earlyStageStepYear3 = this.buildEarlyStageProgramStep(
      this.earlyStageYear3PlanName,
      this.isOnEarlyStageYear3,
      false,
      this.earlyStageYear3StartDate,
      this.earlyStageProgramEndDate,
    );

    earlyStageProgramSteps = [
      earlyStageTrialStep,
      earlyStageStepYear1,
      earlyStageStepYear2,
      earlyStageStepYear3,
    ];

    if (this.isLeavingEarlyStage) {
      let leavingStep = this.buildLeavingStep();

      if (this.isOnEarlyStageTrial) {
        earlyStageProgramSteps[1] = leavingStep;
        earlyStageProgramSteps[2] = null;
        earlyStageProgramSteps[3] = null;
      } else if (this.isOnEarlyStageYear1) {
        earlyStageProgramSteps[2] = leavingStep;
        earlyStageProgramSteps[3] = null;
      } else if (this.isOnEarlyStageYear2) {
        earlyStageProgramSteps[3] = leavingStep;
      } else if (this.isOnEarlyStageYear3) {
        //what should we show if the customer cancelled on year 3?
        if (this.isSubscriptionCancelled) {
          leavingStep.planName = 'early-stage-year-3';
        }
        earlyStageProgramSteps[3] = leavingStep;
      }
    }

    return earlyStageProgramSteps;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::EarlyStage::ProgramOverviewHero': typeof ProgramOverviewHero;
    'billing/summary/early-stage/program-overviewHero': typeof ProgramOverviewHero;
  }
}
