/* import __COLOCATED_TEMPLATE__ from './pause-subscription-modal.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';
import type PauseSubscriptionModalService from 'embercom/services/pause-subscription-modal-service';

interface Signature {
  Args: {
    isOpen: boolean;
    onModalClose: () => void;
  };
}
export default class PauseSubscriptionModal extends Component<Signature> {
  @service declare pauseSubscriptionModalService: PauseSubscriptionModalService;
  @service appService: any;
  @service router: any;
  @service purchaseAnalyticsService: any;

  get app() {
    return this.appService.app;
  }

  @action
  handleModalClose() {
    this.args.onModalClose();
  }

  @action
  cancelSubscription() {
    this.pauseSubscriptionModalService.close();
    if (this.app.canSeeSelfServeChurnSurvey) {
      // The last step of the new self serve churn Survey will
      // redirect customers to their cancellation page.
      startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
    } else {
      this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
    }
  }

  @action
  schedulePause() {
    this.pauseSubscriptionModalService.close();
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'pause_subscription_modal',
      place: 'billing_settings',
      object: 'pause',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Settings::PauseSubscriptionModal': typeof PauseSubscriptionModal;
    'billing/settings/pause-subscription-modal': typeof PauseSubscriptionModal;
  }
}
