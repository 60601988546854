/* import __COLOCATED_TEMPLATE__ from './configure-trigger-words-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Args: {
    answer: $TSFixMe; // embercom/models/custom-answers/custom-answer
    onClose: () => void;
  };
}

export default class ConfigureTriggerWordsModal extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @tracked triggerWordsLineSeparatedList: string;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.triggerWordsLineSeparatedList = this.args.answer.triggerWordsList.join('\n');
  }

  @action
  setTriggerWords() {
    let triggerWords = this.triggerWordsLineSeparatedList.split('\n');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'configured',
      object: 'trigger_word',
      context: 'trigger_words_modal',
      keywords: triggerWords.join(', '),
    });
    this.args.answer.setControlCriteriaFromTriggerWordsList(triggerWords);
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::Editor::QuestionsPanel::ConfigureTriggerWordsModal': typeof ConfigureTriggerWordsModal;
    'answers/editor/questions-panel/configure-trigger-words-modal': typeof ConfigureTriggerWordsModal;
  }
}
