/* import __COLOCATED_TEMPLATE__ from './begin-trial.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import {
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  defaultSolutionId,
  findLatestPricing5PlanData,
  findLatestVBP2PlanData,
} from 'embercom/lib/billing';
import RouteRegexes from 'embercom/lib/route-regexes';

export default class BeginTrial extends Component {
  @service router;
  @service store;
  @service purchaseAnalyticsService;
  @service cardlessTrialService;
  @service intl;
  @service cardlessConversionModalService;
  @service appService;
  @tracked earlyStagePartner;

  constructor() {
    super(...arguments);
    this.checkAction();
    if (!this.app) {
      this.fetchRequiredAttributes();
    } else {
      this.earlyStagePartner = this.app.earlyStagePartner;
    }
  }

  get app() {
    return this.appService.app;
  }

  get validEarlyStageApplicant() {
    return this.cardlessTrialService.customer?.validEarlyStageApplicant;
  }

  get showUpgradeButton() {
    return this.cardlessTrialService.isInCardlessTrial;
  }

  get isInOnboardingHomeRoute() {
    return this.router.currentRouteName.match(RouteRegexes.onboardingHome);
  }

  get isInGuideLibraryRoute() {
    return this.router.currentRouteName.match(RouteRegexes.guideLibrary);
  }

  get showContinueButton() {
    return (
      !this.isInOnboardingHomeRoute &&
      !this.isInGuideLibraryRoute &&
      !this.app?.canUseGreatGuidanceMilestoneOne
    );
  }

  get buyAdvancedNudgeEnabled() {
    return this.app?.buyAdvancedNudgeEnabled;
  }

  get showRecommendedForCompanySize() {
    return this.buyAdvancedNudgeEnabled;
  }

  get upgradeButtonLabel() {
    if (this.buyAdvancedNudgeEnabled) {
      return this.intl.t('top-banner-selector.upgrade_to_advanced');
    }
    return this.intl.t('top-banner-selector.upgrade');
  }

  get trialPlanName() {
    let trialPlanData;
    let trialPlanIds = this.cardlessTrialService.activeOrExpiredPlanTrialIds;

    if (this.cardlessTrialService.isPricing5) {
      trialPlanData = findLatestPricing5PlanData(trialPlanIds);
    } else {
      trialPlanData = findLatestVBP2PlanData(trialPlanIds);
    }
    return this.intl.t(trialPlanData.nameTranslationKey);
  }

  @action
  onUpgrade() {
    if (this.buyAdvancedNudgeEnabled) {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'upgrade_to_advanced_button',
        context: 'cardless_trial',
        place: 'enter_demo_banner',
      });
      let appId = this.cardlessTrialService.isTestApp
        ? this.cardlessTrialService.parentAppId
        : this.cardlessTrialService.appId;
      this.router.transitionTo('apps.app.teams-checkout.plan', appId, {
        queryParams: {
          solution_id: defaultSolutionId(PRICING_5_X_CORE_ADVANCED_ID),
          cardless_trial: true,
        },
      });
    } else {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'upgrade_button',
        context: 'cardless_trial',
        place: 'enter_demo_banner',
      });
      this.cardlessConversionModalService.toggle();
    }
  }

  @action
  openAllPlans() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'see_all_plans_link',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
    });
    this.cardlessConversionModalService.toggle();
  }

  @action
  transitionToCheckout() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'start_early_stage_subscription_button',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
    });

    let queryParams = {
      solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
    };

    if (this.earlyStagePartner) {
      queryParams.partner = true;
    }
    this.router.transitionTo('apps.app.teams-checkout.confirm', this.cardlessTrialService.appId, {
      queryParams,
    });
  }

  @action
  requestTrialExtension() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'enter_demo_banner',
      object: 'request_extension_button',
    });
  }

  @action
  transitionToOnboardingHome() {
    this.store.findRecord('onboarding/home/guide', this.app.id);
    return this.router.transitionTo('apps.app.getting-started', this.app.id);
  }

  checkAction() {
    if (this.showUpgradeButton) {
      let queryParams = this.router.currentRoute?.queryParams;
      if (queryParams && queryParams.action === 'buy_intercom') {
        this.cardlessConversionModalService.toggle();
      }
    }
  }

  async fetchRequiredAttributes() {
    let response = await get(
      `/ember/apps/${this.cardlessTrialService.appId}.json?attribute_allowlist=early_stage_partner`,
    );
    this.earlyStagePartner = response.early_stage_partner;
  }
}
