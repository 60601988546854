/* import __COLOCATED_TEMPLATE__ from './group-current-usage-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import {
  ACTIVE_PHONE_NUMBERS_COUNT_GROUP,
  SALES_LED_ONLY_SMS_METRICS,
} from 'embercom/lib/purchase/constants';
import { inject as service } from '@ember/service';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import type IntlService from 'embercom/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';

export const CALL_TO_ACTION_GROUPS: {
  [key: string]: { url: string; titleTranslationKey: string };
} = {
  [ACTIVE_PHONE_NUMBERS_COUNT_GROUP]: {
    url: 'apps.app.outbound.settings.sms',
    titleTranslationKey: 'activate-number',
  },
};

interface Args {
  testIdentifier: string;
  metricIdentifier: Metric;
  isSalesforceContracted: boolean;
  hasSingleWorkspace: boolean;
  isSecondarySubscription: boolean;
  usage: string;
  price: string | undefined;
  overageTooltipData: any;
  actualUsageAsInt: number;
  metricDisplayName: string;
  charge: Charge;
  contract?: Contract;
}

export default class GroupCurrentUsageRow extends Component<Args> {
  @service declare intl: IntlService;

  get title() {
    if (this.isSalesforceContracted) {
      return this.intl.t('billing.summary.breakdown.current-usage.default');
    } else {
      return this.intl.t('billing.summary.breakdown.current-usage.with-metric-group', {
        metricDisplayName: this.args.metricDisplayName,
      });
    }
  }

  get showCallToAction() {
    return (
      this.args.actualUsageAsInt === 0 &&
      this.mappedMetricInCallToActionList &&
      CALL_TO_ACTION_GROUPS[this.mappedMetricInCallToActionList]
    );
  }

  get callToActionMetricsList() {
    return Object.keys(CALL_TO_ACTION_GROUPS);
  }

  get mappedMetricInCallToActionList() {
    return this.callToActionMetricsList.find((metric) =>
      this.args.metricIdentifier.startsWith(metric),
    );
  }

  get hideCustomMetricWhenSelfServe() {
    return (
      !this.isSalesforceContracted &&
      SALES_LED_ONLY_SMS_METRICS.includes(this.args.metricIdentifier)
    );
  }

  get isSalesforceContracted() {
    return this.args.isSalesforceContracted;
  }

  // Add right padding if we're not showing the price breakdowntooltip
  get addPricePadding() {
    return !(this.args.price && this.args.overageTooltipData);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::GroupCurrentUsageRow': typeof GroupCurrentUsageRow;
  }
}
