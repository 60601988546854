/* import __COLOCATED_TEMPLATE__ from './do-not-suggest-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    answer: $TSFixMe; // embercom/models/custom-answers/custom-answer
    class?: string;
  };
}

const DoNotSuggestStamp = templateOnlyComponent<Signature>();
export default DoNotSuggestStamp;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::DoNotSuggestStamp': typeof DoNotSuggestStamp;
  }
}
