/* import __COLOCATED_TEMPLATE__ from './answer-list-table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const COLUMNS_TO_SORT_BY_ASCENDING = ['title'];

export default class AnswerListTable extends Component {
  @service intercomEventService;
  @service appService;

  @tracked sortBy = 'createdAt';
  @tracked isSortDirectionAscending = false;
  @tracked answersRoute = this.appService.app.answersRoute;
  @tracked answersShowRoute = `${this.answersRoute}.answer.show`;
  @tracked answersShowConversationsRoute = `${this.answersRoute}.answer.show.conversations`;

  get sortDirection() {
    return this.isSortDirectionAscending ? 'asc' : 'desc';
  }

  @action
  sort(sortBy) {
    let existingSortBy = this.sortBy;

    if (sortBy === existingSortBy) {
      this.isSortDirectionAscending = !this.isSortDirectionAscending;
    } else {
      let sortInAscendingOrder = COLUMNS_TO_SORT_BY_ASCENDING.includes(sortBy);
      this.isSortDirectionAscending = sortInAscendingOrder;
    }

    this.sortBy = sortBy;
  }

  @action
  trackViewConversationsAnalytics(answer) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'conversations',
      context: 'answer_interjection',
      answer_id: answer.id,
    });
  }

  @action
  applyDefaultSort(_element, [status]) {
    this.isSortDirectionAscending = false;

    if (status === 'live') {
      this.sortBy = 'firstWentLiveAt';
    } else if (status === 'paused') {
      this.sortBy = 'lastPausedAt';
    } else if (status === 'underperforming') {
      this.sortBy = 'stats.effectivenessPercentage';
      this.isSortDirectionAscending = true;
    } else {
      this.sortBy = 'createdAt';
    }
  }
}
