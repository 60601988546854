/* import __COLOCATED_TEMPLATE__ from './collection-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TreeItem } from 'embercom/objects/tree-list';
import { MAX_LEVEL } from './collection-tree-helper';
import { type Collection, type DataObject, DataTypes } from './types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface Args {
  item: TreeItem;
}

export default class CollectionItem extends Component<Args> {
  collectionDataObject = this.args.item.dataObject as DataObject;
  @tracked openArticlePicker = false;
  @tracked loading = false;
  @tracked addableArticles: Array<ArticleMultilingual> = [];
  @tracked selectedArticles: Array<any> = [];
  @service declare intl: IntlService;

  get locale() {
    return this.collectionDataObject.locale();
  }

  get collection() {
    return this.collectionDataObject.content as Collection;
  }

  @action
  async onAdd(dataType: DataTypes) {
    if (dataType === DataTypes.collection && this.collectionDataObject.addCollection) {
      await this.collectionDataObject.addCollection();
    } else if (dataType === DataTypes.article && this.collectionDataObject.listAddableArticles) {
      this.openArticlePicker = true;
      this.loading = true;
      let addableArticles = await this.collectionDataObject.listAddableArticles();
      this.addableArticles = addableArticles.reject(
        (article: ArticleMultilingual) => article.readOnly,
      );
      this.selectedArticles = [];
      this.loading = false;
    }
  }

  @action
  closeArticlePicker() {
    this.openArticlePicker = false;
  }

  @action
  async addSelectedArticleToCollection() {
    this.loading = true;
    if (this.collectionDataObject.addArticlesToCollection) {
      await this.collectionDataObject.addArticlesToCollection(this.selectedArticles);
    }
    this.loading = false;
    this.openArticlePicker = false;
  }

  @action
  async removeCollection() {
    if (this.collectionDataObject.deleteCollection) {
      await this.collectionDataObject.deleteCollection();
    }
  }

  @action
  async saveCollection() {
    if (this.collectionDataObject.saveCollection) {
      await this.collectionDataObject.saveCollection();
    }
  }

  // Toggle isExpanded unless the click is on an element preventing expansion
  @action
  toggleIsExpanded(event: PointerEvent) {
    let preventExpandElements = Array.from(
      document.getElementsByClassName('prevent-colleciton-tree-expand'),
    );
    let target = event.target;

    if (!(target instanceof Element)) {
      return;
    }

    for (let element of preventExpandElements) {
      if (element.contains(target)) {
        return;
      }
    }

    this.args.item.isExpanded = !this.args.item.isExpanded;
  }

  get contents() {
    let items = [
      {
        text: this.intl.t('articles.collections.actions.add_articles'),
        value: DataTypes.article,
        icon: 'article',
      },
    ];
    if (this.collectionDataObject.level < MAX_LEVEL) {
      items.push({
        text: this.intl.t('articles.collections.actions.add_child_collection'),
        value: DataTypes.collection,
        icon: 'collection',
      });
    }
    return [
      {
        items,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::CollectionItem': typeof CollectionItem;
    'articles/site/collections/tree/collection-item': typeof CollectionItem;
  }
}
