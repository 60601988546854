/* import __COLOCATED_TEMPLATE__ from './regulatory-address-section.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { get } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import type RegulatoryAddress from 'embercom/models/settings/calling/regulatory_address';
import Countries from 'embercom/lib/countries';
import { countrySelect } from 'embercom/helpers/country-select-helper';
import { COUNTRIES_WITH_STATE } from 'embercom/models/calling-phone-number';
import {
  LOCAL_ADDRESS_REQUIREMENTS_BY_COUNTRY,
  type PhoneNumberType,
} from 'embercom/models/settings/calling';

export interface SuggestedAddress {
  friendly_name: string;
  customer_name: string;
  iso_country: string;
  street: string;
  street_secondary: string;
  city: string;
  region: string;
  postal_code: string;
}

interface Args {
  regulatoryAddressSuggestedFields?: SuggestedAddress;
  regulatoryAddressValidationError?: string;
  selectedAddressSid?: string;
  readOnly?: boolean;
  countryCode: string;
  phoneNumberType: PhoneNumberType;
  onAddressSelected: (regulatoryAddressSid: string) => void;
  onAddressCreated: (regulatoryAddress: RegulatoryAddress) => void;
}

export default class RegulatoryAddressSection extends Component<Args> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked allRegulatoryAddresses: RegulatoryAddress[] = [];
  @tracked regulatoryAddressSid?: string = this.args.selectedAddressSid;
  @tracked showNewRegulatoryAddress = false;
  @tracked newRegulatoryAddress?: RegulatoryAddress;
  @tracked isLoadingRegulatoryAddresses = true;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.fetchRegulatoryAddressesWhenRequired();
  }

  @action async fetchRegulatoryAddressesWhenRequired() {
    this.isLoadingRegulatoryAddresses = true;
    if (this.allRegulatoryAddresses.length === 0) {
      let response = await taskFor(this.fetchRegulatoryAddresses).perform();
      this.store.pushPayload({ 'settings/calling/regulatory_address': response });
      this.allRegulatoryAddresses = this.store.peekAll(
        'settings/calling/regulatory_address',
      ) as unknown as RegulatoryAddress[];
    }
    this.isLoadingRegulatoryAddresses = false;
  }

  get regulatoryAddresses() {
    if (this.shouldRestrictCountrySelection) {
      return this.allRegulatoryAddresses.filter(
        (address: RegulatoryAddress) => address.isoCountry === this.args.countryCode,
      );
    }
    return this.allRegulatoryAddresses;
  }

  @dropTask *fetchRegulatoryAddresses(): TaskGenerator<RegulatoryAddress[]> {
    return yield get(`/ember/calling_settings/fetch_regulatory_addresses`, {
      app_id: this.appService.app.id,
    }) as RegulatoryAddress[];
  }

  get regulatoryAddressesOptions() {
    return this.regulatoryAddresses
      ?.filter((regulatoryAddress: RegulatoryAddress) => {
        return !regulatoryAddress.isNew;
      })
      .map((regulatoryAddress: RegulatoryAddress) => ({
        text: regulatoryAddress.friendlyName,
        value: regulatoryAddress.id,
        componentAttrs: {
          description: [
            regulatoryAddress.street,
            regulatoryAddress.streetSecondary,
            regulatoryAddress.postalCode,
            regulatoryAddress.city,
            regulatoryAddress.region,
            regulatoryAddress.isoCountry,
          ]
            .filter((part) => part)
            .join(', '),
        },
        component: 'calling/settings/phone-number-provisioning/regulatory-address-option',
      }));
  }

  get countries() {
    return countrySelect(Countries.allCountries);
  }

  @action onSelectRegulatoryAddress(regulatoryAddressSid: string) {
    this.resetRegulatoryAddress();
    this.regulatoryAddressSid = regulatoryAddressSid;
    this.args.onAddressSelected(regulatoryAddressSid);
  }

  @action onShowNewRegulatoryAddress() {
    this.resetRegulatoryAddress();
    this.showNewRegulatoryAddress = true;
    this.newRegulatoryAddress = this.store.createRecord('settings/calling/regulatory_address', {
      isoCountry: this.args.countryCode,
    }) as RegulatoryAddress;
    this.args.onAddressCreated(this.newRegulatoryAddress);
  }

  get regulatoryAddressSelectLabel() {
    if (this.showNewRegulatoryAddress) {
      return this.intl.t(
        'channels.video-call.settings.phone-call-settings.provisioning-modal.regulatory-address-add-new',
      );
    } else {
      return this.intl.t(
        'channels.video-call.settings.phone-call-settings.provisioning-modal.regulatory-address-default-label',
      );
    }
  }

  get isValid() {
    if (this.showNewRegulatoryAddress) {
      return this.newRegulatoryAddress!.validations.isValid;
    }
    return !!this.regulatoryAddressSid;
  }

  @action applySuggestedAddress(event: Event) {
    event.preventDefault();

    if (this.args.regulatoryAddressSuggestedFields) {
      this.newRegulatoryAddress!.friendlyName =
        this.args.regulatoryAddressSuggestedFields.friendly_name;
      this.newRegulatoryAddress!.customerName =
        this.args.regulatoryAddressSuggestedFields.customer_name;
      this.newRegulatoryAddress!.isoCountry =
        this.args.regulatoryAddressSuggestedFields.iso_country;
      this.newRegulatoryAddress!.street = this.args.regulatoryAddressSuggestedFields.street;
      this.newRegulatoryAddress!.streetSecondary =
        this.args.regulatoryAddressSuggestedFields.street_secondary;
      this.newRegulatoryAddress!.city = this.args.regulatoryAddressSuggestedFields.city;
      this.newRegulatoryAddress!.region = this.args.regulatoryAddressSuggestedFields.region;
      this.newRegulatoryAddress!.postalCode =
        this.args.regulatoryAddressSuggestedFields.postal_code;
    }
  }

  get selectedRegulatoryAddress() {
    return this.regulatoryAddresses?.findBy('id', this.regulatoryAddressSid);
  }

  @action resetRegulatoryAddress() {
    this.regulatoryAddressSid = undefined;
    this.showNewRegulatoryAddress = false;
    if (this.newRegulatoryAddress && !this.newRegulatoryAddress.isDeleted) {
      this.newRegulatoryAddress.deleteRecord();
    }
  }

  @action onSelectRegulatoryAddressCountryIsoCode(isoCode: string) {
    this.newRegulatoryAddress!.isoCountry = isoCode;
  }

  get shouldRestrictCountrySelection() {
    return LOCAL_ADDRESS_REQUIREMENTS_BY_COUNTRY.get(this.args.countryCode)?.includes(
      this.args.phoneNumberType,
    );
  }

  get regionPlaceholder() {
    let countriesWithState = COUNTRIES_WITH_STATE.split(',');
    if (
      this.newRegulatoryAddress?.isoCountry &&
      countriesWithState.indexOf(this.newRegulatoryAddress?.isoCountry) > -1
    ) {
      return this.intl.t(
        'channels.video-call.settings.phone-call-settings.provisioning-modal.regulatory-address.state',
      );
    } else {
      return this.intl.t(
        'channels.video-call.settings.phone-call-settings.provisioning-modal.regulatory-address.region',
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneNumberProvisioning::RegulatoryAddressSection': typeof RegulatoryAddressSection;
    'calling/settings/phone-number-provisioning/regulatory-address-section': typeof RegulatoryAddressSection;
  }
}
