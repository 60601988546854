/* import __COLOCATED_TEMPLATE__ from './answers-coverage.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import percent from 'embercom/lib/percentage-formatter';
import { inject as service } from '@ember/service';

export default class AnswersCoverage extends Component {
  @service appService;

  get coverage() {
    return this.args.conversationsSent > 0
      ? percent(this.args.conversationsSent, this.args.conversationsWithAnswers)
      : 0;
  }
}
