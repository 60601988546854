/* import __COLOCATED_TEMPLATE__ from './cluster-list-table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import percent from 'embercom/lib/percentage-formatter';

const PAGE_SIZE = 20;

export default class ClusterListTable extends Component {
  @service appService;
  @service notificationsService;
  @service router;
  @service store;
  @service intercomEventService;

  @tracked tableData = [];
  @tracked isDismissing = false;
  @tracked isLoading = true;
  @tracked clusterForTraining = null;
  @tracked page = 1;
  @tracked showSideDrawer = false;
  @tracked openAnswerTrainingView = false;

  pageSize = PAGE_SIZE;

  get canLoadMore() {
    return this.totalPages > this.page;
  }

  get tableIsEmpty() {
    return !this.tableData.length;
  }

  get shouldShowEmptyState() {
    return !this.args.isLoading && !this.isLoading && this.tableIsEmpty;
  }

  get totalPages() {
    return Math.ceil(this.args.clusterIds.length / this.pageSize);
  }

  get lastUpdated() {
    return this.args.clusterRunUpdatedAt ? new Date(this.args.clusterRunUpdatedAt) : null;
  }

  @action
  createNewAnswer(data) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'suggested_answers_list_create_new_button',
      action: 'clicked',
      place: 'answer_bot',
      section: 'operator',
      cluster_id: data.clusterId,
      cluster_language: data.language,
      cluster_archetype: data.text,
    });

    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        exampleId: data.clusterId,
        exampleSource: 'answers_clusters',
        exampleText: data.text,
        expandSelectedExamples: true,
        language: data.language,
        transitionBackTo: 'apps.app.automation.resolution-bot.clusters',
      },
    });
  }

  @action
  dismissCluster(data) {
    this._dismissCluster.perform({
      clusterRunId: this.args.clusterRunId,
      clusterId: data.clusterId,
      locale: data.language,
      archetype_text: data.text,
      documentId: data.documentId,
    });
  }

  @restartableTask
  *_dismissCluster({ clusterRunId, clusterId, locale, archetype_text, documentId }) {
    try {
      this.isDismissing = true;
      let response = yield ajax({
        url: `/ember/custom_answers/suggested_answer_clusters/${clusterId}/dismiss`,
        type: 'PUT',
        data: JSON.stringify({
          cluster_run_id: clusterRunId,
          locale,
          archetype_text,
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          document_id: documentId,
        }),
      });
      this.tableData = this._removeTableData(clusterId);
      this.toggleClusterSideDrawer({ cluster: null, showSideDrawer: false, trainingView: false });
      this.notificationsService.notifyConfirmation('This group of questions has been dismissed.');
      this.args.onDismiss(clusterId);
      return response;
    } catch (e) {
      let errorMessage =
        e.jqXHR.status === 409
          ? e.jqXHR.responseJSON.errors
          : 'Something went wrong, try reloading the page.';
      this.notificationsService.notifyError(errorMessage);
    } finally {
      this.isDismissing = false;
    }
  }

  @action
  restoreCluster(data) {
    this._restoreCluster.perform({
      clusterRunId: this.args.clusterRunId,
      clusterId: data.clusterId,
      dismissalRecordId: data.dismissalRecord.id,
    });
  }

  @restartableTask
  *_restoreCluster({ clusterRunId, clusterId, dismissalRecordId }) {
    try {
      this.isRestoring = true;
      let response = yield ajax({
        url: `/ember/custom_answers/suggested_answer_clusters/restore`,
        type: 'PUT',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          cluster_run_id: clusterRunId,
          dismissal_record_id: dismissalRecordId,
        }),
      });
      this.tableData = this._removeTableData(clusterId);
      this.toggleClusterSideDrawer({ cluster: null, showSideDrawer: false, trainingView: false });
      this.notificationsService.notifyConfirmation('This group of questions has been restored.');
      this.args.onRestore(clusterId);
      return response;
    } catch (e) {
      let errorMessage =
        e.jqXHR.status === 409
          ? e.jqXHR.responseJSON.errors
          : 'Something went wrong, try reloading the page.';
      this.notificationsService.notifyError(errorMessage);
    } finally {
      this.isRestoring = false;
    }
  }

  @action
  toggleTrainingModal(data, showModal) {
    if (showModal) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'suggested_answers_list_train_existing_button',
        action: 'clicked',
        place: 'answer_bot',
        section: 'operator',
        cluster_id: data.clusterId,
        cluster_language: data.language,
        cluster_archetype: data.text,
      });
    }

    this.clusterForTraining = data;
    this.showModal = showModal;
  }

  @action
  toggleClusterSideDrawer({ cluster, showSideDrawer, trainingView }) {
    if (showSideDrawer) {
      let object = trainingView
        ? 'suggested_answers_list_train_existing_button'
        : 'suggested_answers_list_cluster_detail_link';

      this.intercomEventService.trackAnalyticsEvent({
        object,
        action: 'clicked',
        place: 'answer_bot',
        section: 'operator',
        cluster_id: cluster.clusterId,
        cluster_language: cluster.language,
        cluster_archetype: cluster.text,
      });
    }

    this.clusterForTraining = cluster;
    this.showSideDrawer = showSideDrawer;
    this.openAnswerTrainingView = trainingView;
  }

  @action
  async loadFirstPage() {
    this.tableData = [];
    this.page = 1;
    try {
      await this._loadPage.perform({ page: 1 });
      this._showExamplesIfRequested();
    } catch (e) {
      /* task canceled when refreshing clusters */
    }
  }

  @action
  loadNextPage() {
    let page = this.page + 1;

    this.intercomEventService.trackAnalyticsEvent({
      object: 'suggested_answers_list_load_more',
      action: 'clicked',
      place: 'answer_bot',
      section: 'operator',
      page_number: page,
    });

    this._loadPage.perform({ page });
  }

  @restartableTask
  *_loadPage({ page }) {
    this.isLoading = true;

    // wait to fetch page until @isLoading resolves
    while (this.args.isLoading) {
      yield timeout(50);
    }

    let start = (page - 1) * this.pageSize;
    let clusterIds = this.args.clusterIds.slice(start, start + this.pageSize);
    let response = yield clusterIds.length
      ? this.fetchData.perform({ cluster_ids: clusterIds })
      : [];

    if (response !== undefined) {
      this.page = page;
      this.tableData = this._addTableData(response);
    }
    this.isLoading = false;
  }

  @restartableTask
  *fetchData({ cluster_ids }) {
    try {
      return yield ajax({
        url: '/ember/custom_answers/suggested_answer_clusters',
        type: 'GET',
        data: {
          cluster_ids,
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          interval: this.args.interval,
          cluster_run_id: this.args.clusterRunId,
          ...(this.args.locale && { locale: this.args.locale }),
        },
      });
    } catch (e) {
      let errorMessage =
        e.jqXHR.status === 409
          ? e.jqXHR.responseJSON.errors
          : 'Something went wrong, try reloading the page.';
      debounce(
        this.notificationsService,
        this.notificationsService.notifyError,
        errorMessage,
        4000,
        1000,
        true,
      );
    }
  }

  _addTableData(payload) {
    let tableDataForClusters = payload.map(this._buildTableDataForCluster);
    let sortedTableDataForClusters = this._sortTableData(tableDataForClusters);
    return this.tableData.concat(sortedTableDataForClusters);
  }

  _removeTableData(clusterId) {
    return this.tableData.filter((data) => data.clusterId !== clusterId);
  }

  _buildTableDataForCluster(clusterData) {
    let { current_time_period_count: currentCount, previous_time_period_count: previousCount } =
      clusterData;

    return {
      currentCount,
      previousCount,
      percentChange: percent(previousCount, currentCount - previousCount),
      clusterId: clusterData.cluster_id,
      text: clusterData.archetype_text,
      language: clusterData.locale,
      documentId: clusterData.document_id,
      dismissalRecord: !clusterData.dismissal_record
        ? null
        : {
            id: clusterData.dismissal_record.id,
            adminId: clusterData.dismissal_record.admin_id,
            archetypeText: clusterData.dismissal_record.archetype_text,
            reason: clusterData.dismissal_record.reason,
            createdAt: clusterData.dismissal_record.created_at,
          },
    };
  }

  _sortTableData(tableData) {
    return tableData.sort(function (a, b) {
      // sorts by (primary) 'currentCount:desc' and (secondary) 'previousCount:asc' (0 || x => x)
      return b.currentCount - a.currentCount || a.previousCount - b.previousCount;
    });
  }

  _showExamplesIfRequested() {
    let clusterIdToShow = this.router.currentRoute.queryParams['clusterId'];
    if (!clusterIdToShow) {
      return;
    }

    let cluster = this.tableData.find((c) => c.clusterId === clusterIdToShow);
    if (!cluster) {
      return;
    }

    let showTrainingView = this.router.currentRoute.queryParams['showTrainingView'] || false;
    this.toggleClusterSideDrawer({ cluster, showSideDrawer: true, trainingView: showTrainingView });
  }
}
