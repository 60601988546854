/* import __COLOCATED_TEMPLATE__ from './grid.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import { modifier } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { schedule } from '@ember/runloop';

interface GridStackOptions {
  cellHeight: string;
  float: boolean;
  staticGrid: boolean;
  onChange: (event: any, options: any) => void;
  onAdded: (event: any, options: any) => void;
  onResizeStop: (event: any, el: any) => void;
  onDragStop: (event: any, el: any) => void;
  gridId: string;
}

interface Signature {
  Element: HTMLElement;
  Args: GridStackOptions;
  Blocks: any;
}

export default class Grid extends Component<Signature> {
  gridStack: GridStack;

  constructor(owner: unknown, args: GridStackOptions) {
    super(owner, args);

    this.gridStack = GridStack.init(args, `#${args.gridId}`);

    this.gridStack.on('change', (event, items) => {
      schedule('afterRender', () => args.onChange(event, items));
    });

    this.gridStack.on('added', (event, items) => {
      schedule('afterRender', () => args.onAdded(event, items));
    });

    this.gridStack.on('resizestop', (event, el) => {
      args.onResizeStop(event, el);
    });

    this.gridStack.on('dragstop', (event, el) => {
      args.onDragStop(event, el);
    });

    registerDestructor(this, () => {
      try {
        this.gridStack.destroy();
      } catch {
        // do nothing
      }
    });
  }

  setStaticGrid = modifier((_, [__], { staticGrid }: { staticGrid: boolean }) => {
    this.gridStack.setStatic(staticGrid);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::Grid': typeof Grid;
    'common/grid': typeof Grid;
  }
}
