/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Conversation from 'embercom/models/conversation';

export default class SideDrawer extends Component {
  @service store;
  @service appService;
  @tracked conversations = [];
  @tracked expandedConversation;

  constructor() {
    super(...arguments);

    this.loadConversations.perform();
  }

  @action
  toggleConversationCard(conversation) {
    if (this.expandedConversation === conversation) {
      this.expandedConversation = null;
    } else {
      this.expandedConversation = conversation;
    }
  }

  @dropTask
  *loadConversations() {
    let response = yield Conversation.newSearch({
      ids: this.args.conversationIds,
      app_id: this.appService.app.id,
    });
    this.store.pushPayload({
      conversations: response.conversations,
    });

    this.conversations = response.conversations.map(({ id }) =>
      this.store.peekRecord('conversation', id),
    );
  }
}
