/* import __COLOCATED_TEMPLATE__ from './article-import-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

const HELP_CENTER_REGEX = /\/hc/;

interface Args {
  closeModal: () => void;
  hasActiveApiImport: boolean;
  sync?: boolean;
}

export default class ArticleImportModal extends Component<Args> {
  @service store: any;
  @service realTimeEventService: any;
  @service openCenteredWindowService: any;
  @service notificationsService: any;
  @service intl: any;
  @service helpCenterService: any;
  @service appService: any;
  @tracked url = '';
  @tracked openSectionId: string | null = null;
  @tracked selectedHelpCenter;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.realTimeEventService.on('ImportStarted', this, '_handleStartedImport');
    this.selectedHelpCenter = this.helpCenterService.site;
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ImportStarted', this, '_handleStartedImport');
  }

  get app() {
    return this.appService.app;
  }

  // show the dropdown only when needed
  get showHelpCenterDropdown() {
    return this.app.canUseMultipleHelpCenters && this.helpCenters.length > 1;
  }

  get helpCenters() {
    return this.helpCenterService.sites;
  }

  get helpCenterOptions() {
    return this.helpCenters.map((site: HelpCenterSite) => {
      return {
        text: site.name,
        value: site.id,
      };
    });
  }

  get isUrlInvalid() {
    let normalizedUrl = this.normalizeZendeskURL(this.url);

    if (!normalizedUrl) {
      return false;
    }

    try {
      let parsedUrl = new URL(normalizedUrl);
      return !parsedUrl.hostname.match(/.+\.zendesk\.com$/);
    } catch (e) {
      return true;
    }
  }

  _handleStartedImport() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.list.import.start_notification'),
    );
    this.args.closeModal();
  }

  @action switchHelpCenter(value: string) {
    this.selectedHelpCenter = this.helpCenters.find(
      (helpCenter: HelpCenterSite) => helpCenter.id === value,
    );
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  toggleExpand() {
    this.openSectionId = this.openSectionId ? null : 'import_content_detail';
  }

  @action
  async startImport() {
    let url = this.normalizeZendeskURL(this.url);
    if (!url) {
      return;
    }
    let ZdUrl = await this.zendeskOAuthURL(url);
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    this.openCenteredWindowService.setup(ZdUrl, 800, 1280, 'Import');
  }

  normalizeZendeskURL(url: string) {
    url = url?.trim();

    if (isEmpty(url)) {
      return;
    }

    if (url.match(/^http:\/\//)) {
      url = url.substring(7);
    }

    if (url.match(/^https/) === null) {
      url = `https://${url}`;
    }

    // This conditional will strip out the help center centric part of the URL
    if (url.match(HELP_CENTER_REGEX)) {
      let index = url.match(HELP_CENTER_REGEX)?.index;
      url = url.substring(0, index);
    }

    return url;
  }

  async zendeskOAuthURL(url: string) {
    let oauthState = this.store.createRecord('articles/oauth-state', {
      siteUrl: url,
      helpCenterId: this.selectedHelpCenter.id,
    });
    await oauthState.save();

    let stateJson = JSON.stringify({
      oauth_state_id: oauthState.id,
    });

    let queryParams = encodeURI(
      `response_type=code&redirect_uri=${this.selectedHelpCenter.get(
        'zendeskImporterRedirectUri',
      )}&client_id=${this.selectedHelpCenter.get(
        'zendeskImporterClientId',
      )}&scope=hc:read&state=${stateJson}`,
    );

    return `${url}/oauth/authorizations/new?${queryParams}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ArticleImportModal': typeof ArticleImportModal;
    'articles/shared/article-import-modal': typeof ArticleImportModal;
  }
}
