/* import __COLOCATED_TEMPLATE__ from './report-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { inject as service } from '@ember/service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { cached } from 'tracked-toolbox';
import { ChargeModel } from 'embercom/lib/purchase/pricing-metric';
import moment from 'moment-timezone';
import {
  mapUsageReminderType,
  SMS_ALL_REGIONS,
  METRIC_CHART_TYPE_MAP,
  SMS_METRICS,
  METRICS_SUPPORTING_USAGE_ALERTS,
  METRICS_SUPPORTING_USAGE_LIMITS,
} from 'embercom/helpers/billing/usage-helper';
import {
  TARGETLINECLASS,
  TARGETLINEPALETTE,
} from 'embercom/lib/billing/usage/custom-usage-default-line-chart-config';

export default class ReportCard extends Component {
  @service customerService;
  @service appService;
  @service intl;
  @service modalService;
  @service store;

  @tracked selectedMetric = this.chartMetrics[0];
  @tracked modalIsOpen = false;

  get app() {
    return this.appService.app;
  }

  get savedUsageReminder() {
    return this.args.usageReminders.find(
      (element) => element.usageType === mapUsageReminderType(this.selectedMetric),
    );
  }

  get currentMetricUsageLimit() {
    let currentLimitMetric = this.args.usageLimits?.find(
      (element) => element.metric === this.selectedMetric,
    );

    if (currentLimitMetric && currentLimitMetric.tableName) {
      return this.store.peekAll(currentLimitMetric.tableName).firstObject;
    }
    return undefined;
  }

  get usageModalLabelText() {
    if (this.selectedMetric === Metric.resolutions_with_custom_answers) {
      if (
        (this.savedUsageReminder && !this.savedUsageReminder.deleted) ||
        (this.currentMetricUsageLimit && this.currentMetricUsageLimit.enabled)
      ) {
        return 'billing.usage.modal-usage-alerts-and-limits.label_text_edit_alerts_limits';
      }

      return 'billing.usage.modal-usage-alerts-and-limits.label_text_start_alerts_limits';
    } else {
      if (this.savedUsageReminder && !this.savedUsageReminder.deleted) {
        return 'billing.usage.modal-usage-alerts-and-limits.label_text_edit_alerts';
      }
      return 'billing.usage.modal-usage-alerts-and-limits.label_text_start_alerts';
    }
  }

  get shouldShowUsageAlertProductTours() {
    // customers will see a product tour for emails sent regardless of if they have alerts set up
    return this.selectedMetric === Metric.emails_sent;
  }

  get shouldShowUsageLimitProductTours() {
    // customers will see a product tour for resolutions regardless of they have alerts or limits set up or not
    return this.selectedMetric === Metric.resolutions_with_custom_answers;
  }

  get customer() {
    return this.customerService.customer;
  }

  get isAnnualOrSalesforceContracted() {
    return this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer;
  }

  get price() {
    let discount =
      this.isAnnualOrSalesforceContracted && this.contract
        ? this.contractCharge?.discount_item
        : this.charge?.discount_item;

    return this.centsToDollars(
      discount ? this.centPrice - discount.amount_in_cents : this.centPrice,
    );
  }

  get centPrice() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.cumulativePrice;
    }

    if (this.selectedMetric === Metric.messages_sent) {
      return this.calculateAdditionalUsagePrice;
    }

    if (this.isAnnualOrSalesforceContracted && this.contract) {
      return this.contractCharge.price;
    }

    return this.charge.price;
  }

  get calculateAdditionalUsagePrice() {
    let charge = this.isAnnualOrSalesforceContracted ? this.contractCharge : this.charge;
    return charge.calculateAdditionalUsagePrice;
  }

  centsToDollars(cents) {
    return cents / 100;
  }

  get currentUsage() {
    return this.chartData.length > 0 ? this.chartData.at(-1)[1] : 0;
  }

  get perWorkspaceUsage() {
    let workspace = this.contract.perWorkspaceUsage?.find(
      (workspace) => workspace.app_id === this.customerService.app.id,
    );

    return this.getUsage(workspace) ?? 0;
  }

  getUsage(workspace) {
    return workspace.usage?.[this.charge?.pricing_metric] ?? 0;
  }

  get totalUsage() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.cumulativeUsageForAllRegions;
    }

    return this.contract.perWorkspaceUsage.reduce(
      (sum, workspace) => sum + this.getUsage(workspace),
      0,
    );
  }

  get isMultiWorkspace() {
    return this.app.isSalesforceContracted
      ? this.contract.numberOfSecondarySubscriptions > 0 || this.contract.isSecondarySubscription
      : false;
  }

  get shouldShowUsageAlertLabel() {
    return METRICS_SUPPORTING_USAGE_ALERTS.includes(this.selectedMetric);
  }

  get shouldShowLimitPill() {
    return this.selectedMetric === Metric.resolutions_with_custom_answers && this.hasLimitBeenSet;
  }

  get hasLimitBeenSet() {
    return this.currentMetricUsageLimit && this.currentMetricUsageLimit.enabled;
  }

  get hasLimitBeenPassed() {
    return this.hasLimitBeenSet && this.currentMetricUsageLimit.threshold <= this.currentUsage;
  }

  get alertModalTitle() {
    let title = this.intl.t(this.args.title);
    let metricTitle = SMS_METRICS.includes(this.selectedMetric)
      ? this.intl.t(`billing.usage.pricing-metric-selector.${this.selectedMetric}`)
      : '';

    return `${title} ${metricTitle}`;
  }

  @cached
  get charge() {
    return this.charges.find((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get contractCharge() {
    return this.contractCharges.find((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get chargesPerMetric() {
    return this.charges.filter((charge) => charge.pricing_metric === this.selectedMetric);
  }

  @cached
  get contractChargesPerMetric() {
    return this.contractCharges.filter((charge) => charge.pricing_metric === this.selectedMetric);
  }

  get pricingMetric() {
    return new PricingMetric(this.charge, this.intl);
  }

  get isChargeTiered() {
    return this.charge.charge_model === ChargeModel.Tiered;
  }

  get contract() {
    return this.args.contract;
  }

  get pmdasData() {
    return this.args.pmdasData || [];
  }

  get contractedUsage() {
    if (
      this.selectedMetric !== SMS_ALL_REGIONS &&
      !this.contract.contractUsageLimits[this.selectedMetric]
    ) {
      return this.charge.baseUsage;
    }

    return this.contract.contractUsageLimits[this.selectedMetric];
  }

  get chartType() {
    return METRIC_CHART_TYPE_MAP[this.selectedMetric];
  }

  // Default: dotted grey line if there is contracted usage and not a multiworkspace and no limit set
  // If limit is on:
  // grey full line for limit
  // yellow full line if limit has been reached

  get targetLine() {
    let shouldShowContractedLine = this.contractedUsage && !this.isMultiWorkspace;
    let shouldShowLimitLine =
      METRICS_SUPPORTING_USAGE_LIMITS.find((element) => element.metric === this.selectedMetric) &&
      this.hasLimitBeenSet;
    let target = {
      value: this.contractedUsage,
      options: {
        palette: TARGETLINEPALETTE.default,
        lineClass: TARGETLINECLASS.default,
        dashStyle: 'dash',
      },
    };
    let limitTargetOptions = {
      value: this.hasLimitBeenSet ? this.currentMetricUsageLimit.threshold : 0,
      options: {
        palette: TARGETLINEPALETTE.limitOn,
        lineClass: TARGETLINECLASS.limitOn,
        dashStyle: 'solid',
      },
    };

    if (this.hasLimitBeenPassed) {
      limitTargetOptions = {
        value: this.currentMetricUsageLimit.threshold,
        options: {
          palette: TARGETLINEPALETTE.limitReached,
          lineClass: TARGETLINECLASS.limitReached,
          dashStyle: 'solid',
        },
      };
    }

    if (shouldShowContractedLine && shouldShowLimitLine) {
      target.secondaryTarget = limitTargetOptions;
    } else if (shouldShowLimitLine) {
      target = limitTargetOptions;
    }

    return shouldShowContractedLine || shouldShowLimitLine ? target : null;
  }

  get chartData() {
    let chartData = [];
    let billingDates = this.getBillingDates();

    billingDates.forEach((billingDate) => {
      let isCycleStartDate = moment(billingDate).isSame(
        new Date(this.customer.currentBillingCycleStartDate),
        'day',
      );

      if (isCycleStartDate) {
        chartData.push([billingDate.setHours(8), 0]);
      } else if (this.shouldAddChartValue(billingDate)) {
        // IF PMDAS data doesn't exist for the last available day,
        // we shouldn't add it (sometimes PMDAS gets generated later in the day and it would mess up the charts and totals)
        chartData.push([billingDate.setHours(8), this.calculateChartValue(billingDate)]);
      }
    });

    return chartData;
  }
  getBillingDates() {
    let billingDates = [];
    for (
      let dt = new Date(this.customer.currentBillingCycleStartDate);
      dt <= new Date(new Date());
      dt.setDate(dt.getDate() + 1)
    ) {
      billingDates.push(new Date(dt));
    }
    return billingDates;
  }

  calculateChartValue(billingDate) {
    let value = 0;

    if (this.selectedMetric === SMS_ALL_REGIONS) {
      this.metrics.forEach((metric) => {
        value += this.getPmdasValueForGivenDayAndMetric(billingDate, metric);
      });
    } else {
      value = this.getPmdasValueForGivenDayAndMetric(billingDate, this.selectedMetric);
    }
    return value;
  }

  shouldAddChartValue(billingDate) {
    let isBillingDateToday = this.isDateToday(billingDate);
    return (
      (isBillingDateToday && this.getPmdasValueForToday(this.selectedMetric)) || !isBillingDateToday
    );
  }

  isDateToday(date) {
    return moment(date).isSame(new Date(), 'day');
  }

  getPmdasValueForToday(metricName) {
    if (metricName === SMS_ALL_REGIONS) {
      return this.pmdasData.find((element) => {
        return (
          this.isDateToday(element.created_at) &&
          (element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_us] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_au] >= 0 ||
            element[Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk] >= 0)
        );
      });
    }

    return this.pmdasData.find((element) => {
      return this.isDateToday(element.created_at) && element[metricName] >= 0;
    });
  }

  getPmdasValueForGivenDayAndMetric(date, metricName) {
    let foundData = this.pmdasData.find((element) => {
      return moment(element.created_at).isSame(date, 'day') && element[metricName] >= 0;
    });

    if (foundData) {
      return foundData[metricName];
    }
    return 0;
  }

  get metrics() {
    return this.args.pricingMetrics;
  }

  get chartMetrics() {
    if (this.args.type === 'sms') {
      return [SMS_ALL_REGIONS, ...this.metrics];
    }

    return this.metrics;
  }

  get hasMultipleMetrics() {
    return this.metrics.length > 1;
  }

  get dropdownItems() {
    return this.chartMetrics.map((metric) => {
      return {
        text: this.intl.t(`billing.usage.pricing-metric-selector.${metric}`),
        value: metric,
      };
    });
  }

  get showBreakdownTooltip() {
    if (this.selectedMetric === SMS_ALL_REGIONS) {
      return this.isMultiWorkspace;
    }

    if (this.isPhoneMetric || (this.currentUsage === 0 && this.price === 0)) {
      return false;
    }

    return true;
  }

  get isPhoneMetric() {
    return this.selectedMetric === Metric.calling;
  }

  get formatType() {
    return this.isPhoneMetric ? 'USDwithCents' : undefined;
  }

  formatUsage(usage) {
    return this.isPhoneMetric ? this.centsToDollars(usage) : usage;
  }

  get formattedContractedUsage() {
    return this.formatUsage(this.contractedUsage);
  }

  get formattedTotalUsage() {
    return this.formatUsage(this.totalUsage);
  }

  get formattedCurrentUsage() {
    return this.formatUsage(this.currentUsage);
  }

  get planId() {
    let breakdown = this.customerService.currentPrice.breakdown.find((breakdown) => {
      return breakdown.charges.find((charge) => {
        return charge.pricing_metric === this.selectedMetric;
      });
    });

    return breakdown.plan_id;
  }

  getCharges(price) {
    let charges = [];

    price.breakdown.forEach((breakdown) => {
      let planId = breakdown.plan_id;
      breakdown.charges.forEach((charge) => {
        charge.planId = planId.toString();
        charges.push(charge);
      });
    });

    return this.metrics.flatMap((metric) => {
      return charges.filter((charge) => charge.pricing_metric === metric);
    });
  }

  get charges() {
    return this.getCharges(this.customerService.currentPrice);
  }

  get contractCharges() {
    return this.getCharges(this.contract.currentPrice);
  }

  getChargePrice(charges) {
    return charges.reduce((accumulator, charge) => {
      return accumulator + charge.price;
    }, 0);
  }

  get cumulativePrice() {
    if (this.isAnnualOrSalesforceContracted && this.contract) {
      return this.getChargePrice(this.contractCharges);
    }
    return this.getChargePrice(this.charges);
  }

  get cumulativeUsage() {
    return this.charges.reduce((accumulator, charge) => {
      accumulator += this.isMultiWorkspace ? charge.actual_usage : charge.actualUsage;
      return accumulator;
    }, 0);
  }

  get cumulativeUsageForAllRegions() {
    return this.charges.reduce((accumulator, charge) => {
      accumulator += this.args.contract.totalUsage?.[charge.pricing_metric];
      return accumulator;
    }, 0);
  }

  get descriptionTooltipMetric() {
    if (this.args.type === 'sms' && this.selectedMetric === SMS_ALL_REGIONS) {
      return SMS_ALL_REGIONS;
    }

    if (this.args.type === 'whatsapp') {
      return 'whatsapp_inbound_outbound';
    }

    return this.selectedMetric;
  }

  @action
  onMetricChange(value) {
    this.selectedMetric = value;
  }
}
