/* import __COLOCATED_TEMPLATE__ from './saved-content-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import LegacyOverlayBaseComponent from 'embercom/components/legacy-overlay-base-component';
import { gt, not, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { pluralize } from 'ember-inflector';
import safeWindowOpen from 'embercom/lib/safe-window-open';

let SavedContentPopover = LegacyOverlayBaseComponent.extend({
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  router: service(),
  attributeBindings: ['style', 'data-test-saved-content-picker'],
  'data-test-saved-content-picker': true,
  classNames: ['saved-contents__popover'],
  allowAutoClose: not('isModalOpen'),
  isModalOpen: false,
  model: '',
  objectName: 'macro',

  hasSavedContents: gt('savedContents.length', 0),
  isLoading: reads('app.savedReplies.isPending'),
  savedContents: computed('app.savedReplies.[]', function () {
    return this.app.savedReplies.filter(({ types }) => types.includes('opener'));
  }),

  openerElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover-opener__saved-content`;
  }),
  popoverElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .saved-contents__popover.js__popover`;
  }),

  emptyMessage: computed('objectName', function () {
    let emptyObjectName;

    if (this.objectName === 'macro') {
      emptyObjectName = pluralize(this.objectName);
    } else {
      emptyObjectName = this.objectName;
    }

    return `Looks like you have no ${emptyObjectName}`;
  }),

  actions: {
    selectItem(savedContent) {
      this.insertSavedContent(savedContent);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'inserted_from_popover',
        object: 'saved_content',
      });
    },
    createSavedContent() {
      this.set('mode', 'create');
      this.set('isModalOpen', true);
    },
    openMacroSettings() {
      safeWindowOpen(this.router.urlFor('apps.app.settings.saved-replies', this.app), '_blank');
    },
  },
});

export default SavedContentPopover;
