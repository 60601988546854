/* import __COLOCATED_TEMPLATE__ from './cluster-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Admin from 'embercom/models/admin';

export default class ClusterSideDrawer extends Component {
  @service intercomEventService;
  @service router;
  @service store;

  @tracked interval = this.args.startingInterval;
  @tracked showAnswerTrainingView = this.args.showAnswerTrainingView;
  @tracked trainingCluster = this.args.cluster;

  get dismissalRecordAdmin() {
    return Admin.peekAndMaybeLoad(this.store, this.args.cluster.dismissalRecord.adminId);
  }

  @action updateInterval(value) {
    if (this.interval !== value) {
      this.interval = value;

      this.intercomEventService.trackAnalyticsEvent({
        object: 'suggested_answers_drawer_date_interval_tab',
        action: 'clicked',
        place: 'answer_bot',
        section: 'operator',
        date_interval: this.interval.toString(),
      });
    }
  }

  @action createNewAnswer(cluster) {
    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        exampleId: cluster.clusterId,
        exampleSource: 'answers_clusters_side_drawer',
        exampleText: cluster.text,
        expandSelectedExamples: true,
        language: cluster.language,
        transitionBackTo: 'apps.app.automation.resolution-bot.clusters',
      },
    });
  }

  @action trainExistingAnswer(trainingCluster) {
    this.trainingCluster = trainingCluster;
    this.showAnswerTrainingView = true;
  }

  @action trainExistingAnswerFromExample(cluster, example) {
    this.trainExistingAnswer({ ...cluster, ...{ text: example.exampleText } });
  }
}
