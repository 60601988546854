/* import __COLOCATED_TEMPLATE__ from './rejected-suggested-questions-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class RejectedSuggestedQuestionsModal extends Component {
  @service router;
  @service answerFollowOnCreationService;

  @tracked selectedQuestions = {};
  @tracked creatingAnswer = false;
  @tracked isVisible = true;

  get selectedQuestionsLength() {
    return Object.keys(this.selectedQuestions).length;
  }

  get firstSelectedQuestion() {
    return this.selectedQuestions[Object.keys(this.selectedQuestions)[0]];
  }

  @action
  selectQuestion(rejectedQuestion) {
    let id = rejectedQuestion.identifier;
    if (this.selectedQuestions[id]) {
      delete this.selectedQuestions[id];
    } else {
      this.selectedQuestions[id] = rejectedQuestion;
    }
    this.selectedQuestions = this.selectedQuestions;
  }

  @action
  createAnswer() {
    this.answerFollowOnCreationService.setQuestionsSelectedFromRejectedQuestions(
      this.selectedQuestions,
    );
    this.creatingAnswer = true;
    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        previousShowPageAnswerId: this.args.currentPageAnswerId,
        prepopulatedSearchTerm: this.firstSelectedQuestion?.text,
        expandSelectedExamples: true,
      },
    });
  }

  @action
  cancel() {
    this.answerFollowOnCreationService.reset();
    this.isVisible = false;
  }
}
