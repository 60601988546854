/* import __COLOCATED_TEMPLATE__ from './body-content.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import type ApplicationInstance from '@ember/application/instance';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import type IntlService from 'embercom/services/intl';
import { REGULATORY_BUNDLE_COUNTRY_CODES } from 'embercom/models/settings/calling';
import type Calling from 'embercom/models/settings/calling';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';

type CallingBodyContentResponse = {
  success: boolean;
  errorMessage: string;
};

interface Args {
  settings: Calling;
  isCallingSupported: boolean;
}

export default class CallingBodyContent extends Component<Args> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare cardlessTrialService: CardlessTrialService;

  @tracked canUseCalling = false;
  @tracked hasAcceptedTerms = false;
  @tracked openSectionId = 'phone_calls';
  @tracked showProvisioningModal = false;
  @tracked phoneNumber?: string;
  @tracked phoneNumbers: PhoneNumber[] = [];
  @tracked countryIcon?: string;
  @tracked recordingWarningMessage: string;
  @tracked recordingEnabled: boolean;
  @tracked inboundCallEnabled = false;
  @tracked outboundCallEnabled = false;
  @tracked routingMethod: string;
  @tracked welcomeMessage: string;
  @tracked isTeamEnabled = false;
  @tracked listOfAssignableTeams: any[];
  @tracked selectedTeam: any;
  @tracked voicemailDeflectionEnabled = false;
  @tracked voicemailDeflectionGreeting = '';
  @tracked voicemailOooTriggerEnabled = false;
  @tracked voicemailMaxQueueSizeTriggerEnabled = false;
  @tracked voicemailMaxQueueSize = 0;
  @tracked voicemailNoTeammatesAvailableTriggerEnabled = false;
  @tracked wrapUpTimeUnit = 'seconds';
  @tracked wrapUpTimeEnabled = false;
  @tracked wrapUpTimeAmount: number;
  @tracked deleteRecordingAndTranscriptionEnabled = false;
  @tracked retentionPeriod = 0;
  @tracked matchUserByPhoneNumber: boolean;
  @tracked provisionedPhoneNumber: PhoneNumber | null = null;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    if (args.settings.phoneNumbers[0]) {
      this.phoneNumber = parsePhoneNumberFromString(
        args.settings.phoneNumbers[0].phone_number,
      )?.formatInternational();
    }
    this.countryIcon = REGULATORY_BUNDLE_COUNTRY_CODES.get(
      args.settings.phoneNumbers[0]?.country_iso_code,
    )?.flag;
    this.recordingWarningMessage = args.settings.recordingWarningMessage;
    this.recordingEnabled = args.settings.recordingEnabled;
    this.canUseCalling = this.appService.app.canUseCalling;
    this.hasAcceptedTerms = this.canUseCalling;
    this.inboundCallEnabled = args.settings.inboundEnabled;
    this.outboundCallEnabled = args.settings.outboundEnabled;
    this.welcomeMessage = args.settings.welcomeMessage;
    this.listOfAssignableTeams = this.noTeamsForApp ? [] : this.mapTeamsToDropdownItems;
    this.selectedTeam = this.noTeamsForApp
      ? '0'
      : args.settings.assignTeamId || this.listOfAssignableTeams[0]?.value;
    this.routingMethod = args.settings.routingMethod || 'team';
    this.voicemailDeflectionEnabled = args.settings.voicemailDeflectionEnabled;
    this.voicemailDeflectionGreeting = args.settings.voicemailDeflectionGreeting;
    this.voicemailOooTriggerEnabled = args.settings.voicemailOooTriggerEnabled;
    this.voicemailMaxQueueSizeTriggerEnabled = args.settings.voicemailMaxQueueSizeTriggerEnabled;
    this.voicemailMaxQueueSize = args.settings.voicemailMaxQueueSize;
    this.voicemailNoTeammatesAvailableTriggerEnabled =
      args.settings.voicemailNoTeammatesAvailableTriggerEnabled;
    this.wrapUpTimeUnit = args.settings.wrapUpTimeUnit;
    this.wrapUpTimeEnabled = args.settings.wrapUpTimeEnabled;
    this.wrapUpTimeAmount =
      args.settings.wrapUpTimeUnit === 'seconds'
        ? args.settings.wrapUpTimeAmount
        : Math.floor(args.settings.wrapUpTimeAmount / 60);
    this.deleteRecordingAndTranscriptionEnabled =
      args.settings.deleteRecordingAndTranscriptionEnabled || false;
    this.retentionPeriod = args.settings.recordingAndTranscriptionRetentionPeriodInDays;
    this.matchUserByPhoneNumber = this.appService.app.canMatchUserByPhoneNumber;
    if (!this.settings.callingAllowed) {
      this.openSectionId = '';
    }
  }

  get settings() {
    return this.args.settings;
  }

  get phoneCallsEnabled() {
    return this.settings.phoneNumbers.length > 0;
  }

  get noTeamsForApp() {
    return this.appService.app.assignableTeams.length === 0;
  }

  get isSaveButtonDisabled() {
    return (
      this.noTeamsForApp && (this.routingMethod === 'team' || !this.hasWorkflowsBillingFeature)
    );
  }

  get isUpdateRecordingButtonDisabled() {
    return !this.isRecordingWarningMessageValid || !this.isRententionPeriodValid;
  }

  get isRecordingWarningMessageValid() {
    if (this.recordingEnabled) {
      return this.recordingWarningMessage && this.recordingWarningMessage.length > 0;
    }
    return true;
  }

  get isRententionPeriodValid() {
    if (this.deleteRecordingAndTranscriptionEnabled) {
      return this.retentionPeriod > 0 && this.retentionPeriod < 999;
    }
    return true;
  }

  get mapTeamsToDropdownItems() {
    let teams = this.appService.app.assignableTeams;
    let options = teams.map((team: any) => {
      return {
        text: team.get('name'),
        value: team.get('id'),
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: team,
          modelDisplayName: team.get('name'),
          avatarSize: 'xs',
        },
      };
    });

    return options;
  }

  get recordingAndTranscriptsString() {
    return this.intl.t('channels.video-call.settings.recordings.recordings-and-transcripts');
  }

  get messengerCallingString() {
    return this.intl.t('channels.video-call.settings.messenger-call-settings.heading');
  }

  get hasWorkflowsBillingFeature() {
    return this.appService.app.canUseFeature('workflows_core_billing_feature');
  }

  get supportedWrapUpTimeUnits() {
    return [
      {
        text: this.intl.t('channels.video-call.settings.phone-call-settings.inbound.seconds'),
        value: 'seconds',
      },
      {
        text: this.intl.t('channels.video-call.settings.phone-call-settings.inbound.minutes'),
        value: 'minutes',
      },
    ];
  }

  get wrapUpAmountSeconds() {
    return this.wrapUpTimeUnit === 'seconds' ? this.wrapUpTimeAmount : this.wrapUpTimeAmount * 60;
  }

  get invalidwrapUpTimeAmount() {
    if (this.wrapUpTimeUnit === 'seconds') {
      return this.wrapUpTimeAmount <= 0 || this.wrapUpTimeAmount > 900;
    }
    return this.wrapUpTimeAmount <= 0 || this.wrapUpTimeAmount > 15;
  }

  get wrapUpTimeAmountMax() {
    return this.wrapUpTimeUnit === 'seconds' ? 900 : 15;
  }

  get wrapUpTimeAmountValidationMessage() {
    return this.intl.t(
      'channels.video-call.settings.phone-call-settings.inbound.wrap-up-time-invalid',
      { max: this.wrapUpTimeAmountMax, unit: this.wrapUpTimeUnit },
    );
  }

  get hasPhoneDemoFeatureFlag() {
    return this.appService.app.canUseFeature('ae-phone-demo');
  }

  get currentAdminId() {
    return this.appService.app.currentAdmin.id;
  }

  @action toggleTermsCheckbox() {
    this.hasAcceptedTerms = !this.hasAcceptedTerms;
  }

  @action async enableCalling() {
    try {
      let response = await taskFor(this.updateCallingBodyContent).perform();
      this._recordEvent('accept_messenger_calling_terms');
      if (response.success) {
        this.canUseCalling = true;
        this.notificationsService.notifyConfirmation(
          this.intl.t('channels.video-call.settings.calling-enabled'),
        );
      } else {
        throw response.errorMessage;
      }
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.calling-enablement-error'),
      );
      throw err;
    }
  }

  @action async saveInboundSettings() {
    let settingsModel = this.args.settings;
    settingsModel.welcomeMessage = this.welcomeMessage;
    settingsModel.assignTeamId = this.selectedTeam === '0' ? null : this.selectedTeam;
    settingsModel.routingMethod = this.routingMethod;
    settingsModel.voicemailDeflectionEnabled = this.voicemailDeflectionEnabled;
    settingsModel.wrapUpTimeEnabled = this.wrapUpTimeEnabled;
    if (this.voicemailDeflectionEnabled) {
      settingsModel.voicemailDeflectionGreeting = this.voicemailDeflectionGreeting;
      settingsModel.voicemailOooTriggerEnabled = this.voicemailOooTriggerEnabled;
      settingsModel.voicemailMaxQueueSizeTriggerEnabled = this.voicemailMaxQueueSizeTriggerEnabled;
      settingsModel.voicemailMaxQueueSize = this.voicemailMaxQueueSize;
      settingsModel.voicemailNoTeammatesAvailableTriggerEnabled =
        this.voicemailNoTeammatesAvailableTriggerEnabled;
    } else {
      settingsModel.voicemailDeflectionGreeting = '';
      settingsModel.voicemailOooTriggerEnabled = false;
      settingsModel.voicemailMaxQueueSizeTriggerEnabled = false;
      settingsModel.voicemailMaxQueueSize = 0;
      settingsModel.voicemailNoTeammatesAvailableTriggerEnabled = false;
    }
    if (this.wrapUpTimeEnabled) {
      if (this.wrapUpAmountSeconds > 900) {
        this.notificationsService.notifyError(
          this.intl.t(
            'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
          ),
        );
        return;
      }
      settingsModel.wrapUpTimeUnit = this.wrapUpTimeUnit;
      settingsModel.wrapUpTimeAmount = this.wrapUpAmountSeconds;
    }
    settingsModel.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t('channels.video-call.settings.phone-call-settings.inbound.updated-notification'),
    );
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action activateProvisioningModal() {
    this.showProvisioningModal = true;
  }

  @action closeProvisioningModal() {
    this.showProvisioningModal = false;
  }

  @action onPhoneNumberProvisioned(phoneNumber: PhoneNumber) {
    this.provisionedPhoneNumber = phoneNumber;
  }

  @action
  closePhoneNumberEditModal() {
    if (this.provisionedPhoneNumber) {
      this.provisionedPhoneNumber.rollbackAttributes();
      this.provisionedPhoneNumber = null;
    }
  }

  @action setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  @action setCountryIcon(countryIcon: string) {
    this.countryIcon = countryIcon;
  }

  @action enableOutboundCalling() {
    this.outboundCallEnabled = true;
  }

  @action onSelectTimeUnit(unit: string) {
    this.wrapUpTimeUnit = unit;
  }

  @action toggleWrapUpTime(unit: any) {
    this.wrapUpTimeEnabled = unit.target.checked;
  }

  @action async toggleMessengerCalling() {
    try {
      this.settings.toggleProperty('messengerCallingEnabled');
      let response = await taskFor(this.updateMessengerCalling).perform();
      if (!response.success) {
        this.settings.toggleProperty('messengerCallingEnabled');
        throw response.errorMessage;
      }
      this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.video-call.settings.recordings.updated-save-notification'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async savePhoneCallSettings() {
    try {
      let response = await taskFor(this.updateMatchUserByPhoneNumber).perform();
      if (!response.success) {
        this.notificationsService.notifyError(
          this.intl.t(
            'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
          ),
        );
        return;
      }
      this.settings.wrapUpTimeEnabled = this.wrapUpTimeEnabled;
      if (this.wrapUpTimeEnabled) {
        if (this.wrapUpAmountSeconds <= 0 || this.wrapUpAmountSeconds > 900) {
          this.notificationsService.notifyError(
            this.intl.t(
              'channels.video-call.settings.phone-call-settings.inbound.failed-notification',
            ),
          );
          return;
        }
        this.settings.wrapUpTimeUnit = this.wrapUpTimeUnit;
        this.settings.wrapUpTimeAmount = this.wrapUpAmountSeconds;
      }
      this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'channels.video-call.settings.phone-call-settings.inbound.updated-notification',
        ),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async toggleRecordingFeature() {
    this.recordingEnabled = !this.recordingEnabled;

    if (!this.recordingEnabled) {
      await this.updateRecording();
    }
  }

  @action async updateRecording() {
    let settingsModel = this.args.settings;
    settingsModel.recordingWarningMessage = this.recordingWarningMessage;
    settingsModel.recordingEnabled = this.recordingEnabled;
    settingsModel.deleteRecordingAndTranscriptionEnabled =
      this.deleteRecordingAndTranscriptionEnabled;
    settingsModel.recordingAndTranscriptionRetentionPeriodInDays = this.retentionPeriod;
    settingsModel.save();

    let analyticsEventAction = 'disabled';
    if (this.recordingEnabled) {
      analyticsEventAction = 'enabled';
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: analyticsEventAction,
      object: 'transcriptions_and_recordings',
      place: 'calling',
      section: 'settings',
    });

    this.notificationsService.notifyConfirmation(
      this.intl.t('channels.video-call.settings.recordings.updated-save-notification'),
    );
  }

  @dropTask *updateCallingBodyContent(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings`, { app_id: this.appService.app.id });
  }

  @action async toggleInboundCalls() {
    try {
      this.inboundCallEnabled = !this.inboundCallEnabled;
      let response = await taskFor(this.updateInboundCalls).perform();
      if (!response.success) {
        this.inboundCallEnabled = !this.inboundCallEnabled;
      }
    } catch (err) {
      this.inboundCallEnabled = !this.inboundCallEnabled;
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.inbound.error'),
      );
      throw err;
    }
  }

  @action async toggleOutboundCalls() {
    try {
      this.outboundCallEnabled = !this.outboundCallEnabled;
      let response = await taskFor(this.updateOutboundCalls).perform();
      if (!response.success) {
        this.outboundCallEnabled = !this.outboundCallEnabled;
      }
    } catch (err) {
      this.outboundCallEnabled = !this.outboundCallEnabled;
      this.notificationsService.notifyError(
        this.intl.t('channels.video-call.settings.phone-call-settings.outbound.error'),
      );
      throw err;
    }
  }

  @action
  showGetStartedWithCallingArticle() {
    window.Intercom('showArticle', 8488909); // https://www.intercom.com/help/en/articles/8488909-how-to-set-up-intercom-phone
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_with_calling_help_link',
      context: 'discovery-banner',
      place: 'calling.banner',
      section: 'discovery-banner',
    });
  }

  @dropTask
  *fetchPhoneNumbersTask(): TaskGenerator<Array<PhoneNumber>> {
    this.phoneNumbers = yield this.store.query('calling-phone-number', {});
    return this.phoneNumbers;
  }

  @dropTask
  *peekPhoneNumbersTask(): TaskGenerator<Array<PhoneNumber>> {
    this.phoneNumbers = yield this.store.peekAll('calling-phone-number');
    return this.phoneNumbers;
  }

  @dropTask *updateInboundCalls(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_inbound_calling`, {
      app_id: this.appService.app.id,
      inbound_enabled: this.inboundCallEnabled,
    });
  }

  @dropTask *updateOutboundCalls(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_outbound_calling`, {
      app_id: this.appService.app.id,
      outbound_enabled: this.outboundCallEnabled,
    });
  }

  @dropTask *updateMessengerCalling(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_messenger_calling`, {
      app_id: this.appService.app.id,
      messenger_calling_enabled: this.settings.messengerCallingEnabled,
    });
  }

  @dropTask *updateMatchUserByPhoneNumber(): TaskGenerator<CallingBodyContentResponse> {
    return yield post(`/ember/calling_settings/update_match_user_by_phone_number`, {
      app_id: this.appService.app.id,
      match_user_by_phone_number: this.matchUserByPhoneNumber,
    });
  }

  _recordEvent(name: string) {
    this.intercomEventService.trackEvent(name, {
      app_id_code: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::BodyContent': typeof CallingBodyContent;
    'calling/settings/body-content': typeof CallingBodyContent;
  }
}
