/* import __COLOCATED_TEMPLATE__ from './seat-assignment-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import {
  PLAN_DATA,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
} from 'embercom/lib/billing';
import {
  PRICING_5_X_FULL_SEAT_TYPE as CORE,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import { helpLinkUrlsForStripeMigrations } from 'embercom/helpers/billing/migrations-helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {
  migrationSeatConfiguration: MigrationSeatConfiguration;
  closeSeatAssignmentModal: () => {};
  selectedPlanId: string;
  onSelectPlan: (dropdownItemValue: string) => void;
  onMigrationSeatConfigurationSave: () => Promise<void>;
  copilotSeatPrice: number;
}

interface Signature {
  Args: Args;
}

export default class SeatAssignmentModal extends Component<Signature> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  get totalSeatCost() {
    return this.totalFullSeatsCost + this.totalCopilotSeatsCost;
  }

  get fullSeatCount() {
    if (this.isEssentialPlanSelected) {
      return this.args.migrationSeatConfiguration.adminSeatMappings.length;
    }
    return this.args.migrationSeatConfiguration.adminSeatMappings.reduce(
      (count, mapping) => count + (mapping.seatTypes.includes(CORE) ? 1 : 0),
      0,
    );
  }

  get liteSeatCount() {
    return this.isEssentialPlanSelected
      ? 0
      : this.args.migrationSeatConfiguration.adminSeatMappings.reduce(
          (count, mapping) => count + (mapping.seatTypes.includes(LITE) ? 1 : 0),
          0,
        );
  }

  get totalFullSeatsCost() {
    return (this.fullSeatCount as number) * this.fullSeatPrice;
  }

  get copilotSeatCount() {
    return this.args.migrationSeatConfiguration.adminSeatMappings.reduce(
      (count, mapping) => count + (mapping.seatTypes.includes(COPILOT) ? 1 : 0),
      0,
    );
  }

  get totalCopilotSeatsCost() {
    return this.copilotSeatCount * this.args.copilotSeatPrice;
  }

  get fullSeatPrice() {
    return PLAN_DATA[this.args.selectedPlanId].pricePerSeat;
  }

  get isEssentialPlanSelected() {
    return this.args.selectedPlanId === PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get dropdownLabel() {
    return PLAN_DATA[this.args.selectedPlanId].name;
  }

  get dropdownList() {
    return [
      {
        items: [
          {
            label: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].name,
            text: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].name,
            value: PRICING_5_X_CORE_ESSENTIAL_ID,
          },
          {
            label: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].name,
            text: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].name,
            value: PRICING_5_X_CORE_ADVANCED_ID,
          },
          {
            label: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].name,
            text: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].name,
            value: PRICING_5_X_CORE_EXPERT_ID,
          },
        ],
      },
    ];
  }

  get learnMoreHCLink() {
    return helpLinkUrlsForStripeMigrations.seats;
  }

  get noFullSeatsSelected() {
    return this.fullSeatCount === 0;
  }

  @action
  async closeModal() {
    if (this.args.migrationSeatConfiguration.get('hasDirtyAttributes')) {
      if (
        await this.intercomConfirmService.confirm({
          title: this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.close-without-saving-modal.title',
          ),
          primaryButtonType: 'primary-destructive',
          confirmButtonText: this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.close-without-saving-modal.close-without-saving',
          ),
          cancelButtonText: this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.close-without-saving-modal.keep-editing',
          ),
          body: this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.close-without-saving-modal.message-body',
          ),
        })
      ) {
        this.args.migrationSeatConfiguration.rollbackAttributes();
        this.args.closeSeatAssignmentModal();
      }
    } else {
      this.args.closeSeatAssignmentModal();
    }
  }

  @action
  async saveSeatAssignmentConfig() {
    try {
      await this.args.migrationSeatConfiguration.save();
      await this.args.onMigrationSeatConfigurationSave();

      // pricing_migration_todo: Update notification messages once they're final
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.new_seat_types.successfully_updated',
        ),
      );
    } catch (_error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.new_seat_types.update_failed',
        ),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignmentModal': typeof SeatAssignmentModal;
  }
}
