/* import __COLOCATED_TEMPLATE__ from './choice.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  PLAN_DATA,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_PLANS,
  PRICING_5_X_ADDON_PLAN_IDS,
} from 'embercom/lib/billing';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'embercom/services/intl';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Price from 'embercom/models/price';
import type Charge from 'embercom/models/billing/price/charge';
import type Admin from 'embercom/models/admin';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type RouterService from '@ember/routing/router-service';
import { sortBy } from 'underscore';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import type {
  CopilotPlanToPriceMap,
  CopilotSeatInfo,
} from 'embercom/components/billing/stripe-migration/types';

interface Args {
  migration: StripeMigration;
  shouldShowSeatPrices: boolean;
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  currentPrices: Price[];
  onProactiveMigrationSave: () => void;
  migrationSeatConfiguration: MigrationSeatConfiguration;
  onMigrationSeatConfigurationSave: () => Promise<void>;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationChoiceComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare customerService: any;
  @service declare notificationsService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked planSelection = '';
  @tracked addonPlanIds: string[] = [];
  @tracked consentFinTermsSelected = false;
  @tracked consentMovePricingSelected = false;
  @tracked showSeatMappingModal = false;
  @tracked selectedPlanIdForSeatMapping = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    let migration = this.args.migration;
    this.planSelection =
      this.getDefaultCorePlanSelection(
        this.storedPlanSelection || migration.postMigrationPlanIds,
      ) || '';
    this.addonPlanIds =
      this.getAddonPlanIds(this.storedPlanSelection || migration.postMigrationPlanIds) || [];
    this.selectedPlanIdForSeatMapping = this.planSelection || PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get storedPlanSelection(): number[] | undefined {
    return JSON.parse(window.sessionStorage.getItem('MIGRATION_CHOICE_PLANSELECTION')!);
  }

  get confirmationStepTitle(): string {
    if (this.args.migration.isProactive) {
      return this.intl.t('billing.migrations.step_3_title_proactive');
    }

    return this.intl.t('billing.migrations.step_3_title_reactive');
  }

  get corePlanChoices() {
    let essentialCopilotSeats = this.copilotSeatsForPlanId(PRICING_5_X_CORE_ESSENTIAL_ID);
    let advancedCopilotSeats = this.copilotSeatsForPlanId(PRICING_5_X_CORE_ADVANCED_ID);
    let expertCopilotSeats = this.copilotSeatsForPlanId(PRICING_5_X_CORE_EXPERT_ID);
    return [
      {
        id: 'essential', // used for data-test attributes
        planId: PRICING_5_X_CORE_ESSENTIAL_ID,
        seats: this.essentialSeats,
        totalSeatPrice: this.getSeatPrices(
          this.essentialSeats.core_seat_count,
          PRICING_5_X_CORE_ESSENTIAL_ID,
          essentialCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].liteSeatLimit,
        ),
        copilotSeatCount: essentialCopilotSeats.seatCount,
        copilotSeatPrice: essentialCopilotSeats.pricePerSeat,
      },
      {
        id: 'advanced', // used for data-test attributes
        planId: PRICING_5_X_CORE_ADVANCED_ID,
        seats: this.advancedSeats,
        totalSeatPrice: this.getSeatPrices(
          this.advancedSeats.core_seat_count,
          PRICING_5_X_CORE_ADVANCED_ID,
          advancedCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].liteSeatLimit,
        ),
        copilotSeatCount: advancedCopilotSeats.seatCount,
        copilotSeatPrice: advancedCopilotSeats.pricePerSeat,
      },
      {
        id: 'expert',
        planId: PRICING_5_X_CORE_EXPERT_ID,
        seats: this.expertSeats,
        totalSeatPrice: this.getSeatPrices(
          this.expertSeats.core_seat_count,
          PRICING_5_X_CORE_EXPERT_ID,
          expertCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].liteSeatLimit,
        ),
        copilotSeatCount: expertCopilotSeats.seatCount,
        copilotSeatPrice: expertCopilotSeats.pricePerSeat,
      },
    ];
  }

  getSeatPrices(coreSeatCount: number, planId: string, copilotSeatInfo: CopilotSeatInfo): number {
    return (
      coreSeatCount * PLAN_DATA[planId].pricePerSeat +
      copilotSeatInfo.seatCount * copilotSeatInfo.pricePerSeat
    );
  }

  includedLiteSeatLimitInfo(planNameTranslationKey: string, liteSeatLimit: number) {
    if (liteSeatLimit > 0) {
      if (this.liteSeatUsage <= liteSeatLimit) {
        return this.intl.t('billing.migrations.edit_plan.included_lite_seat_info', {
          liteSeatLimit,
        });
      }

      return '';
    } else {
      return this.intl.t('billing.migrations.edit_plan.lite_seat_not_included_info', {
        planName: this.intl.t(planNameTranslationKey),
      });
    }
  }

  getDefaultCorePlanSelection(planIds: number[]) {
    let corePlan = planIds.find((planId) => PRICING_5_X_CORE_PLANS.includes(String(planId)));

    return corePlan ? String(corePlan) : '';
  }

  getAddonPlanIds(planIds: number[]) {
    let addonPlanIds = planIds
      .filter((planId) => PRICING_5_X_ADDON_PLAN_IDS.includes(String(planId)))
      .map((planId) => String(planId));

    // If there is copilot usage, add it here too (currently customers can't choose/remove it yet)
    let selectedCorePlan = this.getDefaultCorePlanSelection(planIds);
    let corePlanChoice = this.corePlanChoices.find(
      (corePlan) => corePlan.planId === selectedCorePlan,
    );

    if (
      corePlanChoice &&
      corePlanChoice.copilotSeatCount > 0 &&
      !addonPlanIds.includes(FIN_AI_COPILOT_BASE_ID)
    ) {
      addonPlanIds.push(FIN_AI_COPILOT_BASE_ID);
    }

    return addonPlanIds;
  }

  get recommendedPlanIdsAsString() {
    return this.args.migration.recommendedPlanIds?.map((planId) => String(planId)) ?? [];
  }

  get proactiveSupportPlusPlanData() {
    return PLAN_DATA[PROACTIVE_SUPPORT_PLUS_BASE_ID];
  }

  get finAiCopilotPlanData() {
    return PLAN_DATA[FIN_AI_COPILOT_BASE_ID];
  }

  get submitButtonDisabled() {
    if (this.args.migration.isProactive) {
      return false;
    }

    return !this.planSelection || !this.consentFinTermsSelected || !this.consentMovePricingSelected;
  }

  get essentialSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.essentialEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get advancedSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.advancedEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get expertSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.expertEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get copilotSeatPrice() {
    return this.getCopilotSeatInfo(
      this.copilotPlanToPriceMapping[this.selectedPlanIdForSeatMapping],
    ).pricePerSeat;
  }

  copilotSeatsForPlanId(planId: string) {
    return this.getCopilotSeatInfo(this.copilotPlanToPriceMapping[planId]);
  }

  get copilotPlanToPriceMapping(): CopilotPlanToPriceMap {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: this.args.pricing5Estimates.essentialWithCopilotEstimate,
      [PRICING_5_X_CORE_ADVANCED_ID]: this.args.pricing5Estimates.advancedWithCopilotEstimate,
      [PRICING_5_X_CORE_EXPERT_ID]: this.args.pricing5Estimates.expertWithCopilotEstimate,
    };
  }

  get proactiveSupportPlusPrice() {
    let priceInCents: number = this.args.pricing5Estimates.essentialWithPSPEstimate.breakdown.find(
      (breakdown: Breakdown) => breakdown.plan_id === Number(PROACTIVE_SUPPORT_PLUS_BASE_ID),
    ).base_plan_price_pre_discount;

    return priceInCents / 100;
  }

  get proactiveSupportPlusIncludedUsage() {
    let charge = this.args.pricing5Estimates.essentialWithPSPEstimate.breakdown
      .find((breakdown: Breakdown) => breakdown.plan_id === Number(PROACTIVE_SUPPORT_PLUS_BASE_ID))
      .charges.find((charge: Charge) => charge.pricing_metric === Metric.messages_sent);

    return charge.base_usage;
  }

  get addonData() {
    return [
      {
        planId: PROACTIVE_SUPPORT_PLUS_BASE_ID,
        price: this.proactiveSupportPlusPrice,
        planData: this.proactiveSupportPlusPlanData,
        includedUsage: this.proactiveSupportPlusIncludedUsage,
        hcLink: this.proactiveSupportPlusHCLink,
        isDisabled: false,
        pricingMetric: this.intl.t('billing.summary.breakdown.group-title.messages_sent'),
      },
      {
        planId: FIN_AI_COPILOT_BASE_ID,
        planData: this.finAiCopilotPlanData,
        isDisabled: true,
        hcLink: this.finAiCopilotHCLink,
        addonInfo: this.intl.t('billing.migrations.edit_plan.manage_copilot_seats', {
          teammateSettingUrl: this.router.urlFor('apps.app.settings.teammates'),
          htmlSafe: true,
        }),
      },
    ];
  }

  get copilotTooltip() {
    return this.intl.t('billing.migrations.copilot_tooltip', {
      teammateSettingUrl: this.router.urlFor('apps.app.settings.teammates'),
      htmlSafe: true,
    });
  }

  @action
  toggleAddonToMigration(planId: string, alreadySelected: boolean) {
    if (alreadySelected) {
      this.addonPlanIds = this.addonPlanIds.filter((plan) => plan !== planId);
    } else {
      this.addonPlanIds = [...this.addonPlanIds, planId];
    }
  }

  @action
  onManageSeatsClicked() {
    return this.router.transitionTo('apps.app.settings.teammates');
  }

  getSeatCounts(price: Price, admins: Admin[]) {
    let charge = price.breakdown.firstObject.charges.find(
      (charge: Charge) => charge.pricing_metric === Metric.core_seat_count,
    );

    return {
      core_seat_count: charge.actual_usage,
      lite_seat_count: admins.length - charge.actual_usage,
    };
  }

  private getCopilotSeatInfo(price: Price): CopilotSeatInfo {
    let charge = price.breakdown
      .find((bd: Breakdown) => bd.plan_id!.toString() === FIN_AI_COPILOT_BASE_ID)
      .charges.find((charge: Charge) => charge.pricing_metric === Metric.copilot_seat_count);

    return { seatCount: charge.actual_usage, pricePerSeat: charge.per_unit_price / 100 };
  }

  get liteSeatUsage() {
    return this.advancedSeats.lite_seat_count;
  }

  get hasLiteSeatUsage() {
    return this.liteSeatUsage > 0;
  }

  get intercomPlansExplainedHCLink() {
    return 'https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained';
  }

  get proactiveSupportPlusHCLink() {
    return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
  }

  get finAiCopilotHCLink() {
    return 'https://www.intercom.com/help/en/articles/9121384-fin-ai-copilot-pricing';
  }

  @action
  onSelectPlanForSeatMapping(dropdownItemValue: string) {
    this.selectedPlanIdForSeatMapping = dropdownItemValue;
  }

  @action
  async saveMigration() {
    let planIds = [parseInt(this.planSelection, 10)];
    let migration = this.args.migration;

    this.addonPlanIds.forEach((planId) => {
      planIds.push(parseInt(planId, 10));
    });

    migration.planIds = sortBy(planIds);

    try {
      await migration.save();

      if (migration.isProactive) {
        this.args.onProactiveMigrationSave();
      } else {
        let formattedDate = this.intl.formatDate(migration.migrationDate, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('billing.migrations.submit_success_toast', { migrationDate: formattedDate }),
        );
      }
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('billing.migrations.submit_error_toast'));
      throw e;
    }
  }

  get allPlanIdsAsString(): string[] {
    let selectedPlans = [this.planSelection, ...this.addonPlanIds];
    window.sessionStorage.setItem('MIGRATION_CHOICE_PLANSELECTION', JSON.stringify(selectedPlans));
    return selectedPlans;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::Choice': typeof BillingMigrationChoiceComponent;
  }
}
