/* import __COLOCATED_TEMPLATE__ from './content-suggestions-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ContentSuggestionsToggle extends Component {
  @service appService;
  @service attributeService;

  @tracked userSelection = this.allowContentSuggestionsForAllUsers ? 'all' : 'match';
  @tracked visitorSelection = this.allowContentSuggestionsForAllVisitors ? 'all' : 'match';

  get allowContentSuggestionsForAllUsers() {
    return (
      this.args.aiAgentSettings.contentSuggestionsForUsersPredicateGroup.predicates.length === 0
    );
  }

  get allowContentSuggestionsForAllVisitors() {
    return (
      this.args.aiAgentSettings.contentSuggestionsForVisitorsPredicateGroup.predicates.length === 0
    );
  }

  get app() {
    return this.appService.app;
  }

  get attributeGroupListForUsers() {
    return this.attributeService.attributeGroupListForUsers;
  }

  get attributeGroupListForVisitors() {
    return this.attributeService.attributeGroupListForAnonymous;
  }
}
