/* import __COLOCATED_TEMPLATE__ from './questions-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import QuestionTrainingState from 'embercom/models/custom-answers/ui/question-training-state';

export default class QuestionsEditor extends Component {
  @service intercomEventService;
  @service answerFollowOnCreationService;
  @service appService;
  @service conversationAccessService;
  @service intl;

  @tracked questionTrainingState;
  @tracked previousTrackedTooltip = null;
  @tracked showTriggerWordsModal = false;
  @tracked addManualExampleModalIsVisible = false;
  @tracked suggestionId = this.args.suggestionId;

  @tracked manualExample = '';
  @tracked hasSearched = false;

  constructor() {
    super(...arguments);
    this._initQuestionTrainingState();
    this._trackTooltipDisplayed();
  }

  _initQuestionTrainingState() {
    this.questionTrainingState = QuestionTrainingState.create({
      answer: this.args.answer,
      isNewFromSuggestion: !isBlank(this.suggestionId),
      isNewFromCluster: !isBlank(this.args.clusterId),
    });
  }

  get shouldShowSuggestionClusters() {
    return (
      isEmpty(this.args.answer.exampleQuestions) && !isEmpty(this.args.suggestedAnswerClusters)
    );
  }

  get shouldShowProgress() {
    return this.hasSearched || this.args.answer.exampleQuestions.length;
  }

  get searchBoxTooltipContent() {
    if (this.questionTrainingState.tooltipTryNewSearch) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.try-different-phrasing',
      );
    }
    if (this.questionTrainingState.initiatedFromClusterBeta) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.great-start',
      );
    }
  }

  get searchResultsTooltipContent() {
    if (this.questionTrainingState.tooltipFirstSearchAddMore) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.select-more',
      );
    }

    if (this.questionTrainingState.tooltipFirstSearch) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.help-resolution-bot-to-recognize',
      );
    }

    if (this.questionTrainingState.tooltipSubsequentSearch) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.more-examples',
      );
    }
  }

  get addedExamplesTooltipContent() {
    if (this.questionTrainingState.tooltipCompleted) {
      return this.intl.t(
        'operator.resolution-bot-answers.editor.choose-your-questions-panel.questions-editor.compose-your-answer',
      );
    }
  }

  get currentlyShownTooltipContent() {
    return (
      this.addedExamplesTooltipContent ||
      this.searchBoxTooltipContent ||
      this.searchResultsTooltipContent
    );
  }

  _trackTooltipDisplayed() {
    let previousTooltipContent = this.previousTrackedTooltip;
    let currentTooltipContent = this.currentlyShownTooltipContent;
    if (!isBlank(currentTooltipContent) && currentTooltipContent !== previousTooltipContent) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'displayed',
        object: 'tooltip',
        context: 'question',
        tooltip: currentTooltipContent,
      });
      this.previousTrackedTooltip = currentTooltipContent;
    }
  }

  _updateTitle(text) {
    if (this.args.answer.exampleQuestions.length === 1 && !this.args.answer.hasTitle) {
      this.args.answer.title = text.slice(0, 255);
    }
  }

  @action
  showAddManualExampleModal(visible) {
    this.addManualExampleModalIsVisible = visible;
  }

  @action
  addManualExample(text) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'manual_example_question',
      context: 'add_manual_question',
      models: [this.args.answer],
      question_text: text,
    });

    text = text.trim();

    if (text.length > 0) {
      this.args.answer.exampleQuestions.createFragment({ text, addedFrom: 'main_curation_flow' });
      this.questionTrainingState.addRecentExample({ text }, 'manual');
    }

    this._updateTitle(text);

    this.addManualExampleModalIsVisible = false;
    this.manualExample = '';

    this._trackTooltipDisplayed();
  }

  @action
  onNewSearchResults() {
    this.hasSearched = true;
    this._trackTooltipDisplayed();
  }

  @action
  onSuggestedClusterSelected(cluster) {
    let cluster_position = this.args.suggestedAnswerClusters.indexOf(cluster);
    let keywords = cluster.archetype.text;
    this.intercomEventService.trackAnalyticsEvent({
      answer_id: this.args.answer.id,
      action: 'clicked',
      object: 'create_new_answer',
      context: 'from_faq',
      keywords,
      tile_position: cluster_position < 0 ? null : cluster_position + 1,
    });

    this.args.answer.applyExample(cluster, [cluster.archetype]);

    this.suggestionId = cluster.id;
    this._initQuestionTrainingState();
    this._trackTooltipDisplayed();
  }

  @action
  acceptSuggestedQuestion(searchResult, searchTerm) {
    searchResult['addedFrom'] = 'main_curation_flow';
    this.args.answer.exampleQuestions.createFragment(searchResult);
    let rejectedExample = this.args.answer.rejectedExampleQuestions.findBy(
      'identifier',
      searchResult.identifier,
    );
    if (rejectedExample) {
      this.args.answer.rejectedExampleQuestions.removeObject(rejectedExample);
    }
    this.questionTrainingState.addRecentExample(searchResult);
    this._updateTitle(searchTerm);
    this._trackTooltipDisplayed();
  }

  @action
  rejectSuggestedQuestion(searchResult) {
    let example = this.args.answer.exampleQuestions.findBy('text', searchResult.text);

    if (example) {
      this.questionTrainingState.removeExampleQuestion(example);
      this.args.answer.exampleQuestions.removeObject(example);
    }

    let rejectedExample = this.args.answer.rejectedExampleQuestions.findBy(
      'identifier',
      searchResult.identifier,
    );
    if (!rejectedExample) {
      this.args.answer.rejectedExampleQuestions.createFragment(searchResult);
    }

    this.answerFollowOnCreationService.setRejectedQuestions(
      this.args.answer.rejectedExampleQuestions,
    );

    this.questionTrainingState.rejectExampleQuestion(searchResult);
    this._trackTooltipDisplayed();
  }

  @action
  removeExample(exampleQuestion) {
    this.args.answer.exampleQuestions.removeObject(exampleQuestion);
    this.questionTrainingState.removeExampleQuestion(exampleQuestion);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'removed',
      object: 'question',
      models: [this.args.answer],
      question_text: exampleQuestion.text,
      question_id: exampleQuestion.identifier,
    });
    this._trackTooltipDisplayed();
  }
}
