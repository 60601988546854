/* import __COLOCATED_TEMPLATE__ from './seat-input-field.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  seatNumber: number | string;
  onSeatNumberChange: (value: number | string) => void;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class SeatInputField extends Component<Signature> {
  maxSeatNumber = 100;
  enabledArrowColor = '#222222';
  disabledArrowColor = '#757575';
  @tracked seatNumber = this.args.seatNumber;

  @action
  increment() {
    if (this.seatNumber === this.maxSeatNumber) {
      return;
    }
    this.seatNumber = +this.seatNumber + 1;
    this.args.onSeatNumberChange(this.seatNumber);
  }

  @action
  decrement() {
    if (this.seatNumberIsAtMinimum) {
      return;
    }
    this.seatNumber = +this.seatNumber - 1;
    this.args.onSeatNumberChange(this.seatNumber);
  }

  get seatNumberIsAtMinimum() {
    return this.seatNumber === 1 || this.seatNumber === '';
  }

  get disableUpArrow() {
    return this.seatNumber === this.maxSeatNumber;
  }

  get disableDownArrow() {
    return this.seatNumberIsAtMinimum;
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (
      !/\d/.test(e.key) &&
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'ArrowUp', 'ArrowDown'].includes(
        e.key,
      )
    ) {
      e.preventDefault();
    }
  };

  @action handleChanges(e: Event) {
    let newValue = (e.target as HTMLInputElement)?.value;
    this.seatNumber = newValue === '' ? '' : Math.min(Math.max(1, +newValue), this.maxSeatNumber);
    this.args.onSeatNumberChange(this.seatNumber);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AnnualPlans::SeatInputField': typeof SeatInputField;
  }
}
