/* import __COLOCATED_TEMPLATE__ from './list-item.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import { contentWrapperTypes, statisticKeys } from 'embercom/models/data/outbound/constants';
import { isEmpty } from '@ember/utils';

export default class ListItem extends Component {
  @service store;
  @service appService;
  @service intl;
  @service outboundHomeService;
  @service usageLimitsService;

  statisticKeys = statisticKeys;
  @tracked targetChannelsEmpty = isEmpty(
    this.args.profile.contents.firstObject.contentData.target_channels?.length,
  );

  get getState() {
    let key = Object.keys(states).find(
      (key) => states[key] === this.args.profile.contents.firstObject.state,
    );
    return this.intl.t('ai-agent.profiles.list-item.status', { status: key });
  }

  get hardLimitBreached() {
    return this.usageLimitsService.finHardLimitBreached;
  }

  @task({ restartable: true })
  *getStats() {
    let rulesetIds = this.args.profiles
      .filter((contentWrapper) => contentWrapper.contentWrapperType === contentWrapperTypes.ruleset)
      .map((contentWrapper) => contentWrapper.contentWrapperId);
    let newStats = yield this.outboundHomeService.getStats({
      ruleset_ids: rulesetIds,
      statistics: [this.statisticKeys.receipts], //We only need the receipts statistics
      app_id: this.appService.app.id,
      range_start: this.args.range?.start,
      range_end: this.args.range?.end,
      ...this._subaggregations,
    });
    this._updateStats(newStats);
  }

  _updateStats(statsByContentWrapper) {
    statsByContentWrapper.forEach((statsForContentWrapper) => {
      statsForContentWrapper.forEach((stat) =>
        this.store.push(this.store.normalize('outbound/content-statistic', stat)),
      );
    });
  }

  get targetChannels() {
    if (this.targetChannelsEmpty) {
      return;
    }
    return this.args.profile.contents.firstObject.contentData.target_channels.map((channel) => {
      return this.intl.t('operator.workflows.channels.type', { channel });
    });
  }

  get targetChannelsLabel() {
    if (this.targetChannels.length > 3) {
      return this.intl.t('operator.workflows.channels.multiple-channels', {
        channelOne: this.targetChannels[0],
        remainingChannelCount: this.targetChannels.length - 1,
      });
    }
    return this.intl.formatList(this.targetChannels, {
      type: 'conjunction',
    });
  }

  get targetChannelsTooltip() {
    return this.intl.formatList(this.targetChannels, {
      type: 'conjunction',
    });
  }

  get selectedAudience() {
    let audience = this.args.profile.audienceTypes.map((type) => {
      return this.intl.t('ai-agent.profiles.list-item.audience-summary', {
        audienceType: type,
      });
    });
    return this.intl.formatList(audience, {
      type: 'conjunction',
    });
  }

  get customAnswersEnabled() {
    return this.args.profile.contents.firstObject.contentData.use_custom_answers;
  }

  get aiAnswersEnabled() {
    return (
      this.args.profile.contents.firstObject.contentData.use_ai_answers && !this.hardLimitBreached
    );
  }
}
