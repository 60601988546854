/* import __COLOCATED_TEMPLATE__ from './upload-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';

interface Signature {
  Args: {
    label?: string;
    fileValidators?: FileValidator[];
    multiple?: boolean;
    onFilesSelected: (files: File[]) => void;
    allowedFileTypes: string[];
    hidden?: boolean;
  };
  Blocks: {
    default: [Function];
  };
}

export type FileValidator = {
  validate: (file: File) => boolean | Promise<boolean>;
  errorMessage: (file: File) => string;
};

export const FILE_SIZE_VALIDATOR = (maxSize: number, errorMessage: string) => ({
  validate: (file: File) => {
    return file.size <= maxSize;
  },
  errorMessage: () => errorMessage,
});

export default class UploadButton extends Component<Signature> {
  @service declare notificationsService: any;
  @ref('fileInput') fileInput?: HTMLInputElement;

  @action
  clickFileInput() {
    this.fileInput?.click();
  }

  get allowedFileTypes() {
    return this.args.allowedFileTypes?.join(',') || '';
  }

  @action
  async onFileSelect() {
    if (!this.fileInput) {
      return;
    }
    let selectedFiles = this.fileInput.files || [];

    let validFiles: File[] = [];
    for (let file of selectedFiles) {
      let validators = this.args.fileValidators;
      if (!validators || validators.length === 0) {
        validFiles.push(file);
      } else {
        let isValidFile = true;
        for (let validator of validators) {
          if (!(await validator.validate(file))) {
            this.notificationsService.notifyError(validator.errorMessage(file));
            isValidFile = false;
            break;
          }
        }
        if (isValidFile) {
          validFiles.push(file);
        }
      }
    }
    this.args.onFilesSelected(validFiles);
    this.fileInput.value = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::UploadButton': typeof UploadButton;
  }
}
