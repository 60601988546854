/* import __COLOCATED_TEMPLATE__ from './migration-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Price from 'embercom/models/price';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import moment from 'moment-timezone';
import {
  formattedMigrationDate,
  howWeAssignSeatsLink,
} from 'embercom/helpers/billing/migrations-helper';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

interface Args {
  migration: StripeMigration;
  currentPrices: Price[];
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  allFeatures: string[];
  availableFeaturesForApp: string[];
  showEditPlanTab: boolean | undefined;
  onProactiveMigrationSave: () => void;
  newSettings?: boolean;
  selectedTab?: string;
  onTabSelectionChange?: (selectedTab: string) => void;
  migrationSeatConfiguration: MigrationSeatConfiguration;
  onMigrationSeatConfigurationSave: () => Promise<void>;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationMigrationDetailsComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked oldSettingsSelectedTab = this.initialSelectedTab();

  SEATS_ASSIGNMENT_TOOLTIP = {
    pppWithSeats:
      'billing.migrations.migration_overview.migrationChoice.seats_assignment_tooltip_ppp_seats',
    other:
      'billing.migrations.migration_overview.migrationChoice.seats_assignment_tooltip_other_seats',
  };

  get selectedTab() {
    if (this.args.newSettings) {
      return this.args.selectedTab;
    }
    return this.oldSettingsSelectedTab;
  }

  initialSelectedTab() {
    if (this.args.showEditPlanTab || !this.args.migration.isProactive) {
      return 'moveToNewPricing';
    }
    return 'migrationOverview';
  }

  get shouldShowImprovedFlow() {
    return this.appService.app.canSeePricingMigrationImprovedFlow;
  }

  get editPlanLabel() {
    return this.args.migration.isProactive
      ? this.intl.t('billing.migrations.migration_edit_plan')
      : this.intl.t('billing.migrations.move_to_new_pricing');
  }

  get nextInvoiceDate() {
    return this.intl.formatDate(moment(this.customerService.customer?.currentBillingCycleEndDate), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  get formattedMigrationDate() {
    return formattedMigrationDate(this.args.migration.migrationDate, this.intl);
  }

  get shouldShowHelpTab() {
    return this.args.migration.isProactive;
  }

  @action
  talkToSales() {
    showNewMessageInIntercomWidget();
  }

  get howWeAssignSeatsLink() {
    return howWeAssignSeatsLink(this.appService.app, this.customerService.customer);
  }

  get seatAssignmentTooltipContent() {
    if (this.appService.app.canUsePerProductPricingFlow) {
      return this.SEATS_ASSIGNMENT_TOOLTIP.pppWithSeats;
    }
    return this.SEATS_ASSIGNMENT_TOOLTIP.other;
  }

  @action onTabSelectionChange(selectedValue: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: selectedValue,
      object: 'tab_changes',
    });

    document
      .querySelector('.migration-details-container')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    if (this.args.newSettings) {
      this.args.onTabSelectionChange!(selectedValue);
    } else {
      this.oldSettingsSelectedTab = selectedValue;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationDetails': typeof BillingMigrationMigrationDetailsComponent;
  }
}
