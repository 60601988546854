/* import __COLOCATED_TEMPLATE__ from './stacked-resolution-bot-coverage.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dateFormatter } from 'embercom/components/reporting/tooltip/default-tooltip';

export default class StackedResolutionBotCoverage extends Component {
  @service intl;

  get contentForCurrentDate() {
    return this.args.contentBreakdown.context.find((c) => c.key === this.args.currentDate);
  }

  get formattedDate() {
    return dateFormatter({
      intl: this.intl,
      currentDate: this.args.currentDate,
      aggregationInterval: this.args.aggregationInterval,
      rangeStart: this.args.rangeStart,
      rangeEnd: this.args.rangeEnd,
      timezone: this.args.timezone,
    });
  }
}
