/* import __COLOCATED_TEMPLATE__ from './settings-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import Component from '@glimmer/component';

import { MAX_ARTICLES_IN_A_COLLECTION } from 'embercom/lib/articles/constants';

export default class SettingsSideDrawer extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service helpCenterService;
  @service appService;
  @service store;
  @service intl;

  @tracked ruleset = this.args.article.ruleset;
  @tracked articleGroup = this.args.article.folder;

  @tracked canSave = true;

  constructor() {
    super(...arguments);
    if (this.args.article) {
      this.setOriginalCollections();
    }
  }

  setOriginalCollections() {
    this.original_folder_id = this.args.article.get('folder.id');
    this.original_collections = this.args.article.get('inCollections').getEach('id');
  }

  trackEducateArticleEvent(article, metadata = {}) {
    metadata.owner = 'educate';
    metadata.object = 'article';
    metadata.article_id = article.get('id');
    if (!metadata.collection_id) {
      metadata.collection_id = article.get('collection.id');
    }
    if (!metadata.section_id) {
      metadata.section_id = article.get('section.id');
    }

    let eventName = metadata.eventName || 'educate-event';
    delete metadata['eventName'];

    this.intercomEventService.trackEvent(eventName, metadata);
  }

  get segmentedContentTurnedOff() {
    return this.helpCenterService.allSites.every((site) => !site.segmentedContentTurnedOn);
  }

  get userTypeDescription() {
    let userTypeFromAudiencePreview =
      this.ruleset.get('audiencePreview.predicateGroup.userType') ||
      this.ruleset.get('audiencePreview.rolePredicateGroup.userType');
    let userType = userTypeFromAudiencePreview || this.ruleset.userType || 'user';
    if (userType === 'user') {
      return 'user';
    } else if (userType === 'lead') {
      return 'lead';
    }
    return 'person';
  }

  get collectionsList() {
    return this.home
      .get('children')
      .filter((collection) => !collection.readOnly)
      .map((collection) => ({
        text: collection.name,
        value: collection.id,
        icon: collection.icon,
        component: 'articles/editor/collection-select-item',
      }));
  }

  get showSectionsDropdown() {
    let articleGroupIsCollectionWithSections =
      this.articleGroup.get('isCollection') && this.articleGroup.get('hasChildren');
    let articleGroupType = this.articleGroup.get('type');
    let articleGroupIsSectionOrSubsection = ['section', 'sub-section'].includes(articleGroupType);
    return articleGroupIsSectionOrSubsection || articleGroupIsCollectionWithSections;
  }

  get app() {
    return this.appService.app;
  }

  get showSubSectionsDropdown() {
    // Remove appService when removing feature flag
    let subSectionFF = this.app.canUseArticlesSubSections;
    if (!subSectionFF) {
      return false;
    }

    let articleGroupIsSectionWithChildren =
      this.articleGroup.get('isANonHomeSection') && this.articleGroup.get('hasChildren');

    let articleGroupIsSubSection = this.articleGroup.get('type') === 'sub-section';

    return articleGroupIsSubSection || articleGroupIsSectionWithChildren;
  }

  get sectionsInSelectedCollection() {
    return this.args.article.collection.get('children').map((section) => ({
      text: section.name,
      value: section.id,
    }));
  }

  get subSectionsInSelectedSection() {
    return this.args.article.section.get('children')?.map((section) => ({
      text: section.name,
      value: section.id,
    }));
  }

  get home() {
    return this.store.peekRecord('articles/article-group', 'home');
  }

  get excludeArticleSuggestionsLabel() {
    return this.intl.t(
      'articles.editor.side-drawer.settings.exclude-from-suggestions-in-messenger-and-related-articles',
    );
  }

  get appHasMultiHelpCenterFeature() {
    return this.app.canUseFeature('multi_help_center');
  }

  get hasMultipleHelpCenters() {
    return this.helpCenterService.allSites.length > 1;
  }

  get showMultiHcCollectionSelector() {
    return this.appHasMultiHelpCenterFeature || this.hasMultipleHelpCenters;
  }

  @action
  setCanSave(canSave) {
    this.canSave = canSave;
  }

  @action
  onUpdatePredicates(predicates) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  @action
  setArticleGroup(groupId) {
    let folder = this.store.peekRecord('articles/article-group', groupId);
    this.args.article.set('folder', folder);
    this.args.article.set('inCollections', folder.isHome ? [] : [folder]);
    this.trackEducateArticleEvent(this.args.article, {
      place: 'article',
      action: 'selected',
      context: 'collection',
      value: groupId,
    });
  }

  @action
  setArticleGroups(groupIds) {
    let folders = this.store.peekAll('articles/article-group').filter((group) => {
      return groupIds.includes(group.id);
    });
    // TODO There are still a lot of places that refers to folder,
    // let's keep it until we remove all the references
    this.args.article.set(
      'folder',
      folders.firstObject || this.store.peekRecord('articles/article-group', 'home'),
    );
    this.args.article.set('inCollections', folders);
    this.trackEducateArticleEvent(this.args.article, {
      place: 'article',
      action: 'selected-many',
      context: 'collections',
      value: groupIds,
    });
  }

  @action
  async onCancel() {
    if (this.original_folder_id) {
      let original_folder = this.store.peekRecord(
        'articles/article-group',
        this.original_folder_id,
      );
      this.args.article.set('folder', original_folder);
    }
    if (this.original_collections) {
      let original_collections = this.store.peekAll('articles/article-group').filter((group) => {
        return this.original_collections.includes(group.id);
      });
      this.args.article.set('inCollections', original_collections);
    }
    this.args.article.ruleset.rollbackAttributes();
    this.args.onClose();
  }

  @action
  async saveToServer() {
    try {
      if (this.app.canUseMultipleHelpCenters) {
        await this.args.article.get('updateSettingsMultiple').perform();
      } else {
        await this.args.article.get('updateSettings').perform();
      }
      await this.args.article.reload();
      this.setOriginalCollections();
      this.args.onClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.side-drawer.settings.article-settings-updated'),
      );
    } catch (error) {
      if (error.jqXHR?.responseJSON?.key === 'max_collection_articles_reached') {
        this.notificationsService.notifyError(
          this.intl.t('articles.collections.add_article.max_articles_reached', {
            limit: MAX_ARTICLES_IN_A_COLLECTION,
          }),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('articles.editor.side-drawer.settings.problem-in-saving-settings'),
        );
      }
      this.onCancel();
    }
  }
}
