/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';

const SECTION_MODEL_MAP: any = {
  'suggestion-settings': 'aiAgentSettings',
  'answer-bot': '',
};

// https://app.intercom.com/a/apps/tx2p130c/outbound/survey/35658277

interface SettingsArgs {
  initialSection: string;
  aiAgentQuickReplySettings: any;
  aiAgentSettings: any;
  aiAgentToneOfVoiceSettings: any;
  answerCounts: any;
  liveResolutionBotBehaviors: any;
  previewConfiguration: any;
  completeConversationImportFlow: any;
}

export default class Settings extends Component<SettingsArgs> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare permissionsService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked showAiAgentImportSettingsModal = false;
  @tracked conversationImports: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.conversationImports = this.store.findAll('conversation-import');
  }

  get app() {
    return this.appService.app;
  }

  get showNoLiveAnswersBanner() {
    return !this.app.canUseFinExperience && this.args.answerCounts.live === 0;
  }

  get showNoLiveBehaviorsBanner() {
    return (
      !this.app.canUseFinExperience &&
      this.args.liveResolutionBotBehaviors?.contentWrappers?.length === 0
    );
  }

  get hasActiveZendeskChatConversationImport() {
    return this.conversationImports.any(
      (conversationImport: any) =>
        conversationImport.active && conversationImport.origin === 'zendesk_chat',
    );
  }

  get hasActiveZendeskTicketsConversationImport() {
    return this.conversationImports.any(
      (conversationImport: any) =>
        conversationImport.active && conversationImport.origin === 'zendesk_tickets',
    );
  }

  @action sectionDidOpen(sectionName: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action sectionDidClose(sectionName: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action async sectionCanClose(sectionName: string) {
    let model = SECTION_MODEL_MAP[sectionName];
    let confirmNotSaved = {
      title: this.intl.t('operator.settings.confirm-not-saved.close-without-saving'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('operator.settings.confirm-not-saved.continue-without-saving'),
      cancelButtonText: this.intl.t('operator.settings.confirm-not-saved.keep-editing'),
      body: this.intl.t('operator.settings.confirm-not-saved.body'),
    };

    if (!model || !model.hasDirtyAttributes) {
      return true;
    }

    let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);

    if (confirmed) {
      model.rollbackAttributes();
      return true;
    } else {
      return false;
    }
  }

  @action openAiAgentImportSettingsModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'import_conversation_history_link',
      context: 'zendesk_chat_import',
    });
    this.showAiAgentImportSettingsModal = true;
  }

  @action closeAiAgentImportSettingsModal() {
    this.showAiAgentImportSettingsModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Settings': typeof Settings;
  }
}
