/* import __COLOCATED_TEMPLATE__ from './paginated-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { alias, readOnly, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const MAX_SELECTION = 20;
const NON_LOCALIZED_DEFAULT_COLUMNS = ['lastEditedAt', 'collection'];

export default Component.extend({
  helpCenterService: service(),
  store: service(),
  site: readOnly('helpCenterService.site'),
  siteDefaultLangauge: readOnly('site.locale'),
  classNames: ['flex-auto flex flex-col pl-8 pr-8 min-h-0'],
  selectedLocalesOrdered: alias('helpCenterService.site.selectedLocalesOrdered'),
  allUniqueLocalesOrdered: alias('helpCenterService.allUniqueLocalesOrdered'),
  currentlySelectedLocaleId: readOnly('helpCenterService.currentlySelectedLocaleId'),
  appService: service(),
  intl: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  intercomEventService: service(),
  selectedContent: null,
  selectedColumns: null,

  init() {
    this._super(...arguments);
    this.set('selectedContent', []);
    this.set(
      'selectedColumns',
      isEmpty(this.request.columns) ? this.defaultColumns : this.request.columns,
    );
    this.set('componentRequest', this.request);
    this.store.findAll('help-center-site');
  },

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.isLoading) {
      this.resetBulkSelect();
    }
  },

  defaultColumns: computed(function () {
    return [this.siteDefaultLangauge, ...NON_LOCALIZED_DEFAULT_COLUMNS];
  }),

  tags: computed('app.tags', function () {
    return this.app.tags.reject((tag) => tag.archived);
  }),

  isInternalSource: computed('componentRequest.conditions.sync', function () {
    let isSync = this.componentRequest?.conditions?.sync;
    return !(isSync === 'true');
  }),

  emptyTagsEntry: computed('intl.locale', function () {
    return { text: this.intl.t('articles.list.no_tags'), value: 0 };
  }),

  hasMorePages: computed('articles.meta', function () {
    if (!this.articles.meta) {
      return false;
    }
    return this.articles.meta.page < this.articles.meta.total_pages;
  }),

  columns: computed(
    'intl.locale',
    'allUniqueLocalesOrdered.[]',
    'fromExternalSource',
    'selectedColumns',
    function () {
      let languageColumns = this.allUniqueLocalesOrdered.map((loc) => {
        let label = loc.localeId
          .split('-')
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1, part.length))
          .join('-');

        return {
          label: this.helpCenterService.isAllSingleLanguage ? 'Status' : label,
          valuePath: loc.localeId,
          minWidth: '40px',
          ...(!this.helpCenterService.isAllSingleLanguage && { type: 'number' }),
          type: 'locale',
          isVisible: this.columnShouldBeVisible(`${loc.localeId}`),
        };
      });

      let externalSourceColumn = this.fromExternalSource
        ? [
            {
              label: this.intl.t('articles.list.table_column.source'),
              valuePath: 'source',
              minWidth: '120px',
              width: '320px',
            },
          ]
        : [];

      let checkboxColumn = this.fromExternalSource
        ? []
        : [
            {
              valuePath: 'isSelectable',
              type: 'checkbox',
              minWidth: '30px',
              maxWidth: '30px',
            },
          ];
      let helpCenterColumn = this.app.canUseMultipleHelpCenters
        ? [
            {
              label: this.intl.t('articles.list.table_column.help-centers'),
              valuePath: 'helpCenter',
              minWidth: '120px',
              width: '320px',
              isVisible: this.columnShouldBeVisible('helpCenter'),
            },
          ]
        : [];
      return [
        ...checkboxColumn,
        {
          label: this.intl.t('articles.list.table_column.article'),
          valuePath: 'article',
          minWidth: '320px',
          maxWith: '0',
          type: 'multiline',
        },
        ...externalSourceColumn,
        {
          label: this.intl.t('articles.list.table_column.last_edited'),
          valuePath: 'lastEditedAt',
          minWidth: '120px',
          width: '320px',
          isSortable: true,
          isVisible: this.columnShouldBeVisible('lastEditedAt'),
        },
        ...languageColumns,
        { label: '', valuePath: 'empty', width: '1px' },
        {
          label: this.intl.t('articles.list.table_column.author'),
          valuePath: 'author',
          minWidth: '120px',
          maxWidth: '320px',
          type: 'avatar-with-text',
          isVisible: this.columnShouldBeVisible('author'),
        },
        {
          label: this.intl.t('articles.list.table_column.collection'),
          valuePath: 'collection',
          maxWidth: '320px',
          isVisible: this.columnShouldBeVisible('collection'),
        },
        ...helpCenterColumn,
        {
          label: this.intl.t('articles.list.table_column.performance'),
          valuePath: 'performance',
          minWidth: '320px',
          width: '320px',
          isVisible: this.columnShouldBeVisible('performance'),
        },
        {
          label: this.intl.t('articles.list.table_column.last_edited_by'),
          valuePath: 'lastEditedById',
          minWidth: '120px',
          width: '320px',
          isVisible: this.columnShouldBeVisible('lastEditedById'),
        },
      ];
    },
  ),

  columnShouldBeVisible(valuePath) {
    return this.selectedColumns.includes(valuePath);
  },

  languageOptions: computed('allUniqueLocalesOrdered', function () {
    return this.allUniqueLocalesOrdered.map((loc) => ({
      text: loc.name,
      value: loc.localeId,
    }));
  }),

  articlesMissingAnyContents: computed('articles.[]', 'currentlySelectedLocaleId', function () {
    return this.articles.filter((article) => {
      return !article.contentByLocale(this.currentlySelectedLocaleId);
    });
  }),

  hasMultipleLocales: alias('helpCenterService.site.hasMultipleLocales'),

  selectedLanguage: computed('currentlySelectedLocaleId', 'languageOptions', function () {
    let option = this.languageOptions.findBy('value', this.currentlySelectedLocaleId);
    if (option) {
      return option.text;
    }
  }),

  selectedSortOption: computed('componentRequest.order.{key,direction}', function () {
    return {
      valuePath: 'lastEditedAt',
      direction: this.componentRequest.order?.direction || 'desc',
    };
  }),

  helpCenters: computed(function () {
    let result = [];
    this.store
      .peekAll('help-center-site')
      .forEach((site) => result.push({ text: site.name, value: site.id }));
    return result;
  }),

  groupsList: computed('home', function () {
    return this.descendantsList('home');
  }),

  // recursive action to get all descendants of a given article group id
  descendantsList: action(function (articleGroupId) {
    let result = [];
    this.store
      .peekRecord('articles/article-group', articleGroupId)
      .get('children')
      .forEach((child) => {
        result.push({
          text: child.name,
          value: child.id,
          icon: child.icon,
          type: child.type,
        });
        result = result.concat(this.descendantsList(child.id));
      });
    return result;
  }),

  collectionsList: computed('intl.locale', 'home', function () {
    let collections = this.store.peekAll('articles/article-group');
    return collections.map((collection) => {
      let collectionName = collection.isHome
        ? this.intl.t('articles.shared.no-collection')
        : collection.name;
      let helpCenter = collection.helpCenterId
        ? this.store.peekRecord('help-center-site', collection.helpCenterId)
        : null;

      return {
        text: collectionName,
        value: collection.id,
        icon: collection.icon,
        helpCenterName: helpCenter?.name || '',
      };
    });
  }),

  handleSelect: action(function (articleId) {
    let updatedSelectedContent;
    if (this.selectedContent.includes(articleId)) {
      updatedSelectedContent = this.selectedContent.without(articleId);
    } else {
      updatedSelectedContent = [...this.selectedContent, articleId];
    }
    this.set('selectedContent.[]', updatedSelectedContent);
  }),
  indeterminateState: computed('selectedContent.[]', 'bulkSelectState', function () {
    if (this.bulkSelectState) {
      return false;
    }
    return this.selectedContent.length > 0;
  }),
  bulkSelectState: computed('selectedContent.[]', function () {
    if (this.isLoading) {
      return false;
    }
    return this.selectedContent.length === this.limitSelection;
  }),

  hasSelectedContent: gt('selectedContent.[].length', 0),

  filtersApplied: computed(
    'selectedTagValues',
    'componentRequest.conditions.{status,collection_ids,languages,help_center_ids}',
    function () {
      let filters = [];
      if (this.componentRequest) {
        if (this.selectedTagValues && this.selectedTagValues.length) {
          filters.push('tag_ids');
        }
        if (this.componentRequest.conditions) {
          if (this.componentRequest.conditions.status) {
            filters.push('status');
          }
          if (
            this.componentRequest.conditions.collection_ids &&
            this.componentRequest.conditions.collection_ids.length
          ) {
            filters.push('collection_ids');
          }
          if (
            this.componentRequest.conditions.help_center_ids &&
            this.componentRequest.conditions.help_center_ids.length
          ) {
            filters.push('help_center_ids');
          }
          if (
            this.componentRequest.conditions.languages &&
            this.componentRequest.conditions.languages.length
          ) {
            filters.push('languages');
          }
          if (
            this.componentRequest.conditions.author_ids &&
            this.componentRequest.conditions.author_ids.length
          ) {
            filters.push('author_ids');
          }
          if (
            this.componentRequest.conditions.last_edited_by_ids &&
            this.componentRequest.conditions.last_edited_by_ids.length
          ) {
            filters.push('last_edited_by_ids');
          }
        }
      }
      return filters;
    },
  ),

  selectableFilters: computed(
    'filtersApplied',
    'synchronizationEnabled',
    'isInternalSource',
    'app',
    function () {
      let items = [
        { text: 'Author', icon: 'person', value: 'author_ids' },
        { text: 'Last edited by', icon: 'person', value: 'last_edited_by_ids' },
        { text: 'Language', icon: 'globe', value: 'languages' },
        { text: 'Collection', icon: 'collection', value: 'collection_ids' },
      ];

      if (!this.synchronizationEnabled && this.isInternalSource) {
        items.push({ text: 'Status', icon: 'active', value: 'status' });
        items.push({ text: 'Tag', icon: 'tag', value: 'tag_ids' });
      }
      if (this.app.canUseMultipleHelpCenters) {
        items.push({ text: 'Help Center', icon: 'article', value: 'help_center_ids' });
      }

      return [
        {
          items: items.reject((item) => this.filtersApplied.includes(item.value)),
        },
      ];
    },
  ),

  filterContent: action(async function (filterProperty, value) {
    this.set('activeFilter', undefined);
    this.set('selectedTagValues', value);
    this.updateFilters({
      selectedTagValues: value,
      selectedContentStatus: this.selectedContentStatus,
      request: this.componentRequest,
    });
  }),

  onNewFilterSelected: action(function (key, selectedValues) {
    this.set('activeFilter', undefined);
    let request = this.componentRequest;
    let filters = {
      request: { ...request },
      selectedTagValues: this.selectedTagValues,
    };
    filters.request.conditions = { ...filters.request.conditions };
    filters.request.conditions[key] = selectedValues;
    this.set('componentRequest', filters.request);
    this.updateFilters(filters);
  }),

  onSourceSelected: action(function (newValue) {
    this.resetBulkSelect();
    this.set('isSync', `${!newValue}`);
    this.onNewFilterSelected('sync', `${!newValue}`);
  }),

  onSort: action(function () {
    let oldDirection = this.componentRequest.order.direction;
    let newDirection = oldDirection === 'asc' ? 'desc' : 'asc';
    let order = { direction: newDirection, key: this.componentRequest.order.key };
    let filters = { request: { ...this.componentRequest, ...{ order } } };
    this.set('componentRequest', filters.request);
    this.updateFilters(filters);
  }),

  resetBulkSelect: action(function () {
    this.set('selectedContent', []);
  }),
  limitSelection: computed('app', function () {
    return MAX_SELECTION;
  }),
  onBulkSelect: action(function () {
    let moreThanMax = this.articles.length > this.limitSelection;
    let articles = moreThanMax ? this.articles.slice(0, this.limitSelection) : this.articles;
    let hasAllSelected = articles.every((article) => this.selectedContent.includes(article.id));
    if (hasAllSelected) {
      this.resetBulkSelect();
    } else {
      this.set(
        'selectedContent.[]',
        articles.map((article) => article.id),
      );
      if (moreThanMax) {
        this.notificationsService.notifyWarning(
          this.intl.t('articles.list.bulk_actions.selection_limit_hit', {
            limit: this.limitSelection,
          }),
        );
      }
    }
  }),

  onColumnSelect: action(function (newColumns) {
    this.logColumnSelectionEvent(this.selectedColumns, newColumns);
    this.set('selectedColumns', newColumns);
    this.set('componentRequest', { ...this.componentRequest, ...{ columns: newColumns } });
    this.set('isPresetViewChanged', true);
  }),

  logColumnSelectionEvent: action(function (oldColumns, newColumns) {
    let added_column = newColumns.find((column) => !oldColumns.includes(column));
    let removed_column = oldColumns.find((column) => !newColumns.includes(column));
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'paginated_table_column_selector',
      place: 'articles_list',
      section: 'articles',
      added_column,
      removed_column,
      new_columns: newColumns,
    });
  }),

  totalArticleCount: computed('articles.meta.total_count', function () {
    if (this.articles.meta) {
      return this.articles.meta.total_count;
    }
    return 0;
  }),
  toggle: action(function (id) {
    if (this.ids.includes(id)) {
      this.ids = this.ids.without(id);
    } else {
      this.ids = [...this.ids, id];
    }
  }),
  isChecked: computed('selectedContent.[]', function () {
    return true;
  }),

  clearFilters: action(function () {
    let request = this.componentRequest;
    let filters = {
      request: { ...request },
    };
    filters.request.conditions = {};
    filters.selectedTagValues = [];
    if (this.synchronizationEnabled) {
      filters.request.conditions['sync'] = `${this.fromExternalSource}`;
    }
    this.set('componentRequest', filters.request);
    this.set('selectedTagValues', []);
    this.updateFilters(filters);
  }),

  clearSelectedTagValues: action(function () {
    let request = this.componentRequest;
    let filters = {
      request: { ...request },
    };
    filters.selectedTagValues = [];
    this.set('selectedTagValues', []);
    this.updateFilters(filters);
  }),

  fromExternalSource: computed('synchronizationEnabled', 'isInternalSource', function () {
    return this.synchronizationEnabled && !this.isInternalSource;
  }),

  selectedArticles: computed('articles.[]', 'fromExternalSource', function () {
    return this.synchronizationEnabled
      ? this.articles.filter((article) => {
          let isFromExternal = article.siteProvider !== null;
          return isFromExternal === this.fromExternalSource;
        })
      : this.articles;
  }),

  isFirstSyncRunning: computed(
    'fromExternalSource',
    'activeSyncJob',
    'zendeskSyncSetting.lastSuccessfulSyncedAt',
    function () {
      return (
        this.fromExternalSource &&
        this.activeSyncJob &&
        !this.zendeskSyncSetting?.lastSuccessfulSyncedAt
      );
    },
  ),

  selectableColumns: computed('intl.locale', 'columns', function () {
    let tableColumns = [];
    let localeColumns = [];
    this.columns.forEach((column) => {
      if (column.label && column.valuePath !== 'article') {
        if (column.type === 'locale') {
          localeColumns.push({
            text: column.label,
            value: column.valuePath,
            isSelected: column.isVisible,
          });
        } else {
          tableColumns.push({
            text: column.label,
            value: column.valuePath,
            isSelected: column.isVisible,
          });
        }
      }
    });

    return [
      {
        heading: this.intl.t('articles.list.column_selector.attributes_subhead'),
        items: tableColumns,
      },
      {
        heading: this.intl.t('articles.list.column_selector.locales_subhead'),
        items: localeColumns,
      },
    ];
  }),

  synchronizingMessages: computed(
    'intl.locale',
    'activeSyncJob',
    'zendeskSyncSetting',
    function () {
      if (this.zendeskSyncSetting && this.zendeskSyncSetting.error) {
        return {
          iconKey: 'error',
          title: this.intl.t('articles.list.external.error.title'),
          description: this.intl.t('articles.list.external.error.description'),
        };
      }
      let jobStatus = this.activeSyncJob?.job_status;
      let step;
      let title;
      let description;
      switch (jobStatus) {
        case 'gathering_data':
          step = 1;
          title = this.intl.t('articles.list.external.step1.title', { step });
          description = this.intl.t('articles.list.external.step1.description');
          break;
        case 'transforming_data':
          step = 2;
          title = this.intl.t('articles.list.external.step2.title', { step });
          description = this.intl.t('articles.list.external.step2.description');
          break;
        case 'importing_help_center':
          step = 3;
          title = this.intl.t('articles.list.external.step3.title', { step });
          description = this.intl.t('articles.list.external.step3.description');
          break;
        default:
          step = 3;
          title = this.intl.t('articles.list.external.step3.title', { step });
          description = this.intl.t('articles.list.external.step3.description');
      }
      return {
        iconKey: 'sync',
        title,
        description,
      };
    },
  ),
});
