/* import __COLOCATED_TEMPLATE__ from './estimate-price-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { isPresent } from '@ember/utils';
import type Charge from 'embercom/models/billing/price/charge';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import {
  getEstimatedLiteSeatCount,
  getEstimatedCoreSeatCharge,
  getCorePlanBreakdown,
} from 'embercom/helpers/billing/migrations-helper';

interface Args {
  breakdowns: Array<Breakdown>;
  hasPSPUsage: boolean;
  planId:
    | typeof PRICING_5_X_CORE_ESSENTIAL_ID
    | typeof PRICING_5_X_CORE_ADVANCED_ID
    | typeof PRICING_5_X_CORE_EXPERT_ID;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  isConfirmedByCustomer: boolean;
}

interface Signature {
  Args: Args;
}

const USAGE_BASED_PRICING_5_METRICS_TO_SHOW = [
  Metric.resolutions_with_custom_answers,
  Metric.messages_sent,
  Metric.emails_sent,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
  Metric.whatsapp_outbound,
  Metric.whatsapp_inbound,
  Metric.calling,
];

export default class BillingMigrationEstimatePriceDetailsComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  get estimatedLiteSeatCount() {
    return getEstimatedLiteSeatCount(
      this.args.planId,
      this.appService.app.humanAdmins.length,
      this.corePlanBreakdown!,
    );
  }

  get resolutionMetric(): string {
    return Metric.resolutions_with_custom_answers.toString();
  }

  get estimatedUsageBasedMessagingInfo() {
    return this.sortedUsageBasedMessagingCharges.map((charge) => ({
      metricName: this.intl.t(
        `billing.pricing-metric.pricing-5-text-with-usage.${charge.pricing_metric}`,
        { count: charge.actualUsage },
      ),
      price: charge.pricingMetricModel.usageAboveMinimumPrice / 100,
      pricingMetric: charge.pricing_metric,
    }));
  }

  private get sortedUsageBasedMessagingCharges() {
    return this.usageBasedMessagingCharges.sort(
      (chargeA, chargeB) =>
        USAGE_BASED_PRICING_5_METRICS_TO_SHOW.indexOf(chargeA.pricing_metric) -
        USAGE_BASED_PRICING_5_METRICS_TO_SHOW.indexOf(chargeB.pricing_metric),
    );
  }

  private get usageBasedMessagingCharges() {
    let usageBasedMessagingCharges: Array<Charge> = [];

    this.args.breakdowns.forEach((breakdown) => {
      if (isPresent(breakdown.charges)) {
        breakdown.charges.forEach((charge) => {
          if (
            USAGE_BASED_PRICING_5_METRICS_TO_SHOW.includes(charge.pricing_metric) &&
            charge.actualUsage > 0
          ) {
            usageBasedMessagingCharges.push(charge);
          }
        });
      }
    });

    return usageBasedMessagingCharges;
  }

  get coreSeatTranslation() {
    if (this.args.planId === PRICING_5_X_CORE_ESSENTIAL_ID) {
      return this.intl.t('billing.migrations.pricing_5_estimates.breakdown.essential_seat_count', {
        count: getEstimatedCoreSeatCharge(this.corePlanBreakdown)!.actualUsage,
      });
    }

    if (this.args.planId === PRICING_5_X_CORE_ADVANCED_ID) {
      return this.intl.t('billing.migrations.pricing_5_estimates.breakdown.advanced_seat_count', {
        count: getEstimatedCoreSeatCharge(this.corePlanBreakdown)!.actualUsage,
      });
    }

    if (this.args.planId === PRICING_5_X_CORE_EXPERT_ID) {
      return this.intl.t('billing.migrations.pricing_5_estimates.breakdown.expert_seat_count', {
        count: getEstimatedCoreSeatCharge(this.corePlanBreakdown)!.actualUsage,
      });
    }
    return;
  }

  get estimatedCoreSeatCharge() {
    return getEstimatedCoreSeatCharge(this.corePlanBreakdown);
  }

  get pspBasePrice() {
    if (this.pspPlanBreakdown === undefined) {
      return 0;
    }

    return this.pspPlanBreakdown.plan_starting_price! / 100;
  }

  private get corePlanBreakdown() {
    return getCorePlanBreakdown(this.args.breakdowns);
  }

  private get pspPlanBreakdown() {
    return this.args.breakdowns.find(
      (breakdown) => breakdown.plan_id!.toString() === PROACTIVE_SUPPORT_PLUS_BASE_ID,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::EstimatePriceDetails': typeof BillingMigrationEstimatePriceDetailsComponent;
  }
}
