/* import __COLOCATED_TEMPLATE__ from './icon-selector-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const ARROW_SYMBOLS_ICONS = [
  'arrow-up-circle',
  'play-circle',
  'arrows-up-down',
  'arrow-path',
  'arrows-right-left',
  'arrow-down-tray',
  'check-circle',
  'arrow-trending-up',
  'information-circle',
  'question-mark-circle',
  'arrow-trending-down',
  'arrow-down-circle',
  'shopping-cart',
];
const BIZZ_FIN_ICONS = [
  'currency-pound',
  'briefcase',
  'building-office-2',
  'currency-rupee',
  'currency-bangladeshi',
  'wallet',
  'scale',
  'currency-yen',
  'currency-euro',
  'banknotes',
  'credit-card',
  'circle-stack',
  'building-storefront',
  'building-library',
  'currency-dollar',
];
const CHARTS_ICONS = [
  'chart-bar',
  'presentation-chart-line',
  'chart-pie',
  'presentation-chart-bar',
  'chart-bar-square',
];
const COMMUNICATION_ICONS = ['inbox', 'inbox-arrow-down', 'envelope', 'paper-airplane', 'phone'];
const CONTENT_ICONS = ['paint-brush', 'paper-clip', 'book-open', 'pencil-square', 'bookmark'];
const FF_ICONS = [
  'document-check',
  'document-arrow-up',
  'clipboard-document-check',
  'folder-open',
  'document-magnifying-glass',
  'clipboard',
  'archive-box-arrow-down',
  'document-chart-bar',
  'newspaper',
  'document-arrow-down',
  'clipboard-document',
  'document',
  'archive-box',
  'document-text',
  'clipboard-document-list',
  'document-duplicate',
  'folder',
  'folder-arrow-down',
];
const NATURE_ICONS = [
  'sparkles',
  'fire',
  'bug-ant',
  'bolt',
  'star',
  'sun',
  'cloud',
  'moon',
  'heart',
];
const OTHER_ICONS = [
  'scissors',
  'home',
  'globe-alt',
  'home-modern',
  'ticket',
  'map',
  'shopping-bag',
  'globe-asia-australia',
  'key',
  'beaker',
  'globe-europe-africa',
  'musical-note',
  'cake',
  'trophy',
  'truck',
  'receipt-percent',
  'lifebuoy',
  'language',
  'light-bulb',
  'gift',
  'puzzle-piece',
  'globe-americas',
  'rocket-launch',
  'photo',
  'film',
  'academic-cap',
];
const OTHER_DEVICES_ICONS = [
  'computer-desktop',
  'tv',
  'radio',
  'device-phone-mobile',
  'camera',
  'printer',
  'video-camera',
  'calculator',
];
const PEOPLE_CHAT_GETS_ICONS = [
  'hand-thumb-up',
  'face-frown',
  'device-tablet',
  'face-smile',
  'hand-thumb-down',
  'user-circle',
  'chat-bubble-bottom-center-text',
  'users',
  'chat-bubble-left-ellipsis',
];
const SFT_ICONS = [
  'cpu-chip',
  'megaphone',
  'adjustments-horizontal',
  'tag',
  'cursor-arrow-rays',
  'microphone',
  'signal',
  'cog-8-tooth',
  'speaker-wave',
  'at-symbol',
  'wrench-screwdriver',
  'server',
  'cloud-arrow-up',
  'code-bracket-square',
  'bell',
  'funnel',
  'flag',
  'cube',
  'wifi',
  'squares-2x2',
  'cube-transparent',
  'eye-dropper',
  'identification',
  'wrench',
  'cloud-arrow-down',
  'swatch',
  'squares-plus',
  'square-3-stack-3d',
  'clock',
  'command-line',
  'eye',
  'square-2-stack',
  'map-pin',
  'magnifying-glass',
  'lock-closed',
  'finger-print',
  'window',
  'rectangle-stack',
  'calendar-days',
];

class IconGroup {
  id: string;
  titleKey: string;
  icons: string[];

  constructor(id: string, titleKey: string, icons: string[]) {
    this.id = id;
    this.titleKey = titleKey;
    this.icons = icons.map((item: string) => `${id}-${item}`);
  }
}

interface Args {
  folder: $TSFixMe;
  uploadHelper: $TSFixMe;
  action: () => void;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class IconSelectorPopoverComponent extends Component<Signature> {
  @service appService: any;

  @tracked selectedIcon = null;

  @action
  selectIcon(iconIdentifier: string) {
    this.args.folder.icon = iconIdentifier;
    if (this.args.folder.externalIconUrl) {
      this.args.uploadHelper.externalIcon.setProperties({
        isReset: true,
        isSaved: false,
        url: '',
      });
      this.args.folder.externalIconUrl = null;
    }

    if (this.args.action) {
      this.args.action();
    }
  }

  get iconGroups() {
    return [
      new IconGroup('arr-sym', 'articles.collections.icons.arr-sym', ARROW_SYMBOLS_ICONS),
      new IconGroup('bizz-fin', 'articles.collections.icons.bizz-fin', BIZZ_FIN_ICONS),
      new IconGroup('charts', 'articles.collections.icons.charts', CHARTS_ICONS),
      new IconGroup(
        'communication',
        'articles.collections.icons.communication',
        COMMUNICATION_ICONS,
      ),
      new IconGroup('content', 'articles.collections.icons.content', CONTENT_ICONS),
      new IconGroup('ff', 'articles.collections.icons.ff', FF_ICONS),
      new IconGroup('nature', 'articles.collections.icons.nature', NATURE_ICONS),
      new IconGroup('other', 'articles.collections.icons.other', OTHER_ICONS),
      new IconGroup(
        'other-devices',
        'articles.collections.icons.other-devices',
        OTHER_DEVICES_ICONS,
      ),
      new IconGroup(
        'people-chat-gets',
        'articles.collections.icons.people-chat-gets',
        PEOPLE_CHAT_GETS_ICONS,
      ),
      new IconGroup('sft', 'articles.collections.icons.sft', SFT_ICONS),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'articles/site/collections/icon-selector-popover-component': typeof IconSelectorPopoverComponent;
    'Articles::Site::Collections::IconSelectorPopoverComponent': typeof IconSelectorPopoverComponent;
  }
}
