/* import __COLOCATED_TEMPLATE__ from './billing-comparison-estimated-plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Price from 'embercom/models/price';
import {
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
} from 'embercom/lib/billing';
import type IntlService from 'ember-intl/services/intl';
import { getHasPSPUsage, getPspPlanBreakdown } from 'embercom/helpers/billing/migrations-helper';

interface Args {
  planId:
    | typeof PRICING_5_X_CORE_ESSENTIAL_ID
    | typeof PRICING_5_X_CORE_ADVANCED_ID
    | typeof PRICING_5_X_CORE_EXPERT_ID;
  estimatedPriceWithoutPsp: Price;
  estimatedPriceWithPsp: Price;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  isRecommended: boolean;
  isConfirmedByCustomer: boolean;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationBillingComparisonEstimatedPlanCardComponent extends Component<Signature> {
  @service appService: any;
  @service intl!: IntlService;

  get currentPrice() {
    return this.hasPSPUsage ? this.args.estimatedPriceWithPsp : this.args.estimatedPriceWithoutPsp;
  }

  get totalPrice() {
    return this.currentPrice.amount / 100;
  }

  get pspBasePrice() {
    return this.pspPlanBreakdown.plan_starting_price / 100;
  }

  get hasPSPUsage() {
    return getHasPSPUsage(this.args.estimatedPriceWithPsp);
  }

  private get pspPlanBreakdown() {
    return getPspPlanBreakdown(this.args.estimatedPriceWithPsp);
  }

  get heading() {
    switch (this.args.planId) {
      case PRICING_5_X_CORE_ESSENTIAL_ID:
        return this.intl.t('billing.migrations.billing_comparison.essential_plan_card_title');
      case PRICING_5_X_CORE_ADVANCED_ID:
        return this.intl.t('billing.migrations.billing_comparison.advanced_plan_card_title');
      case PRICING_5_X_CORE_EXPERT_ID:
        return this.intl.t('billing.migrations.billing_comparison.expert_plan_card_title');
      default:
        return;
    }
  }

  get body() {
    switch (this.args.planId) {
      case PRICING_5_X_CORE_ESSENTIAL_ID:
        return this.intl.t('billing.migrations.billing_comparison.essential_plan_card_body');
      case PRICING_5_X_CORE_ADVANCED_ID:
        return this.intl.t('billing.migrations.billing_comparison.advanced_plan_card_body');
      case PRICING_5_X_CORE_EXPERT_ID:
        return this.intl.t('billing.migrations.billing_comparison.expert_plan_card_body');
      default:
        return;
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::BillingComparisonEstimatedPlanCard': typeof BillingMigrationBillingComparisonEstimatedPlanCardComponent;
  }
}
