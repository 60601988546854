/* import __COLOCATED_TEMPLATE__ from './fin-answer-length-settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface SettingsArgs {
  aiAgentToneOfVoiceSettings: any;
  body: any;
}

export default class FinAnswerLengthSettings extends Component<SettingsArgs> {
  @service declare intl: IntlService;
  @service intercomEventService: any;

  @tracked selectedAnswerLength: string;

  constructor(owner: unknown, args: SettingsArgs) {
    super(owner, args);
    this.selectedAnswerLength = this.args.aiAgentToneOfVoiceSettings.aiAnswerLength;
  }

  get availableAnswerLength() {
    return [
      {
        value: 'succinct',
        text: this.intl.t('ai-agent.settings.answer-length.values.succinct.name'),
      },
      {
        value: 'standard',
        text: this.intl.t('ai-agent.settings.answer-length.values.standard.name'),
      },
      {
        value: 'comprehensive',
        text: this.intl.t('ai-agent.settings.answer-length.values.comprehensive.name'),
      },
    ];
  }

  @action
  setAnswerLength(value: string) {
    this.args.aiAgentToneOfVoiceSettings.aiAnswerLength = value;
    this.selectedAnswerLength = value;
  }

  @action
  afterSave() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'fin_answer_length',
      answer_length: this.args.aiAgentToneOfVoiceSettings.aiAnswerLength,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::FinAnswerLengthSettings': typeof FinAnswerLengthSettings;
    'ai-agent/fin-answer-length-settings': typeof FinAnswerLengthSettings;
  }
}
