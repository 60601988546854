/* import __COLOCATED_TEMPLATE__ from './unanswered-questions-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import percent from 'embercom/lib/percentage-formatter';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';

export default class UnansweredQuestionsPreview extends Component {
  @service appService;
  @service router;
  @service intercomEventService;

  @tracked cluster;
  @tracked clusters;
  @tracked failedToLoadClusters = false;

  constructor() {
    super(...arguments);
    this.fetchClusters.perform();
  }

  get showComponent() {
    return this.args.clusterIds.length && !this.failedToLoadClusters;
  }

  get totalClustersCount() {
    return this.args.clusterIds.length;
  }

  get lastUpdated() {
    return this.args.lastUpdatedAt;
  }

  get range() {
    let start = moment(this.args.lastUpdatedAt)
      .subtract(this.args.interval - 1, 'days')
      .format();
    let end = moment(this.args.lastUpdatedAt).format();
    return Range.createFromParams(start, end, this.appService.app.timezone);
  }

  @action
  onSelectCluster(cluster, index) {
    this.cluster = cluster;
    this.trackAnalyticsEvent({
      object: 'pagination_buttons',
      action: 'clicked',
      page_index: index,
    });
  }

  @action
  trainExistingAnswer() {
    this.router.transitionTo('apps.app.automation.resolution-bot.clusters', {
      queryParams: {
        interval: this.args.interval,
        language: null,
        clusterId: this.cluster.clusterId,
        showTrainingView: true,
      },
    });

    this.trackAnalyticsEvent({ object: 'training_side_drawer', action: 'opened' });
  }

  @action
  createAnswer() {
    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        exampleId: this.cluster.clusterId,
        exampleSource: 'answers_clusters_preview',
        exampleText: this.cluster.text,
        expandSelectedExamples: true,
        language: this.cluster.language,
        transitionBackTo: 'apps.app.automation.resolution-bot.answers',
      },
    });
    this.trackAnalyticsEvent({ object: 'new_answer_button' });
  }

  @action
  trackAnalyticsEvent(data) {
    let coreData = {
      object: `suggested_answers_preview_${data.object}`,
      action: data.action || 'clicked',
      place: 'answer_bot',
      section: 'operator',
      ...(this.cluster && {
        cluster_id: this.cluster.clusterId,
        cluster_language: this.cluster.language,
        cluster_archetype: this.cluster.text,
      }),
    };

    this.intercomEventService.trackAnalyticsEvent({
      ...data,
      ...coreData,
    });
  }

  @task({ drop: true })
  *fetchClusters() {
    if (this.args.clusterIds.length) {
      try {
        let clusterIds = this.args.clusterIds.slice(0, this.args.maxPreviews);
        let rawClusters = yield ajax({
          url: '/ember/custom_answers/suggested_answer_clusters',
          type: 'GET',
          data: {
            cluster_ids: clusterIds,
            app_id: this.appService.app.id,
            admin_id: this.appService.app.currentAdmin.id,
            interval: this.args.interval,
            cluster_run_id: this.args.clusterRunId,
          },
        });
        if (rawClusters.length) {
          let unsortedClusters = rawClusters.map(this._buildCluster);
          this.clusters = this._sortClusters(unsortedClusters);
          this.cluster = this.clusters[0];
        } else {
          this.failedToLoadClusters = true;
        }
      } catch (_e) {
        this.failedToLoadClusters = true;
      }
    }
  }

  _buildCluster(cluster) {
    let { current_time_period_count: currentCount, previous_time_period_count: previousCount } =
      cluster;
    return {
      currentCount,
      previousCount,
      percentChange: percent(previousCount, currentCount - previousCount),
      clusterId: cluster.cluster_id,
      text: cluster.archetype_text,
      language: cluster.locale,
    };
  }

  _sortClusters(clusters) {
    return clusters.sort(
      (a, b) => b.currentCount - a.currentCount || a.previousCount - b.previousCount,
    );
  }
}
