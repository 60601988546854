/* import __COLOCATED_TEMPLATE__ from './tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TaggingModal extends Component {
  @service notificationsService;
  @service intl;

  @action
  afterTagSave() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('custom-answers.answer-tagging-modal.tags-applied'),
    );
    this.args.answer.reload();
    this.args.onClose();
  }
}
