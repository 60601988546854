/* import __COLOCATED_TEMPLATE__ from './answer-list-item-training-cell.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AnswerListItemTrainingCell extends Component {
  @tracked showLoadingIndicator;

  @action
  trainAnswer() {
    this.showLoadingIndicator = true;
    this.args.extraArgs.trainExistingAnswer(this.args.answer);
  }
}
