/* import __COLOCATED_TEMPLATE__ from './answer-language-select.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { LANGUAGES_RELEASED_TO_EVERYBODY } from 'embercom/lib/resolution-bot-languages';

export default class AnswerLanguageSelect extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get items() {
    let localizedLanguages = this.supportedLanguagesItems.map((languageItem) => ({
      text: this.intl.t(`app.lib.languages.language.${languageItem.value}`),
      value: languageItem.value,
    }));
    return [
      { text: this.intl.t('components.answers.language-select.all-languages'), value: null },
      ...localizedLanguages,
    ];
  }

  get supportedLanguagesItems() {
    return LANGUAGES_RELEASED_TO_EVERYBODY;
  }
}
