/* import __COLOCATED_TEMPLATE__ from './progression-summary-and-action-banner.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';

interface Args {
  earlyStageGraduation: any;
}

export default class ProgressionSummaryAndActionBanner extends Component<Args> {
  @service declare customerService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare router: RouterService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get customer() {
    return this.customerService.customer;
  }

  get stripeMigration() {
    return this.customerService.stripeMigration;
  }

  get hasActiveTrialWithoutCreditCard() {
    return this.customer.hasActiveTrialWithoutCreditCard;
  }

  get shouldDisplayCancellationBanner() {
    return (
      this.customer.subscriptionWillBeCancelled &&
      !this.customer.hasSucceedingSubscription &&
      !this.stripeMigration?.isPending
    );
  }

  get hasPendingEarlyStageApplication() {
    return this.customer.hasActiveSubscription && this.customer.pendingEarlyStageApplicant;
  }

  get showSummaryWarningBanners() {
    return (
      this.hasActiveTrialWithoutCreditCard ||
      this.shouldDisplayCancellationBanner ||
      this.hasPendingEarlyStageApplication
    );
  }

  get currentEarlyStageProgramName() {
    return this.customerService.customer.earlyStageProgrameName;
  }

  get currentDiscountToDisplay() {
    return Math.floor(this.args.earlyStageGraduation.currentStepPercentageDiscount);
  }

  get futureDiscountToDisplay() {
    return Math.floor(this.args.earlyStageGraduation.nextStepPercentageDiscount);
  }

  @action
  takeAction(event: Event) {
    event.preventDefault();
    let object = 'review_options';
    if (this.args.earlyStageGraduation.progressionConfirmedByCustomer) {
      object = this.args.earlyStageGraduation.isGraduatingToEarlyStage
        ? 'update_subscription'
        : 'switch_back';
    }
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_progression_summary_and_action_banner',
      place: 'billing_summary',
      object,
    });
    this.transiteToUpdateSubscriptionPage();
  }

  transiteToUpdateSubscriptionPage() {
    this.router.transitionTo('apps.app.settings.workspace.billing.update-subscription');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::ProgressionSummaryAndActionBanner': typeof ProgressionSummaryAndActionBanner;
    'billing/summary/progression-summary-and-action-banner': typeof ProgressionSummaryAndActionBanner;
  }
}
