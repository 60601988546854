/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FIN_CSAT_COMPATIBLE_CHANNELS } from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';
import type { BotMode, InactivityMode } from 'embercom/models/ai-agent/profile';

interface Signature {
  Args: {
    profile: Profile;
    isEditing: boolean;
  };
}

export default class Editor extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedMode: BotMode = 'default';
  @tracked selectedUserInactivityModeWhenNoAnswer: InactivityMode;
  @tracked selectedUserInactivityModeWhenAnswer: InactivityMode;
  @tracked selectedAccessToTeammate = true;
  @tracked selectedBotOnlyLocale = this.defaultLocale;
  @tracked showCsatBlockAfterUpdate =
    this.profile.csatBlockUpdateAfter !== undefined && this.profile.csatBlockUpdateAfter !== null;

  supportedAutoCloseModes: { text: string; value: InactivityMode }[];
  inactivityWhenAnswerCurrentSelection: { [key: string]: InactivityMode } = {};

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.supportedAutoCloseModes = [
      { text: this.intl.t('ai-agent.profiles.editor.routed'), value: 'routed' },
      { text: this.intl.t('ai-agent.profiles.editor.closed'), value: 'closed' },
    ];
    this.selectedUserInactivityModeWhenNoAnswer =
      this.profile.userInactivityModeWhenNoAnswer || 'routed';
    this.selectedUserInactivityModeWhenAnswer =
      this.profile.userInactivityModeWhenAnswer || 'closed';
    this._setDefaults();
  }

  _setDefaults() {
    if (this.appService.app.canUseFinExperience) {
      return;
    }

    this.selectedUserInactivityModeWhenNoAnswer =
      this.profile.userInactivityModeWhenNoAnswer || 'routed';
    this.selectedUserInactivityModeWhenAnswer =
      this.profile.userInactivityModeWhenAnswer || 'closed';
    if (this.profile.botMode === 'looping' || this.profile.botMode === 'bot_only') {
      this.selectedMode = 'looping';
    } else {
      this.selectedMode = 'default';
    }
    this.inactivityWhenAnswerCurrentSelection[this.selectedMode] =
      this.selectedUserInactivityModeWhenAnswer;
    this.selectedAccessToTeammate = this.profile.botMode !== 'bot_only';
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.list';
  }

  get profile() {
    return this.args.profile;
  }

  get resolutionBotEnabled() {
    return this.profile.botMode !== 'disabled';
  }

  get accessToTeammateEnabled() {
    return this.profile.botMode !== 'bot_only';
  }

  get botModeHeading() {
    switch (this.profile.botMode) {
      case 'one_time':
        return this.intl.t('ai-agent.profiles.bot-mode.one-time');
      case 'looping':
        return this.intl.t('ai-agent.profiles.bot-mode.looping');
      case 'bot_only':
        return this.intl.t('ai-agent.profiles.bot-mode.looping-bot-only');
      default:
        return '';
    }
  }
  get defaultLocale() {
    return this.appService.app.locale || 'en';
  }

  _humanReadableAutoCloseMode(mode_value: InactivityMode) {
    return this.supportedAutoCloseModes.find((mode) => mode.value === mode_value)!.text;
  }

  get humanReadableUserInactivityModeWhenAnswer() {
    if (!this.profile.userInactivityModeWhenAnswer) {
      return '';
    }
    return this._humanReadableAutoCloseMode(this.profile.userInactivityModeWhenAnswer);
  }

  get humanReadableUserInactivityModeWhenNoAnswer() {
    if (!this.profile.userInactivityModeWhenNoAnswer) {
      return '';
    }
    return this._humanReadableAutoCloseMode(this.profile.userInactivityModeWhenNoAnswer);
  }

  get compatibleWithFinCSAT() {
    let targetChannels = this.profile.targetChannels || [];
    if (!targetChannels.length) {
      return true;
    }

    let incompatibleChannels = targetChannels.filter((channel) => {
      return !FIN_CSAT_COMPATIBLE_CHANNELS.includes(channel);
    });

    return !incompatibleChannels.length;
  }

  @action
  selectResolutionBotMode(mode: BotMode) {
    if (mode === 'default') {
      this.profile.botMode = 'one_time';
    } else {
      this.profile.botMode = this.selectedAccessToTeammate ? 'looping' : 'bot_only';
    }
    this.selectedMode = mode;
    let inactivityWhenAnswerMode = this.inactivityWhenAnswerCurrentSelection[mode] || 'closed';
    this.selectedUserInactivityModeWhenAnswer = inactivityWhenAnswerMode;
    this.profile.userInactivityModeWhenAnswer = inactivityWhenAnswerMode;
  }

  @action
  updateUserInactivityModeWhenNoAnswer(mode: InactivityMode) {
    this.selectedUserInactivityModeWhenNoAnswer = mode;
    this.profile.userInactivityModeWhenNoAnswer = mode;
  }

  @action
  updateUserInactivityModeWhenAnswer(mode: InactivityMode) {
    this.inactivityWhenAnswerCurrentSelection[this.selectedMode] = mode;
    this.selectedUserInactivityModeWhenAnswer = mode;
    this.profile.userInactivityModeWhenAnswer = mode;
  }

  @action
  updateAccessToTeammate(enabled: boolean) {
    this.profile.botMode = enabled ? 'looping' : 'bot_only';
    this.selectedAccessToTeammate = enabled;
  }

  @action
  toggleResolutionBotEnabled() {
    if (this.profile.botMode !== 'disabled') {
      this.profile.botMode = 'disabled';
      return;
    }

    if (this.selectedMode === 'default') {
      this.profile.botMode = 'one_time';
    } else if (this.selectedAccessToTeammate) {
      this.profile.botMode = 'looping';
    } else {
      this.profile.botMode = 'bot_only';
    }
  }

  @action
  updateBotOnlyLocale(locale: string) {
    this.selectedBotOnlyLocale = locale;
  }

  @action
  setBotMode(mode: BotMode) {
    this.profile.botMode = mode;
    if (mode === 'one_time') {
      this.profile.useAiAnswers = false;
      this.profile.preHandoverAnswerEnabled = false;
    } else if (mode === 'bot_only') {
      this.profile.preHandoverAnswerEnabled = false;
    }
    this.profile.maybeResetDefaultInactivityValues();
  }

  @action
  setInactivityModeFor(
    mode: 'userInactivityModeWhenAnswer' | 'userInactivityModeWhenNoAnswer',
    value: InactivityMode,
  ) {
    this.profile[mode] = value;
  }

  @action
  toggleCsatEnabled() {
    this.profile.csatEnabled = !this.profile.csatEnabled;

    if (!this.profile.csatEnabled) {
      this.profile.csatBlockUpdateAfter = undefined;
      this.showCsatBlockAfterUpdate = false;
    }
  }

  @action
  toggleRatingChange() {
    this.showCsatBlockAfterUpdate = !this.showCsatBlockAfterUpdate;

    if (!this.showCsatBlockAfterUpdate) {
      this.profile.csatBlockUpdateAfter = undefined;
    }
  }

  @action
  setBlockUpdateAfter(e: Event) {
    let value = parseInt((e.target as HTMLInputElement)?.value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    this.profile.csatBlockUpdateAfter = value * 3600;
  }

  @action
  updateInactivityDuration(number: number) {
    this.profile.endUserInactivityTimer = number;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Profiles::Editor': typeof Editor;
    'ai-agent/profiles/editor': typeof Editor;
  }
}
