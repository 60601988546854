/* import __COLOCATED_TEMPLATE__ from './selector-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import $ from 'jquery';
import { computed, observer, action } from '@ember/object';
import { gt, not, gte, and, notEmpty, lt, sort } from '@ember/object/computed';
import Component from '@ember/component';
import {
  equalToProperty,
  fmtStyle,
  subtractFrom,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import latinize from 'latinize';
import browserScrollbarWidth from 'embercom/lib/scrollbar-width';
import { addEventListener } from 'ember-lifeline';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';

export default Component.extend(ComponentDropdownToggler, {
  tagName: 'span',
  paywallService: service(),
  frontendStatsService: service(),
  classNames: ['u__relative'],
  classNameBindings: ['isOpen:o__opened'],
  attributeBindings: ['tabindex'],
  tabindex: ternary('disabled', null, 0),
  itemHeight: 30,
  itemWidth: 247,
  numItemsToDisplay: 12,
  listItemSelector: '.js__admin-list-item',
  dropdownAlignmentClass: 'o__down-left',
  clearInputOnSelect: false,
  use3dFix: true,
  listElementComponent: 'admin/selector-item-component',
  showUnassigned: true,
  sorted: false,
  showFullName: true,
  showTeammates: true,
  showTeams: false,
  showSearch: true,
  hasTeammates: gt('filteredTeammates.length', 0),
  hasTeams: gt('filteredTeams.length', 0),
  hasNoTeammates: not('hasTeammates'),
  hasNoTeams: not('hasTeams'),
  hasHighlightedItem: gte('highlightedItemIndex', 0),
  showInviteLink: and('hasNoTeammates', 'hasNoTeams'),
  sortProperties: ['count:desc', 'name:asc'],
  adminFilterQuery: '',
  helpTeammateText: '',
  helpTeamText: '',
  hasHelpTeammateText: notEmpty('helpTeammateText'),
  hasHelpTeamText: notEmpty('helpTeamText'),

  didInsertElement() {
    this._super(...arguments);
    this.set('highlightedItemIndex', this.firstSelectableItemIndex);
    let $el = $(this.element);
    addEventListener(this, this.element, 'mouseover', (event) => {
      let listItemElement = $(event.target, this.element)
        .closest(this.listItemSelector, $el)
        .get(0);
      if (listItemElement) {
        this.set('highlightedItemIndex', parseInt(listItemElement.dataset.index, 10));
      }
    });
  },

  // TODO - Computed.findIndex
  firstSelectableItemIndex: computed('collectionItems', function () {
    let items = this.collectionItems;
    for (let i = 0; i < items.get('length'); i++) {
      if (!items[i].isLabel && !items[i].isEmptyMessage) {
        return i;
      }
    }
    return -1;
  }),

  firstItemIsHighlighted: lt('highlightedItemIndex', 1),
  lastItemIndex: subtractFrom('collectionItems.length', 1),
  lastItemIsHighlighted: equalToProperty('highlightedItemIndex', 'lastItemIndex'),

  // TODO - Computed.objectAtProperty
  highlightedItem: computed('collectionItems', 'highlightedItemIndex', function () {
    this.collectionItems.objectAt(this.highlightedItemIndex);
  }),

  containerHeight: computed('itemHeight', 'collectionItems.[]', function () {
    return this.itemHeight * Math.min(this.get('collectionItems.length'), this.numItemsToDisplay);
  }),

  containerWidth: computed('itemWidth', function () {
    return this.itemWidth + browserScrollbarWidth();
  }),

  containerStyle: fmtStyle(
    'position: relative; height: %@px; width: %@px;',
    'containerHeight',
    'containerWidth',
  ),

  collectionItems: computed(
    'filteredSelectableTeammatesWithLabel',
    'filteredSelectableTeamsWithLabel',
    function () {
      let items = A();
      items = items.concat(this.filteredSelectableTeammatesWithLabel);
      if (this.showTeams) {
        items = items.concat(this.filteredSelectableTeamsWithLabel);
      }
      return items;
    },
  ),

  filteredSelectableTeammatesWithLabel: computed('teammates', 'teammateLabelItem', function () {
    return this.filteredSelectableItemsWithLabel('teammates', 'teammateLabelItem');
  }),

  filteredSelectableTeamsWithLabel: computed('teams', 'teamLabelItem', function () {
    return this.filteredSelectableItemsWithLabel('teams', 'teamLabelItem');
  }),

  filteredSelectableItemsWithLabel(itemsKey, labelsKey) {
    let teammates = this.get(itemsKey);
    let labelItem = this.get(labelsKey);
    let items = [];
    if (labelItem) {
      items.push(labelItem);
    }
    if (teammates.length > 0) {
      items = items.concat(teammates);
    } else if (this.get(`show${capitalize(itemsKey)}`)) {
      items.push({ isEmptyMessage: true, text: `No ${itemsKey} found` });
    }
    return items;
  },

  teammates: ternaryToProperty('sorted', 'sortedFilteredTeammates', 'filteredTeammates'),

  filteredTeammates: computed('selectableTeammates', 'filterRegExp', function () {
    return this.selectableTeammates.filter(this.match, this);
  }),

  sortedTeammates: computed('selectableTeammates', function () {
    return this.selectableTeammates.sortBy('sortName');
  }),

  sortedFilteredTeammates: computed('sortedTeammates', 'filterRegExp', function () {
    return this.sortedTeammates.filter(this.match, this);
  }),

  teams: ternaryToProperty('sorted', 'sortedFilteredTeams', 'filteredTeams'),

  filteredTeams: computed('selectableTeams', 'filterRegExp', function () {
    return this.selectableTeams.filter(this.match, this);
  }),

  sortedFilteredTeams: sort('filteredTeams', 'sortProperties'),

  teammateLabelItem: computed('helpTeammateText', 'showSearch', function () {
    if (this.hasHelpTeammateText && this.showSearch) {
      return {
        isLabel: true,
        text: this.helpTeammateText,
      };
    }
  }),

  teamLabelItem: computed('helpTeamText', 'showSearch', function () {
    if (this.hasHelpTeamText && this.showSearch) {
      return {
        isLabel: true,
        text: this.helpTeamText,
      };
    }
  }),

  selectableTeammates: computed('showTeammates', 'selectableItems.[]', function () {
    if (!this.showTeammates) {
      return [];
    }
    return this.selectableItems.filter((admin) => !admin.get('isTeam'));
  }),

  selectableTeams: computed('showTeams', 'selectableItems.[]', function () {
    if (!this.showTeams) {
      return [];
    }
    return this.selectableItems.filterBy('isTeam');
  }),

  selectableItems: computed('admins', function () {
    let resultSet = [];
    this.admins.forEach((admin) => {
      if (admin.get('isUnassignedAdmin')) {
        if (this.showUnassigned) {
          resultSet.push(admin);
        }
      } else if (!admin.get('isBot')) {
        resultSet.push(admin);
      }
    });
    return resultSet;
  }),

  match(item) {
    let rx = this.filterRegExp;
    let name = item.get('name') || '';
    let email = item.get('email') || '';
    let displayAs = item.get('display_as_assignee');
    let matched = latinize(name).match(rx);
    if (!matched && displayAs && name !== displayAs) {
      matched = latinize(displayAs).match(rx);
    }
    if (!matched && !!email) {
      matched = latinize(email).match(rx);
    }
    return matched;
  },

  filterRegExp: computed('adminFilterQuery', function () {
    let filter = this.adminFilterQuery || '';
    return new RegExp(latinize(filter), 'gi');
  }),

  placeHolderText: computed('selectableTeammates', 'selectableTeams', function () {
    let properties = this.getProperties('hasTeammates', 'hasTeams');
    let teammates = properties.hasTeammates ? ' teammates' : '';
    let conjunction = properties.hasTeammates && properties.hasTeams ? ' and' : '';
    let teams = properties.hasTeams ? ' teams' : '';
    return `Search${teammates}${conjunction}${teams}`;
  }),

  // TODO - Observers should die
  isOpenChanged: observer({
    dependentKeys: ['isOpen'],

    fn() {
      let statsService = this.frontendStatsService;
      if (this.isOpen) {
        // TODO - Register stats service correctly so this can be DI'd for tests
        if (statsService) {
          statsService.timeUntilAfterRender('ember_ux_adminSelectorDropdownComponentOpen');
        }
      } else {
        this.handleClose();
      }
    },

    sync: true,
  }),

  handleClose() {
    this.setProperties({
      adminFilterQuery: '',
      highlightedItemIndex: this.firstSelectableItemIndex,
    });
  },

  keyDown(e) {
    let action = {
      40: 'onDown',
      38: 'onUp',
      13: 'onEnter',
    }[e.which];

    if (action) {
      this.send(action);
      e.preventDefault();
    } else {
      this._super(...arguments);
    }
  },

  updatedSearch: action(function (changeEvent) {
    this.set('adminFilterQuery', changeEvent.target.value);
    this.set('highlightedItemIndex', this.firstSelectableItemIndex);
  }),

  updatedSearchForInput: action(function (input) {
    this.set('adminFilterQuery', input);
    this.set('highlightedItemIndex', this.firstSelectableItemIndex);
  }),

  sendSelectionAction: action(function (model) {
    this.listElementAction(model);
  }),

  actions: {
    open() {
      if (this.paywallFeature && this.paywallActivated) {
        this.paywallService
          .paywall({
            featureName: this.paywallFeature,
          })
          .then(() => {
            this.send('open', ...arguments);
          })
          .catch(this.paywallService.handleError);
        return;
      }
      this._super(...arguments);
    },

    onEnter() {
      if (this.hasHighlightedItem) {
        $('.o__selected:visible', this.element).click();
      }
    },

    onUp() {
      if (!this.firstItemIsHighlighted) {
        this.decrementProperty('highlightedItemIndex');
      }
    },

    onDown() {
      if (!this.lastItemIsHighlighted) {
        this.incrementProperty('highlightedItemIndex');
      }
    },
  },
});
