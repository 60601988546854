/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';
import {
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type Price from 'embercom/models/price';

interface Args {
  model: $TSFixMe;
  tab: string;
  onChangeTab: (tabId: string) => void;
  tabContainerClass: string;
  pricing5Estimates: Array<Price>;
  onMigrationSeatConfigurationSave: () => Promise<void>;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationMainComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showEditPlanTab = false;

  get app() {
    return this.appService.app;
  }

  get shouldShowConfirmationPage() {
    return (
      (this.args.model.stripeMigration.isConfirmedByCustomer ||
        this.args.model.stripeMigration.isLocked) &&
      !this.showEditPlanTab
    );
  }

  get shouldShowSurveyButton() {
    return !this.shouldShowConfirmationPage;
  }

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    if (this.shouldShowConfirmationPage) {
      window.Intercom('trackEvent', 'stripe-migration-survey-trigger');
      return [];
    } else {
      return [
        {
          label: 'billing.migrations.migration_overview.tab_title',
          value: 'migrationOverview',
          canAccess: this.args.model.stripeMigration.isProactive,
        },
        {
          label: this.editPlanLabel,
          value: 'moveToNewPricing',
          canAccess: true,
        },
        {
          label: 'billing.migrations.compare_feature',
          value: 'compareFeatures',
          canAccess: true,
        },
        {
          label: 'billing.migrations.compare_billing',
          value: 'compareBilling',
          canAccess: this.args.model.stripeMigration.isProactive || this.shouldShowImprovedFlow,
        },
        {
          label: 'billing.migrations.migration_help.tab_title',
          value: 'migrationHelp',
          canAccess: this.shouldShowHelpTab,
        },
      ];
    }
  }

  get pricing5Estimates() {
    return {
      expertEstimate: this.expertEstimate,
      expertWithPSPEstimate: this.expertWithPSPEstimate,
      expertWithCopilotEstimate: this.expertWithCopilotEstimate,
      expertWithPSPCopilotEstimate: this.expertWithPSPCopilotEstimate,
      advancedEstimate: this.advancedEstimate,
      advancedWithPSPEstimate: this.advancedWithPSPEstimate,
      advancedWithCopilotEstimate: this.advancedWithCopilotEstimate,
      advancedWithPSPCopilotEstimate: this.advancedWithPSPCopilotEstimate,
      essentialEstimate: this.essentialEstimate,
      essentialWithPSPEstimate: this.essentialWithPSPEstimate,
      essentialWithCopilotEstimate: this.essentialWithCopilotEstimate,
      essentialWithPSPCopilotEstimate: this.essentialWithPSPCopilotEstimate,
    };
  }

  private get essentialEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ESSENTIAL_ID]);
  }

  private get advancedEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ADVANCED_ID]);
  }

  private get expertEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_EXPERT_ID]);
  }

  private get essentialWithPSPEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ESSENTIAL_ID, PROACTIVE_SUPPORT_PLUS_BASE_ID]);
  }

  private get advancedWithPSPEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ADVANCED_ID, PROACTIVE_SUPPORT_PLUS_BASE_ID]);
  }

  private get expertWithPSPEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_EXPERT_ID, PROACTIVE_SUPPORT_PLUS_BASE_ID]);
  }

  private get essentialWithCopilotEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ESSENTIAL_ID, FIN_AI_COPILOT_BASE_ID]);
  }

  private get advancedWithCopilotEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_ADVANCED_ID, FIN_AI_COPILOT_BASE_ID]);
  }

  private get expertWithCopilotEstimate() {
    return this.getEstimateByPlan([PRICING_5_X_CORE_EXPERT_ID, FIN_AI_COPILOT_BASE_ID]);
  }

  private get essentialWithPSPCopilotEstimate() {
    return this.getEstimateByPlan([
      PRICING_5_X_CORE_ESSENTIAL_ID,
      PROACTIVE_SUPPORT_PLUS_BASE_ID,
      FIN_AI_COPILOT_BASE_ID,
    ]);
  }

  private get advancedWithPSPCopilotEstimate() {
    return this.getEstimateByPlan([
      PRICING_5_X_CORE_ADVANCED_ID,
      PROACTIVE_SUPPORT_PLUS_BASE_ID,
      FIN_AI_COPILOT_BASE_ID,
    ]);
  }

  private get expertWithPSPCopilotEstimate() {
    return this.getEstimateByPlan([
      PRICING_5_X_CORE_EXPERT_ID,
      PROACTIVE_SUPPORT_PLUS_BASE_ID,
      FIN_AI_COPILOT_BASE_ID,
    ]);
  }

  private getEstimateByPlan(planIdStrings: string[]): Price {
    let estimates = this.args.pricing5Estimates;
    let planIds: number[] = planIdStrings.map((id) => this.idAsNumber(id));

    return estimates.find((estimate) => estimate.hasSamePlans(planIds))!;
  }

  private idAsNumber(id: string): number {
    return parseInt(id, 10);
  }

  private get editPlanLabel() {
    return this.args.model.stripeMigration.isProactive
      ? 'billing.migrations.migration_edit_plan'
      : 'billing.migrations.move_to_new_pricing';
  }

  private get shouldShowImprovedFlow() {
    return this.app.canSeePricingMigrationImprovedFlow;
  }

  private get shouldShowHelpTab() {
    return this.args.model.stripeMigration.isProactive;
  }

  get controlTabs() {
    return this.tabs.filter((tab) => tab.canAccess);
  }

  get selectedTab() {
    if (this.controlTabs.find((tab) => tab.value === this.args.tab)) {
      return this.args.tab;
    }
    return this.controlTabs.firstObject?.value;
  }

  @action
  onProactiveMigrationSave() {
    this.showEditPlanTab = false;
  }

  @action
  onEditPlanClick() {
    this.showEditPlanTab = true;
    this.args.onChangeTab('moveToNewPricing');
  }

  @action openSurvey() {
    window.Intercom('trackEvent', 'stripe-migration-survey-button-trigger');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::Main': typeof BillingMigrationMainComponent;
  }
}
