/* import __COLOCATED_TEMPLATE__ from './collections-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';

export default Component.extend(EventTracking, {
  helpCenterService: service(),
  notificationsService: service(),
  intl: service(),
  sortKey: ['order'],
  sortedCollections: sort('collections', 'sortKey'),

  showCollectionReorderWarning() {
    if (this.helpCenterService.showCollectionReorderWarning) {
      this.notificationsService.removeNotification('collectionReorderWarning');
      this.notificationsService.notifyWarningWithButton(
        this.intl.t('articles.collections.reordering.title'),
        {
          label: this.intl.t('articles.collections.reordering.label'),
          action: this.collectionReorderWarningButton.bind(this),
        },
        5000,
        'collectionReorderWarning',
      );
    }
  },

  collectionReorderWarningButton() {
    this.helpCenterService.set('showCollectionReorderWarning', false);
    this.notificationsService.removeNotification('collectionReorderWarning');
  },

  reorderable: computed('collections.[]', function () {
    return !this.collections.any((i) => i.readOnly);
  }),

  onDraggingEnd: action(function (collections, movedCollection) {
    let newIndex = collections.indexOf(movedCollection);
    let oldIndex = movedCollection.order;
    collections.forEach((collection, index) => {
      collection.set('order', index);
    });
    movedCollection
      .reorder(newIndex)
      .then(() => {
        this.showCollectionReorderWarning();
        this.trackCollectionEvent('educate-collection-reordered', movedCollection, {
          action: 'reordered',
          place: 'collection',
          oldIndex,
          newIndex,
        });
      })
      .catch(() =>
        this.notificationsService.notifyError(this.intl.t('articles.collections.reordering.error')),
      );
  }),
});
