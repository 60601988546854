/* import __COLOCATED_TEMPLATE__ from './coverage-summary-value.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import numberFormatter from 'embercom/lib/number-formatter';

export default class CoverageSummaryValue extends Component {
  get formattedValue() {
    return numberFormatter(this.args.currentValue);
  }
}
