/* import __COLOCATED_TEMPLATE__ from './training-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import TrainExistingConfirmation from 'embercom/components/notifications/train-existing-confirmation';

export default class TrainingModal extends Component {
  @service store;
  @service appService;
  @service router;
  @service intercomEventService;
  @service notificationsService;
  @tracked exampleQuestionText;
  @tracked status = 'live';
  @tracked language = null;
  @tracked answers = null;

  constructor() {
    super(...arguments);
    this.exampleQuestionText = this.args.unansweredQuestionForTraining.phrase_text;
    this.loadAnswers.perform();
  }

  get questionMetadata() {
    return {
      example_question_text: this.exampleQuestionText,
      phrase_text: this.args.unansweredQuestionForTraining.phrase_text,
      phrase_id: this.args.unansweredQuestionForTraining.phrase_id,
    };
  }

  @task({ restartable: true })
  *loadAnswers() {
    this.answers = yield this.store.findAll('custom-answers/custom-answer', {
      reload: true,
      include: 'stats',
    });
  }

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...data,
      ...this.questionMetadata,
    });
  }

  @action
  filterByLanguage(languageCode) {
    this.language = languageCode;
  }

  @action
  filterByStatus(status) {
    this.status = status;
  }

  @action
  createNewAnswer() {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'unanswered_question_training_modal_create_answer',
    });

    this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
      queryParams: {
        exampleText: this.exampleQuestionText,
        exampleSource: 'reporting_unanswered_question',
        exampleId: this.args.unansweredQuestionForTraining.phrase_id,
        expandSelectedExamples: true,
        transitionBackTo: 'apps.app.reports.customer-support.resolution-bot',
      },
    });
  }

  @action
  trainExistingAnswer(answer) {
    this.trackAnalyticsEvent({
      action: 'clicked',
      object: 'unanswered_question_training_modal_edit_answer',
    });

    this.addExampleToExistingAnswer.perform(answer);
  }

  @task({ drop: true })
  *addExampleToExistingAnswer(selectedAnswer) {
    let answer = yield post(
      `/ember/custom_answers/custom_answers/${selectedAnswer.id}/add_example_questions`,
      {
        app_id: this.appService.app.id,
        example_questions: [
          {
            text: this.exampleQuestionText,
            added_from: 'reporting_unanswered_question',
            identifier: this.args.unansweredQuestionForTraining.phrase_id,
          },
        ],
      },
    );
    this.notificationsService.notifyConfirmationWithModelAndComponent(
      answer,
      TrainExistingConfirmation,
    );
    this.args.onModalClose();
  }
}
