/* import __COLOCATED_TEMPLATE__ from './channels-hub.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ChannelsHub extends Component {
  @service appService;
  @service store;
  @service guideLibraryService;

  get shouldShowNochannelsBanner() {
    if (this.appService.app?.canUseActivationProgressIndicator) {
      let step = this.store.peekRecord(
        'onboarding/home/step',
        'guide_library_foundational_steps_install_messenger_v2',
      );
      return (
        !(step?.completed || this.guideLibraryService.isMessengerInstalled) &&
        !this.appService.app?.has_setup_email_forwarding
      );
    }
    return (
      !this.appService.app?.has_setup_email_forwarding &&
      !this.guideLibraryService.isMessengerInstalled
    );
  }
}
